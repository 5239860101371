<template>
    <div class="full-page">
        <div class="page-header">
            <breadcrumbs-title></breadcrumbs-title>
            <the-button @click="goToAddNew" class="button">
                <template #icon>
                    <PlusIcon :className="'ui-the-button-icon'"></PlusIcon>
                </template>
                <template #text>
                    {{ $t('KeywordsView.add-keywords') }}
                </template>
            </the-button>
        </div>
        <v-card class="square-card" v-if="keywords">
            <DataTable :value="keywords" tableStyle="min-width: 50rem" table-style="font-size: 14px">
                <Column field="keywords" :header="$t('KeywordsView.keywords')">
                    <template #body="slotProps">
                        <p>{{ arrayToString(slotProps.data.keywords) }}</p>
                    </template>
                </Column>
                <Column field="name" :header="$t('KeywordsView.name')"></Column>
                <Column field="actions, item" :header="$t('DossierListTable.actions')">
                    <template #body="{ data }">
                        <span class="p-buttonset">
                            <Toast />
                            <div class="actions">
                                <Button icon="pi pi-trash" class="delete" size="small" @click="deleteConfirm($event, data)"
                                    :loading="deleteIsLoading && selectedKeywordsID == data.id"
                                    loading-icon="pi pi-spinner pi-spin" :title="$t('KeywordsView.delete')" />
                            </div>
                        </span>
                    </template>
                </Column>

            </DataTable>
        </v-card>
    </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import TheButton from '@/components/ui/TheButton.vue';
import PlusIcon from '@/assets/icons/PlusIcon.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Toast from 'primevue/toast';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import Button from 'primevue/button';

import router from '@/router';
import { onMounted, ref } from 'vue';
import { computed } from '@vue/reactivity';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';
import i18n from '@/i18n';

const confirm = useConfirm();
const toast = useToast();
const keywords = ref();
const { t } = i18n.global;

const reactiveSelectedKeywordsID = ref(null)
const selectedKeywordsID = computed({
    get() {
        return reactiveSelectedKeywordsID.value
    },
    set(tenderID) {
        reactiveSelectedKeywordsID.value = tenderID
    }
})

onMounted(() => {
    getKeywords();
})

const deleteIsLoading = ref(false);

function getKeywords() {
    axiosInstance.get('keywords/')
        .then((response) => {
            keywords.value = response.data;
        })
        //handle error
        .catch((error) => {
            devLog(error)
        })
        //always executed
        .then(() => {
        });
}

function goToAddNew() {
    router.push('keywords/add-keywords')
}

function arrayToString(array) {
    return array.join(', ')
}

function deleteConfirm(event, keywords) {
    reactiveSelectedKeywordsID.value = keywords.id
    confirm.require({
        target: event.currentTarget,
        message: t('KeywordsView.delete-confirm'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: t('KeywordsView.yes'),
        rejectLabel: t('KeywordsView.no'),
        acceptClass: 'confirm-delete',
        accept: () => {
            deleteIsLoading.value = true;
            deleteKeywords(keywords);

        },
        reject: () => {
            toast.add({ severity: 'error', summary: t('KeywordsView.cancel-summary'), detail: t('KeywordsView.cancel-detail'), life: 3000 });
        }
    });
};

function deleteKeywords(keywords) {
    axiosInstance.delete('keywords/' + keywords.id + '/')
        .then((response) => {
            // removes currently deleted keywords from tenderList reactive object
            deleteKeywordsFromLocal(keywords)

            // shows delete succeeded notification
            deleteIsLoading.value = false;
            toast.add({ severity: 'info', summary: t('KeywordsView.success-summary'), detail: t('KeywordsView.success-detail'), life: 3000 });
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
}

function deleteKeywordsFromLocal(deletedKeywords) {
    keywords.value = keywords.value.filter(function (object) {
        return object.id !== deletedKeywords.id;
    });
}

</script>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1.5rem;
}

.square-card {
    border-radius: 0;
}
:deep(.p-button.delete) {
    border-color: #1A224C;
    background-color: white;
    color: #1A224C;
    font-size: 14px;
    padding: 0.3rem;
    border-radius: 10%;
}

:deep(.p-button.delete:hover) {
    background-color: red;
    border-color: red;
    color: white;
}
</style>