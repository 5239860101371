<template>
  <div class="titleBackground">
    <h1>{{ $t('AuthLoginForm.headerTitle') }}</h1>
  </div>
</template>

<style scoped>
.titleBackground {
  background-color: var(--main-header-color);
}
h1{
  color: #A5AFF7;
  padding: 2rem;
  font-weight: bold;
  font-size: 14px;
}
</style>