import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";

export default {
  state() {
    return {
      authUser: {},
      jwt: "",
      refresh: "",
      isAuthenticated: "",
      endpoints: {
        // TODO: Remove hardcoding of dev endpoints
        obtainJWT: "auth/obtain_token/",
        refreshJWT: "auth/refresh_token/",
        baseUrlAuth: "auth/",
      },
    };
  },
  mutations,
  actions,
  getters,
};
