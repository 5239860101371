<template>
    <Card class="square-card">
        <template #title>
            {{ $t('QualificationsExport.title') }}
        </template>
        <template #content>
            <form class="form" @keydown.enter.prevent>

                <div class="form-field">
                    <label class="form-title" for="filename">{{ $t('QualificationsExport.name') }}</label>
                    <InputText id="filename" v-model="exportForm.name" aria-describedby="filename-help" />
                    <p v-if="exportForm.name.length < 1 && attemptedFormSent" class="error">{{
                $t('QualificationsExport.name-explanation') }}</p>
                </div>

                <div class="button-field">
                    <TheButton class="form-button" :loading="wordIsLoading" :disabled="disableButtons"
                        @click="checkForErrors($event, 'word')">
                        <template #text>{{ $t('QualificationsExport.word-title') }}</template>
                    </TheButton>

                    <TheButton class="form-button" :loading="PDFisLoading" :disabled="disableButtons"
                        @click="checkForErrors($event, 'pdf')">
                        <template #text>{{ $t('QualificationsExport.pdf-title') }}</template>
                    </TheButton>

                </div>

            </form>
        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';
import TheButton from '@/components/ui/TheButton.vue';
import InputText from 'primevue/inputtext';

import router from '@/router';
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';

const props = defineProps({
    disableButtons: Boolean
})

const route = useRoute();

const exportForm = ref({
    name: '',
    dossier: route.params.dossierId
})
const attemptedFormSent = ref(false);
const PDFisLoading = ref(false);
const wordIsLoading = ref(false);

function checkForErrors(event, fileType) {
    event.preventDefault();
    attemptedFormSent.value = true
    if (exportForm.value.name.length < 1) {
        return
    }

    if (fileType === 'word') {
        downloadWord();
    } else if (fileType === 'pdf') {
        downloadPDF();
    }
};

async function downloadWord() {
    wordIsLoading.value = true;
    try {
        const language = localStorage.getItem('language') || 'nl';
        const response = await axiosInstance.get('/qualification-export/?dossier=' + route.params.dossierId + '&language=' + language, {
            responseType: 'blob'
        });
        if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', exportForm.value.name + '.docx'); // Change the file extension to .docx
            document.body.appendChild(link);
            link.click();
        } else {
            // await generateConformity();
            // await downloadConformity(fileType);
        }
    } catch (error) {
        devLog(error);
    } finally {
        wordIsLoading.value = false;
    }
}


async function downloadPDF() {
    PDFisLoading.value = true;
    try {
        const language = localStorage.getItem('language');
        const response = await axiosInstance.get('/qualification-export-pdf/?dossier=' + route.params.dossierId + '&language=' + language, {
            responseType: 'blob'
        });
        if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', exportForm.value.name + '.pdf');
            document.body.appendChild(link);
            link.click();
        } else {
            // await generateConformity();
            // await downloadConformity(fileType);
        }
    } catch (error) {
        devLog(error);
    } finally {
        PDFisLoading.value = false;
    }
}

</script>

<style scoped>
/* FORM */
.form,
.form-empty,
.form-undefined {
    color: #1A224C;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}

.form-button {
    margin-top: 1rem;
}

.form-title {
    font-weight: 450;
    color: var(--sec-dark-purple);
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}

#documents {
    width: 100%;
    margin-top: 1rem;
    color: var(--sec-dark-purple);
}

.button-field {
    display: flex;
    gap: 15px;
}
</style>