<template>
  <select v-model="$i18n.locale" @change="submitLanguage($event)" class="dropdown">
    <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale.value">
      {{ $t('languages.' + locale.text) }}
    </option>
  </select>
</template>

<script setup>
//haven't added styling in this component, so the styling will happen in the parent component
//makes this component more re-usable

const locales = [
  { text: "EN", value: 'en' },
  { text: "NL", value: 'nl' },
  { text: "FR", value: 'fr' },
];
function submitLanguage(event) {
  localStorage.setItem('language', event.target.value);
};
</script>
