<template>
    <div class="page-wrapper">
        <div class="page-header">
            <breadcrumbs-title></breadcrumbs-title>
            <the-button @click="goToAddUsers" class="button">
                <template #icon>
                    <PlusIcon :className="'ui-the-button-icon'"></PlusIcon>
                </template>
                <template #text>
                    {{ $t('usersView.addUser') }}
                </template>
            </the-button>
        </div>
        <v-card v-if="isLoading">
            <p>{{ $t('usersView.loading') }}</p>
        </v-card>
        <!-- <Toolbar class="mb-4">
        <template #start>
            <Button icon="pi pi-user-plus" class="add-user" :label="$t('usersView.addUser')" @click="goToAddUsers()" />
        </template>
    </Toolbar> -->
        <v-card v-else-if="isLoading == false && users" class="square-card-page">


            <DataTable :value="users" table-style="font-size: 14px">
                <Column field="email" :header="$t('usersView.user')"></Column>

            </DataTable>
        </v-card>

    </div>
</template>

<script setup>
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import router from '@/router';
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import TheButton from '@/components/ui/TheButton.vue';
import PlusIcon from '@/assets/icons/PlusIcon.vue';

import { ref, onMounted } from 'vue';

import axiosInstance from '@/services/interceptor';


onMounted(() => {
    getUsers();
})

const users = ref()
const isLoading = ref(true)

function getUsers() {
    axiosInstance.get('/users/')
        .then((response) => {
            users.value = response.data;
            isLoading.value = false
        })
        .catch(() => {

        })
        .then(() => {
        })
}

function goToAddUsers() {
    router.push('users/add-users');
}

</script>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1.5rem;
}


.square-card-page {
    border-radius: 0;
}
</style>