<template>
    <div class="wrapper" v-if="selectionDropdownOptions">
        <Dropdown v-model="currentlySelectedSelection" @change="setSelectedSelection"
            :options="selectionDropdownOptions" option-label="name" class="selections-dropdown"
            :placeholder="t('SelectionsDropdown.pick-selection')">
        </Dropdown>
    </div>
</template>

<script setup>
import Dropdown from 'primevue/dropdown';

import { ref, onMounted } from 'vue';

import axiosInstance from '@/services/interceptor';
import riskDetailsChecklist from '@/assets/JSON/riskDetailsChecklist.json'

import i18n from '@/i18n';
import { devLog } from '@/helpers/helpers';


const { t } = i18n.global;

const props = defineProps({
    hideDefault: Boolean
})

const emit = defineEmits(['chosenSelectionChanged',]);

onMounted(() => {
    if (!props.hideDefault) {
        currentlySelectedSelection.value = standardPublicanSelection;
    };

    getSelections();
})

const standardPublicanSelection = {
    contractual: riskDetailsChecklist.contractual,
    precontractual: riskDetailsChecklist.precontractual,
    name: t('SelectionsDropdown.publican'),
    profile: 0,
    id: 0
}

const selectionDropdownOptions = ref();
const currentlySelectedSelection = ref();

function getSelections() {
    // in backend, the selections are called profiles
    axiosInstance.get('profiles/')
        .then((response) => {
            selectionDropdownOptions.value = response.data;
            selectionDropdownOptions.value.unshift(standardPublicanSelection)
            setSelectedSelection();
        })
        .catch((error) => {
            devLog(error)
        })
}

function setSelectedSelection() {
    emit('chosenSelectionChanged', currentlySelectedSelection.value)
}

</script>

<style scoped>
.wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-bottom: 1rem;
}

/* .wrapper-text {
    text-align: start;
    margin-right: 1rem;
    font-size: 14px;
    min-width: 7rem;
} */

.selections-dropdown {
    /* width: 20%; */
    text-align: start;
    align-items: center !important;
    vertical-align: middle;
    height: 3rem;
}
</style>