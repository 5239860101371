<template>
    <svg v-if="props.className" :class="props.className" id="fi_4853378" enable-background="new 0 0 512 512" viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Outline_38_">
            <path
                d="m355.932 432.94h-274.208v-241.184h90.069v-90.069h51.539v-15.011h-62.154l-36.027 36.027h-91.464v28.021h-33.025v361.276h304.232v-28.021h33.025v-36.027h33.025v-113.587h-15.011v98.575zm-199.151-320.638v64.443h-64.443zm133.101 384.687h-274.209v-331.253h18.014v318.242h256.195zm33.025-28.022h-274.209v-331.252h61.44l-43.427 43.427v266.81h256.195v21.015z">
            </path>
            <path
                d="m464.913 289.393-11.101 3.026-9.911-36.352c20.292-11.993 37.225-29.098 49.238-50.116 18.24-31.911 22.962-69.014 13.295-104.476s-32.564-65.037-64.474-83.276c-31.91-18.24-69.012-22.961-104.476-13.295-73.204 19.955-116.526 95.745-96.572 168.95 16.666 61.14 72.271 101.437 132.798 101.432 3.312 0 6.641-.134 9.978-.379l9.295 34.093-11.107 3.027 40.136 147.244c2.728 10.005 11.826 16.598 21.731 16.598 1.954 0 3.941-.257 5.916-.795l39.587-10.79c5.802-1.582 10.642-5.329 13.627-10.551s3.758-11.294 2.176-17.096zm-209.518-119.487c-17.777-65.219 20.819-132.741 86.037-150.518 10.723-2.923 21.614-4.37 32.436-4.37 21.063 0 41.862 5.479 60.642 16.214 28.429 16.251 48.829 42.599 57.44 74.192 8.613 31.592 4.406 64.649-11.844 93.078-16.251 28.43-42.599 48.829-74.192 57.441-65.222 17.779-132.741-20.82-150.519-86.037zm154.467 100.52c7.049-1.922 13.86-4.374 20.4-7.308l9.065 33.248-31.86 8.684-8.75-32.094c3.717-.689 7.435-1.519 11.145-2.53zm75.437 179.373-39.587 10.79c-1.935.525-3.959.271-5.699-.725-1.741-.995-2.99-2.607-3.516-4.543l-36.19-132.76 54.07-14.739 36.189 132.761c1.089 3.994-1.274 8.129-5.267 9.216z">
            </path>
            <path
                d="m467.094 191.061c14.252-24.935 17.936-53.946 10.373-81.69-7.487-27.469-25.232-50.495-49.967-64.835-25.007-14.499-54.171-18.285-82.121-10.666-27.683 7.546-50.791 25.456-65.066 50.43-14.276 24.974-17.981 53.974-10.436 81.658 13.03 47.8 56.504 79.301 103.825 79.301 9.337 0 18.832-1.228 28.264-3.799 27.746-7.563 50.875-25.462 65.128-50.399zm-69.076 35.915c-49.247 13.43-100.234-15.721-113.657-64.966-6.492-23.815-3.301-48.767 8.984-70.26 12.286-21.494 32.167-36.905 55.982-43.396 24.05-6.559 49.136-3.301 70.644 9.169 21.296 12.346 36.571 32.162 43.012 55.796 6.509 23.875 3.34 48.839-8.922 70.293-12.262 21.456-32.166 36.856-56.043 43.364z">
            </path>
            <path
                d="m373.779 75.334c-20.693 0-37.529 16.835-37.529 37.529 0 11.839 5.657 22.974 15.011 30.012v46.212h45.034v-46.212c9.355-7.038 15.011-18.172 15.011-30.012.002-20.693-16.833-37.529-37.527-37.529zm11.252 57.029-3.746 2.168v39.545h-15.011v-39.544l-3.746-2.169c-6.949-4.022-11.265-11.494-11.265-19.5 0-12.416 10.101-22.517 22.517-22.517s22.517 10.101 22.517 22.517c-.001 8.006-4.318 15.479-11.266 19.5z">
            </path>
            <path d="m119.252 291.367h206.157v15.011h-206.157z"></path>
            <path d="m119.252 323.035h206.157v15.011h-206.157z"></path>
            <path d="m119.252 354.702h206.157v15.011h-206.157z"></path>
            <path d="m310.398 386.369h15.011v15.011h-15.011z"></path>
            <path d="m280.375 386.369h15.011v15.011h-15.011z"></path>
            <path d="m119.252 386.369h146.111v15.011h-146.111z"></path>
        </g>
    </svg>
</template>

<script setup>
const props = defineProps({
    className: String
})
</script>