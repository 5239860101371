<template>
    <!-- Chips are hidden when there is less than 1 document in dossier -->
    <div class="wrapper">
        <div class="chips-container">
            <div class="chip" v-for="document in documents" :class="getStatusClass(document.status, document.id)">
                <span class="chip-content">
                    <p class="chip-content" @click="setChipActive(document.id, document.status)"
                        :disabled="document.status === 'processing' || document.status === 'error'">{{
                document.document_name }}
                    </p>
                    <i v-if="document.status === 'processing'" class="pi pi-hourglass" style="font-size: 1rem"></i>
                    <i v-else-if="document.status === 'error'" class="pi pi-times-circle" style="font-size: 1rem"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import axiosInstance from '@/services/interceptor';

const props = defineProps({
    showTypeFour: Boolean
})

const emit = defineEmits(['updateData', 'noDocumentsAvailable'])

onMounted(() => {
    getDocuments();
})

const route = useRoute();

const activeDocuments = ref([]);
const documents = ref([]);
const processingDocumentIDs = ref([]);
let amountOfGetDocumentLoops = 0;


function setChipActive(documentID, status) {
    // disable click when doc is processing or has an error
    if (status === "processing" || status === "error") {
        return
    }
    // add if to check if item already in array
    // if item in array, remove it, otherwise, add it
    if (activeDocuments.value.includes(documentID)) {
        // filters out item that is already selected
        activeDocuments.value = activeDocuments.value.filter(val => val !== documentID);
        emit('updateData', activeDocuments.value);

    } else {
        activeDocuments.value.push(documentID);
        emit('updateData', activeDocuments.value);
    }
}

// gets all documents from dossier
async function getDocuments() {
    axiosInstance.get('documents/?dossier=' + route.params.dossierId)
        .then(async (response) => {
            await handleTypesOfDocs(response.data);
            await handleProcessingDocs(response.data);
        })
        .catch((error) => {
        })
}

async function handleTypesOfDocs(responseData) {
    // Clear the documents array
    documents.value = [];
    // if showTypeFour is true, show all documents
    if (props.showTypeFour === true) {
        documents.value = responseData;
        setInitialValues();
        // otherwise, filter out type 4 documents
    } else {
        filterOutDocumentsOfType4(responseData);
    }
}


async function handleProcessingDocs(responseData) {
    processingDocumentIDs.value = [];
    // checks amount of docs that are still being processed
    processingDocumentIDs.value = responseData.filter(obj => obj.status === "processing").map(obj => obj.id);
    // if there are docs still being processed, after 5 seconds get those docs
    if (processingDocumentIDs.value.length >= 1 && amountOfGetDocumentLoops < 15) {
        setTimeout(() => {
            amountOfGetDocumentLoops++;
            getDocuments();
        }, 5000);
    }

}

// filters out type 4 documents
function filterOutDocumentsOfType4(docs) {
    // only add type 1,2, and 3 to documents
    for (const index in docs) {
        if (docs[index].type === 4) {
            continue
        } else {
            documents.value.push(docs[index]);
        }
    }

    setInitialValues();
};

// sets initial values for chips
function setInitialValues() {
    if (documents.value.length === 0) {
        emit('noDocumentsAvailable', true);
    } else if (documents.value.length === 1) {
        // if there is only one document, and that document is processing, then emit noDocumentsAvailable
        if (documents.value[0].status !== 'done') {
            activeDocuments.value = [];
            return

        }
        // else if there is no status field, then set that document to active
        if (!documents.value[0].status) {
            activeDocuments.value.push(documents.value[0].id);
        }
        else {
            // otherwise, set that document to active
            activeDocuments.value.push(documents.value[0].id);
        }

    }
    emit('updateData', activeDocuments.value);
}

// checks if document is active or processing
const getStatusClass = (status, id) => {
    if (activeDocuments.value.includes(id)) {
        return 'active';
    } else if (status === 'error') {
        return 'error';
    } else if (status === 'processing') {
        return 'processing'
    } else {
        return '';
    }
}
</script>

<style scoped>
.chips-container {
    text-align: start;
}

.chip {
    text-align: start;
    display: inline-block;
    padding: 0 25px;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    border-radius: 25px;
    border: 1px solid white;
    background-color: white;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.chip:hover {
    /* border: 1px solid var(--sec-dark-purple); */
    border: 1px solid #E7EAFF;
    background-color: #E7EAFF;
    cursor: pointer;
}

.active {
    border: 1px solid var(--sec-dark-purple);
    background-color: #E7EAFF;
}

.chip-content {
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    color: var(--sec-dark-purple);
}

.error {
    border: 1px solid red;
    background-color: lightpink;
    color: darkred;
}

.error:hover {
    cursor: default;
    border: 1px solid red;
    background-color: lightpink;
}

.error .chip-content {
    color: darkred;
}

.processing {
    border: 1px solid green;
    background-color: greenyellow;
    color: green;
}

.processing:hover {
    cursor: default;
    border: 1px solid green;
    background-color: greenyellow;
}

.processing .chip-content {
    color: green;
}
</style>