<template>
    <Card class="square-card">
        <template #title>
            {{ $t('ConformityExport.title') }}
        </template>
        <template #content>
            <form class="form" @submit.prevent="checkForErrors()">

                <div class="form-field">
                    <label class="form-title" for="filename">{{ $t('ConformityExport.name') }}</label>
                    <InputText id="filename" v-model="exportForm.name" aria-describedby="filename-help" />
                    <p v-if="exportForm.name.length < 1 && attemptedFormSent" class="error">{{
                $t('ConformityExport.name-explanation') }}</p>
                    <p class="error" v-if="showConformityIsBeingGenerated">{{ $t('ConformityExport.error') }}</p>
                </div>

                <TheButton class="form-button" :loading="isLoading" :disabled="disableButtons">
                    <template #text>{{ $t('ConformityExport.title') }}</template>
                </TheButton>

            </form>
        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';
import TheButton from '@/components/ui/TheButton.vue';
import InputText from 'primevue/inputtext';
import router from '@/router';
import { useRoute } from 'vue-router';
import { ref } from 'vue';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';


const props = defineProps({
    disableButtons: Boolean
})

const route = useRoute();

const exportForm = ref({
    name: '',
    dossier: route.params.dossierId
})
const attemptedFormSent = ref(false);
const isLoading = ref(false);
const showConformityIsBeingGenerated = ref(false);
let downloadCounter = 0;

function checkForErrors() {
    attemptedFormSent.value = true
    if (exportForm.value.name.length < 1) {
        return
    }
    downloadConformity();
};

async function downloadConformity() {
    isLoading.value = true; // Start loading
    const language = localStorage.getItem('language') || 'nl';
    axiosInstance.get('/qualification-export-matrix/?dossier=' + route.params.dossierId + '&language=' + language, {
        responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'conformity.xlsx');
        document.body.appendChild(link);
        link.click();
        isLoading.value = false; // End loading
    }).catch((error) => {
        // if there is an error, that means the excel still hasn't been generated
        // call generateExcel and then downloadExcel again
        generateExcel();
    });
}

function generateExcel() {
    if (downloadCounter >= 1) {
        isLoading.value = false;
        showConformityIsBeingGenerated.value = true;
        return;
    } else {
        axiosInstance.post('/qualificationMatrix/', {
            dossier: route.params.dossierId
        }).then((response) => {
            downloadCounter++;
            // call downloadConformity again after 2 seconds
            downloadConformity();

        }).catch((error) => {
            devLog(error);
        });
    }
}

</script>

<style scoped>
/* FORM */
.form,
.form-empty,
.form-undefined {
    color: #1A224C;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}

.form-button {
    margin-top: 1rem;
}

.form-title {
    font-weight: 450;
    color: var(--sec-dark-purple);
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}

#documents {
    width: 100%;
    margin-top: 1rem;
    color: var(--sec-dark-purple);
}

.button-field {
    display: flex;
    gap: 15px;
}
</style>