<template>
    <Card class="square-card">
        <template #header>
            <img :alt="$t('DashboardQualification.img-alt')" src="../../assets/images/acties-illustratie.png" />
        </template>
        <template #title>
            {{ $t('DashboardQualification.title') }}
        </template>
        <template #content>
            <p>
                {{ $t('DashboardQualification.content') }}
            </p>
        </template>
        <template #footer>
            <Button :label="$t('DashboardQualification.button-label')" rounded outlined right :disabled="isDisabled"
                @click="checkForQualifications()">
            </Button>

            <p class="error" v-if="showErrors">{{ $t('DashboardQualification.error') }}</p>
        </template>


    </Card>

</template>

<script setup>
import Card from 'primevue/card';
import Button from 'primevue/button';

import router from '@/router';
import { useRoute } from 'vue-router';
import { ref, computed } from 'vue';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';


const route = useRoute();

function goToURL(URL) {
    router.push(URL);
}

const props = defineProps({
    qualificationMatrix: String
});

// computed property, if props.qualificationMatrix is 'empty', disable the button
const isDisabled = computed(() => {
    return props.qualificationMatrix === 'empty' || props.qualificationMatrix === 'processing';
});


const showErrors = ref(false);

function checkForQualifications() {
    // if async function getQualifications returns an empty array, set showError to true
    axiosInstance.get('qualifications/?dossier=' + route.params.dossierId)
        .then(response => {
            devLog(response.data)
            if (response.data.length === 0) {
                showErrors.value = true;
            } else {
                goToURL('/dashboard/' + route.params.dossierId + '/qualification');
            }
        })
        .catch(error => {
            devLog(error);
        });
}


</script>

<style scoped>
.page-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

:deep(.p-card):hover {
    cursor: pointer;
    background-color: #E7EAFF;
}

:deep(.p-card-title),
:deep(.p-card-content) {
    font-size: 14px;
    color: var(--main-dark-purple);
}

.square-card {
    max-width: 25em;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    border-radius: 0;
    flex-basis: 48%;
    flex-grow: 1;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.dashboard-item-title {
    font-weight: 550;
    color: var(--sec-dark-purple);
}

.two-buttons {
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.dashboard-button,
.dropdown {
    min-width: 48%;
    height: 3rem;
    max-width: 48%;
}

.dropdown {
    padding: 0;
    margin: 0;
}

.dashboard-button {
    justify-content: center;
}

.dashboard-single-button {
    width: 100%;
    justify-content: center;
    height: 3rem;
}

.dashboard-button-field,
.two-buttons {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.error {
    margin-top: 1rem;
    color: red;
}
</style>