<template>
    <v-card class="square-card-page">
        <h2 class="card-title">{{ $t('ForgotPasswordView.forgot-password') }}</h2>

        <form class="form" autocomplete="off">
            <p>{{ $t('ForgotPasswordView.enter-email') }}</p>
            <div class="form-field">
                <label for="email">{{ $t('ForgotPasswordView.email') }}</label>
                <InputText autocomplete="off" type="email" v-model="emailObject.email" :placeholder="$t('ForgotPasswordView.email')" />
                <p v-if="emailIsValid === false" class="error">{{ $t('ForgotPasswordView.wrong-email') }}</p>
            </div>
            <Button icon="pi pi-send" class="forgot-password" :label="$t('ForgotPasswordView.send')" @click="checkEmail()"
                :loading="postIsLoading" />
            <p v-if="successfulPost === true || successfulPost === false"
                :class="{ success: successfulPost, error: !successfulPost }">{{ postResponse }}</p>
        </form>

    </v-card>
</template>

<script setup>

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import { ref } from 'vue';
import axios from 'axios';
import i18n from '@/i18n';

const uninterceptedAxiosInstance = axios.create();

const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
const emailObject = ref({
    "email": '',
});
const postIsLoading = ref(false);
const successfulPost = ref(null);
const postResponse = ref('');
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const emailIsValid = ref(null); 
const { t } = i18n.global;


function requestPasswordChange() {
    postIsLoading.value = true;

    uninterceptedAxiosInstance.post(BASE_URL + 'auth/password_reset/', emailObject.value)
    .then((response) => {
            postIsLoading.value = false;
            successfulPost.value = true;
            postResponse.value = t('ForgotPasswordView.success')
        })
        .catch((error) => {
            postIsLoading.value = false;
            successfulPost.value = false;
            postResponse.value =  t('ForgotPasswordView.error')
        })
        .then(() => {
        })
};

function checkEmail() {
    if (emailObject.value.email.match(emailRegex)) {
        emailIsValid.value = true;
        requestPasswordChange();
    } else {
        emailIsValid.value = false;
        return
    }
}
</script>

<style scoped>
.form-field {
    margin: 1rem;
}

.error {
    color: red;
}

.success {
    color: green;
}

.form-field {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    text-align: start;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}

:deep(.p-button.forgot-password) {
    background-color: #1A224C;
    border-color: #1A224C;
    color: white;
}

:deep(.p-button.forgot-password:hover) {
    background-color: #bfc7ff;
    border-color: #bfc7ff;
    color: #1A224C;
}
</style>