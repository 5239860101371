<template>
	<div class="card flex justify-content-center">

		<Toast />

		<Dialog v-if="typeOfDialog === 'addRisk'" v-model:visible="dialogIsVisible" modal
			:header="$t('RightClickRisksFunctionality.new-risk')" :style="{ width: '75vw' }">
			<AddRiskForm :selectedText="selectedText" :documentID="currentlySelectedDocument"
				@form-finished="showAddAction" @closeForm="dialogIsVisible = false" :paragraphID="paragraphID"
				@risk-added="emit('riskEdited', data.id)">
			</AddRiskForm>
		</Dialog>
		<Dialog v-else-if="typeOfDialog === 'addAction'" v-model:visible="dialogIsVisible" modal
			:header="$t('RightClickRisksFunctionality.new-action')" :style="{ width: '75vw' }">
			<AddActionForm :selectedText="selectedText" :riskID="selectedRiskID" :warningID="selectedWarningID"
				:documentID="currentlySelectedDocument" @closeForm="dialogIsVisible = false">
			</AddActionForm>
		</Dialog>
		<Dialog v-else-if="typeOfDialog === 'editRisk'" v-model:visible="dialogIsVisible" modal
			:header="$t('RightClickRisksFunctionality.edit-risk')" :style="{ width: '75vw' }">
			<AddRiskForm :selectedText="selectedText" :documentID="currentlySelectedDocument"
				:existingRisk="selectedRisk" @form-finished="showAddAction" @risk-edited="emit('riskEdited', data.id)"
				@closeForm="dialogIsVisible = false">
			</AddRiskForm>
		</Dialog>
		<Dialog v-else-if="typeOfDialog === 'showLaw'" v-model:visible="dialogIsVisible" modal
			:header="$t('RightClickRisksFunctionality.assistance')" :style="{ width: '75vw' }">
			<LawAndAssistance :legal="legal" :explanation="explanation" @closeForm="dialogIsVisible = false">
			</LawAndAssistance>
		</Dialog>
		<Dialog v-else-if="typeOfDialog === 'sectionRisk'" v-model:visible="dialogIsVisible" modal
			:header="$t('RightClickRisksFunctionality.new-risk')" :style="{ width: '75vw' }">
			<AddSectionRiskForm :selected-paragraphs="props.selectedParagraphs" :documentID="currentlySelectedDocument"
				:selected-paragraph-objects="props.selectedParagraphObjects" @closeForm="dialogIsVisible = false"
				@risk-added="emit('riskAdded')" @paragraphsHaveBeenChanged="paragraphsHaveBeenChanged">
			</AddSectionRiskForm>
		</Dialog>
	</div>

	<ContextMenu ref="menu" :model="contextMenuItems" style="width: fit-content;" />
</template>

<script setup>
import ContextMenu from 'primevue/contextmenu';
import Dialog from 'primevue/dialog';
import AddActionForm from '@/components/forms/AddActionForm.vue'
import AddRiskForm from '@/components/forms/AddRiskForm.vue';
import LawAndAssistance from '@/components/functional/right-click-risks-functionality/LawAndAssistance.vue';
import AddSectionRiskForm from '@/components/forms/AddSectionRiskForm.vue';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import i18n from '@/i18n';
import { devLog } from '@/helpers/helpers';

const props = defineProps({
	event: Object,
	data: Object,
	currentlySelectedDocument: Number,
	selectedParagraphs: Array,
	selectedParagraphObjects: Array,
	selectedRisks: Array
})

const { t } = i18n.global;

const emit = defineEmits(['riskEdited', 'deleteRisk', 'deleteSectionRisk', 'riskAdded', 'paragraphsHaveBeenChanged'])

const route = useRoute();
const router = useRouter();
const confirm = useConfirm();
const toast = useToast();

// added ref value because this confirm popup appears when parent component uses confirm popups
const showThisConfirm = ref(false);

// popup
const dialogIsVisible = ref(false);
const typeOfDialog = ref(null);
// action form
const selectedText = ref(null);
// context menu
const contextMenuItems = ref([])

const paragraphID = ref(null);
const selectedRiskID = ref(null);
const selectedWarningID = ref(null);
const selectedRisk = ref(null);
// law and assistance
const legal = ref();
const explanation = ref();

const currentlySelectedDocument = ref(props.currentlySelectedDocument)
const menu = ref();

watch(() => [props.event, props.data], () => {
	onDataTableRightClick(props.event, props.data)
})


// shows context menu on right click
const onDataTableRightClick = (event, data) => {
	// gets selected text on right click
	let text = window.getSelection().toString()
	// function that will set text in form to either be selected text, or standard text in column
	addSelectedTextToForm(text, data);
	// empty out context menu
	contextMenuItems.value = [];
	// transform data to correct form, so data can be used in all forms
	let correctForm = transformToCorrectForm(data);

	// this if statement checks if the right-clicked data is a risk, section, warning or paragraph
	if (data.section) {
		// this is a risk, used in SummaryRisksView
		addRiskItemsToMenu(data, correctForm);
	} else if (data.hits) {
		// this is a search, used in SearchView
		addSearchItemsToMenu(data, correctForm);
	} else if (data.answer && data.consequence) {
		addWarningItemsToMenu(data, correctForm);
	} else {
		// this is a paragraph, used in DetailedAnalysisView
		if (props.selectedParagraphs && props.selectedParagraphs.length >= 1) {
			// if selectedParagraphs is not empty, then we call AddSelectionItemsOfParagraphToMenu
			AddSelectionItemsOfParagraphToMenu(data, correctForm);
		} else {
			// else we call addParagraphItemsToMenu
			addParagraphItemsToMenu(data, correctForm);
		}
	}
	menu.value.show(event);
};

function addRiskItemsToMenu(data, correctForm) {
	// of the user has selected a risk or multiple risks, the context menu will be different
	if (props.selectedRisks && props.selectedRisks.length >= 1) {
		contextMenuItems.value = [
			{ label: t('RightClickRisksFunctionality.delete-selected-risks'), icon: 'pi pi-fw pi-trash', command: () => { emit('deleteRisk', props.selectedRisks, "delete"); } },
			{ label: t('RightClickRisksFunctionality.keep-selected-risks'), icon: 'pi pi-fw pi-plus', command: () => { emit('deleteRisk', props.selectedRisks, "keep"); } },
		];
		// if no selection has been made, the normal context menu will be shown
	} else {
		contextMenuItems.value = [
			{ label: t('RightClickRisksFunctionality.edit-risk'), icon: 'pi pi-fw pi-pencil', command: () => { showEditRisk(data.document, undefined, correctForm); } },
			{ label: t('RightClickRisksFunctionality.add-action'), icon: 'pi pi-fw pi-plus', command: () => { showAddAction(data.id, data.document); } },
			{ label: t('RightClickRisksFunctionality.more-context'), icon: 'pi pi-fw pi-external-link', command: () => { goToParagraphOfRisk(data.id, data.document, data.type, undefined); } },
		];
	}
};

function addWarningItemsToMenu(data, correctForm) {
	contextMenuItems.value = [
		{ label: t('RightClickRisksFunctionality.add-action'), icon: 'pi pi-fw pi-plus', command: () => { showAddAction(null, null, data.id); } },
	];

}

function addParagraphItemsToMenu(data, correctForm) {
	// there are two possibilities, either the paragraph has a risk_section, or it does not
	// if it does not have a risk_section, the user can add a risk
	// if it does have a risk_section the user can edit the risk_section, delete it, check the assistance	 or add a risk
	if (data.risk_section) {
		contextMenuItems.value.push(
			{ label: t('RightClickRisksFunctionality.add-risk'), icon: 'pi pi-fw pi-plus', command: () => { showAddRisk(currentlySelectedDocument.value, data.id, undefined); } },
			{
				label: t('riskDetailsTable.' + data.risk_section.name) + " aanpassen", icon: 'pi pi-fw pi-pencil', command: () => { showEditRisk(data.risk_section.document, data.id, data.risk_section); }
			},
			{
				label: t('riskDetailsTable.' + data.risk_section.name) + " verwijderen", icon: 'pi pi-fw pi-trash', command: () => { deleteSectionRisk(data); },
			},
			{
				label: t('RightClickRisksFunctionality.assistance'), icon: 'pi pi-fw pi-info-circle', command: () => { showAssistance(data.risk_section.name, data.document__id); }
			},
		);
	} else {
		contextMenuItems.value.push(
			{ label: t('RightClickRisksFunctionality.add-risk'), icon: 'pi pi-fw pi-plus', command: () => { showAddRisk(currentlySelectedDocument.value, data.id, undefined); } },

		);

	}
};

//* OLD FUNCTION, keep here in case it is needed in the future
// function addParagraphItemsToMenu(data, correctForm) {
// 	if (data.label === 'None') {
// 		contextMenuItems.value = [
// 			{ label: t('RightClickRisksFunctionality.add-risk'), icon: 'pi pi-fw pi-plus', command: () => { showAddRisk(currentlySelectedDocument.value, data.id, undefined); } },
// 		];
// 		// if the there is a risk_section, just push this to items
// 		if (data.risk_section) {
// 			contextMenuItems.value.push(
// 				{
// 					label: t('riskDetailsTable.' + data.risk_section.name) + " aanpassen", icon: 'pi pi-fw pi-pencil', command: () => { showEditRisk(data.risk_section.document, data.id, data.risk_section); }
// 				},
// 				{
// 					label: t('riskDetailsTable.' + data.risk_section.name) + " verwijderen", icon: 'pi pi-fw pi-trash', command: () => { deleteSectionRisk(data); },
// 				},
// 				{ 
// 					label: t('RightClickRisksFunctionality.assistance'), icon: 'pi pi-fw pi-info-circle', command: () => { showAssistance(data.risk_section.name, data.document__id); } 
// 				},
// 			);
// 		}
// 	} else {
// 		// if there is a label, then there are two options
// 		// either it also has a risk_section as well, or only a label/risk
// 		if (!data.risk_section) {
// 			// without a risk_section
// 			contextMenuItems.value = [
// 				{ label: t('RightClickRisksFunctionality.edit-risk'), icon: 'pi pi-fw pi-pencil', command: () => { showEditRisk(data.risk.document, undefined, correctForm); } },
// 				{ label: t('RightClickRisksFunctionality.add-action'), icon: 'pi pi-fw pi-plus', command: () => { showAddAction(data.risk.id, data.risk.document); } },
// 				{ label: t('RightClickRisksFunctionality.assistance'), icon: 'pi pi-fw pi-info-circle', command: () => { showAssistance(data.risk.name, data.document__id); } },
// 				{ label: t('RightClickRisksFunctionality.delete-risk'), icon: 'pi pi-fw pi-trash', command: () => { showDeleteRisk(data); } },
// 			];
// 		} else {
// 			// if there is a risk_section, the user must also be able to edit this
// 			contextMenuItems.value = [
// 				{ label: t('riskDetailsTable.' + data.risk.name) + " aanpassen", icon: 'pi pi-fw pi-pencil', command: () => { showEditRisk(data.risk.document, undefined, correctForm); } },
// 				{
// 					label: t('riskDetailsTable.' + data.risk_section.name) + " aanpassen", icon: 'pi pi-fw pi-pencil', command: () => { showEditRisk(data.risk_section.document, data.id, data.risk_section); }
// 				},
// 				{ label: t('RightClickRisksFunctionality.add-action'), icon: 'pi pi-fw pi-plus', command: () => { showAddAction(data.risk.id, data.document); } },
// 				{ label: t('RightClickRisksFunctionality.assistance'), icon: 'pi pi-fw pi-info-circle', command: () => { showAssistance(data.risk.name, data.document__id); } },
// 				{ label: t('riskDetailsTable.' + data.risk.name) + " verwijderen", icon: 'pi pi-fw pi-trash', command: () => { showDeleteRisk(data); } },
// 				{
// 					label: t('riskDetailsTable.' + data.risk_section.name) + " verwijderen", icon: 'pi pi-fw pi-trash', command: () => { deleteSectionRisk(data); },
// 				}
// 			];

// 		}
// 	}
// };

function AddSelectionItemsOfParagraphToMenu(data, correctForm) {
	contextMenuItems.value = [
		{ label: t('RightClickRisksFunctionality.add-risk'), icon: 'pi pi-fw pi-plus', command: () => { showAddSectionRisk(data); } },
	];
};

function addSearchItemsToMenu(data, correctForm) {
	contextMenuItems.value = [
		{ label: t('RightClickRisksFunctionality.add-risk'), icon: 'pi pi-fw pi-plus', command: () => { showAddRisk(data.document_id, data.id, undefined); } },
		// { label: t('RightClickRisksFunctionality.assistance'), icon: 'pi pi-fw pi-info-circle', command: () => { showAssistance(correctForm, data.document__id); } },
		{ label: t('RightClickRisksFunctionality.more-context'), icon: 'pi pi-fw pi-external-link', command: () => { goToParagraphOfRisk(data.id, data.document_id, undefined, data.seq,); } },
	];
};

function addSelectedTextToForm(text, data) {
	if (text) {
		selectedText.value = text;
	} else if (data.section) {
		selectedText.value = data.section;
	} else if (data.text) {
		selectedText.value = data.text;
	} else if (data.answer) {
		// if it is a warning, add the answer to the form
		selectedText.value = data.answer;
	}
};

function showAddRisk(document, paragraph, selectedParagraphs) {
	if (selectedParagraphs && selectedParagraphs.length > 1) {
		showAddSectionRisk();
	} else {
		typeOfDialog.value = 'addRisk';
		currentlySelectedDocument.value = document;
		paragraphID.value = paragraph
		dialogIsVisible.value = true;
	}
};

function showEditRisk(document, paragraph, risk) {
	typeOfDialog.value = 'editRisk';
	currentlySelectedDocument.value = document;
	paragraphID.value = paragraph
	selectedRisk.value = risk;
	dialogIsVisible.value = true;
};

function showAssistance(riskName, doc) {
	// get currently selected language
	const selectedLanguage = localStorage.getItem('language').toUpperCase() || 'nl';
	axiosInstance.get('/advice/?dossier=' + route.params.dossierId + '&risk_name=' + riskName + '&language=' + selectedLanguage)
		.then((response) => {
			legal.value = response.data.data.legal;
			explanation.value = response.data.data.explanation;
			dialogIsVisible.value = true;
			typeOfDialog.value = "showLaw";
			currentlySelectedDocument.value = doc;

		})
		.catch((error) => {
			devLog(error)
		})
};

function showAddAction(riskID, doc, warningId) {
	if (warningId) {
		selectedRiskID.value = null;
		selectedWarningID.value = warningId;
	} else {
		selectedRiskID.value = riskID;
		selectedWarningID.value = null;
	}
	typeOfDialog.value = 'addAction';
	dialogIsVisible.value = true;
	currentlySelectedDocument.value = doc;
};

function showAddSectionRisk() {
	typeOfDialog.value = 'sectionRisk';
	dialogIsVisible.value = true;
};

function deleteSectionRisk(data) {
	confirm.require({
		message: t('RightClickRisksFunctionality.delete-section-risk-confirm') + '"' + t('riskDetailsTable.' + data.risk_section.name),
		header: t('RightClickRisksFunctionality.delete-confirm-action'),
		icon: 'pi pi-info-circle',
		rejectClass: 'p-button-text p-button-text',
		acceptClass: 'p-button-danger p-button-text',
		accept: () => {
			emit('deleteSectionRisk', data)
			showThisConfirm.value = false;
		},
		reject: () => {
			showThisConfirm.value = false;
			// toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
		}
	});
}

function showDeleteRisk(paragraph) {
	confirm.require({
		message: t('RightClickRisksFunctionality.delete-confirm'),
		header: t('RightClickRisksFunctionality.delete-confirm-action'),
		icon: 'pi pi-info-circle',
		rejectClass: 'p-button-text p-button-text',
		acceptClass: 'p-button-danger p-button-text',
		accept: () => {
			emit('deleteRisk', paragraph)
			showThisConfirm.value = false;
		},
		reject: () => {
			showThisConfirm.value = false;
			// toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
		}
	});
};

// this function transforms the data that is received from the right-click into a form that can be used in almost all forms
function transformToCorrectForm(data) {
	let correctForm = {
		"name": data.risk__name || data.risk?.name || data.name,
		"chapter": data.risk__chapter || data.risk?.chapter || data.chapter,
		"document": data.document__id || data.risk?.document || data.document,
		"section": data.text || data.risk?.section || data.section,
		"dossier": parseInt(route.params.dossierId),
		"id": data.risk__id || data.risk?.id || data.id,
	};

	if (data.risk__comment__legal && data.risk__comment__explanation) {
		correctForm.comment = {
			"legal": data.risk__comment__legal,
			"explanation": data.risk__comment__explanation
		};
	}
	if (props.selectedText) {
		correctForm.section = props.selectedText;
	}

	return correctForm;
}

function goToParagraphOfRisk(risk, document, type, seq) {
	if (seq) {
		const routeData = router.resolve({ name: 'detailed-analysis', query: { document: document, seq: seq } });
		window.open(routeData.href, '_blank');
	} else {
		let url;
		if (type === "section") {
			url = 'paragraphs/?document=' + document + '&section_risk=' + risk;
		} else {
			url = 'paragraphs/?document=' + document + '&risk=' + risk;
		}
		axiosInstance.get(url)
			.then((response) => {
				const routeData = router.resolve({ name: 'detailed-analysis', query: { document: document, seq: response.data.results[0].seq } });
				window.open(routeData.href, '_blank');
			})
			.catch((error) => {
			})
	}
}

function paragraphsHaveBeenChanged(changedParagraphs) {
	emit('paragraphsHaveBeenChanged', changedParagraphs);
}

</script>