<template>
    <div class="full-page">
        <v-card class="square-card" v-if="documents.length !== 0">
            <DataTable :value="documents" table-style="font-size: 14px" paginator :rows="8">
                <Column field="document_name" :header="$t('ListOfDocuments.document_name')"></Column>
                <Column field="type" :header="$t('ListOfDocuments.type')">
                    <template #body="{ data }">
                        {{ $t('ListOfDocuments.' + data.type) }}
                    </template>
                </Column>
                <Column field="author" :header="$t('ListOfDocuments.author')">
                    <template #body="{ data }">
                        {{ data.author }}
                    </template>
                </Column>
                <Column field="status" :header="$t('ListOfDocuments.status')">
                    <template #body="{ data }">
                        <span class="pi pi-verified" v-if="data.status === 'done'"></span>
                        <span class="pi pi-times-circle" v-else-if="data.status === 'error'"></span>
                        <span class="pi pi-hourglass" v-else-if="data.status === 'processing'"></span>
                    </template>
                </Column>
                <Column field="secure_url" :header="$t('ListOfDocuments.actions')">
                    <template #body="{ data }">
                        <Toast />
                        <span class="p-buttonset">
                            <div class="buttons-field">
                                <Button icon="pi pi-file-pdf" size="small" @click="goToPDF(data.secure_url)"
                                    :disabled="data.secure_url === null"
                                    :title="$t('ListOfDocuments.go-to-dashboard')" />

                                <Button icon="pi pi-trash" class="delete" size="small"
                                    @click="deleteConfirm($event, data)" :title="$t('ListOfDocuments.delete-doc')" />
                            </div>
                        </span>
                    </template>
                </Column>
            </DataTable>
        </v-card>
    </div>
</template>

<script setup>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Toast from 'primevue/toast';

import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';

import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';


// import { getUserEmailFromID } from '@/helpers/helpers';


const confirm = useConfirm();
const toast = useToast();
const route = useRoute();
const store = useStore();

const documents = ref([]);
// const users = ref();

onMounted(() => {
    getDocuments();
    // getUsers();
})

function goToPDF(URL) {
    window.open(URL, '_blank')
}

function getDocuments() {
    axiosInstance.get('documents/?dossier=' + route.params.dossierId)
        .then((response) => {
            documents.value = response.data;
        })
        .catch((error) => {
            devLog(error)
        })
}

function deleteConfirm(event, document) {
    confirm.require({
        target: event.currentTarget,
        message: 'Weet u zeker dat u dit document wilt verwijderen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nee',
        acceptClass: 'confirm-delete',
        accept: () => {
            deleteDocument(document);

        },
        reject: () => {
            toast.add({ severity: 'error', summary: 'Geannuleerd', detail: 'Het document werd niet verwijderd', life: 3000 });
        }
    });
};

async function deleteDocument(document) {
    axiosInstance.delete('documents/' + document.id + '/')
        .then(() => {
            handleSuccessfulDocumentDelete(document);
        })
        .catch((error) => {
            devLog(error)
        })
        .then(() => {
            //
        })
};

async function handleSuccessfulDocumentDelete(document) {
    // const dossier = await getDossier();
    // if (dossier.summary_status === 'needs_update' && document.status === 'processing') {
    //     store.dispatch('initiateCheckingForSummariesInDossiers');
    // }
    toast.add({ severity: 'info', summary: 'Succes', detail: 'Het document werd succesvol verwijderd.', life: 3000 });
    getDocuments();

}

async function getDossier() {
    axiosInstance.get('dossiers/' + route.params.dossierId + '/')
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            devLog(error)
        })
}

</script>

<style scoped>
.full-page {
    border-radius: 0;
}

:deep(.p-button:not(.export)) {
    border-color: #1A224C;
    background-color: white;
    color: #1A224C;
    font-size: 14px;
    padding: 0.3rem;
}

:deep(.p-button:hover:not(.export)) {
    background-color: #bfc7ff;
    border-color: #bfc7ff;
}

:deep(.p-button.delete:hover:not(.export)) {
    background-color: red;
    border-color: red;
    color: white;
}

:deep(.p-button.edit:hover:not(.export)) {
    background-color: green;
    border-color: green;
    color: white;
}

:deep(.p-button.export) {
    background-color: #1A224C;
    border-color: #1A224C;
    color: white;
}

:deep(.p-button.export:hover) {
    background-color: #bfc7ff;
    border-color: #bfc7ff;
    color: #1A224C;
}
</style>