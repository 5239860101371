<template>
    <div class="page-wrapper">
        <breadcrumbs-title></breadcrumbs-title>
        <Card class="square-card">
            <template #content>
                <form class="form" @submit.prevent="handleSubmit">

                    <div class="form-field">
                        <h4 class="form-title">{{ $t('addDossier.name') }}</h4>
                        <textarea v-model="newDossier.name" id="name"></textarea>
                        <p v-if="formIsValid === false" class="error">{{ $t('addDossier.error') }}</p>
                        <p v-if="nameIsTaken === true" class="error" id="name-error">{{ $t('addDossier.nameError') }}</p>
                    </div>

                    <div class="form-field">
                        <h4 class="form-title">{{ $t('addDossier.contractor') }}</h4>
                        <textarea v-model="newDossier.contractor" id="contractor"></textarea>
                    </div>

                    <div class="form-field">
                        <h4 class="form-title">{{ $t('addDossier.submission_date') }}</h4>
                        <Calendar id="submission_date" v-model="newDossier.submission_date" dateFormat="yy-mm-dd" show-icon />
                    </div>

                    <the-button class="form-button" id="form-button">
                        <template #text>{{ $t('addDossier.addDossier') }}</template>
                    </the-button>

                </form>
            </template>
        </Card>
    </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import Calendar from 'primevue/calendar';
import TheButton from '@/components/ui/TheButton.vue';
import Card from 'primevue/card';

import { formatDate } from '@/helpers/helpers';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';

const router = useRouter();
const formIsValid = ref(true);
const nameIsTaken = ref(false);

// only name is needed, the rest are optional
const newDossier = ref({
    "name": null,
    "submission_date": null,
    "contractor": null
});

async function handleSubmit() {
    nameIsTaken.value = false;
    if (!newDossier.value.name) {
        formIsValid.value = false;
        return
    } else {
        if (newDossier.value.submission_date) {
            formatDate(newDossier.value, "submission_date")
        }
        formIsValid.value = true;
        
        return postNewDossier()
    }
}

async function postNewDossier() {
    return axiosInstance.post('/dossiers/', newDossier.value)
        .then((data) => {
            router.push('/dossiers');
            
        })
        .catch((error) => {
            // devLog(error.response);
            devLog("error")
            nameIsTaken.value = true;
        })
}

function testOne() {
    devLog('testOne');
}

</script>

<style scoped>
.square-card {
    border-radius: 0;
    min-width: fit-content;
}

/* FORM */
.form,
.form-empty,
.form-undefined {
    color: #1A224C;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    max-width: 35rem;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-button {
    margin-top: 1rem;
}

.form-title {
    font-weight: 450;
    color: var(--sec-dark-purple);
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}
</style>