<template>
    <div v-if="users">
        <Dropdown v-model="selectedUser" :options="users" optionLabel="email"
            :placeholder="$t('UsersDropdown.select-user')" @onChange="updateUser" />
    </div>
</template>

<script setup>
import Dropdown from 'primevue/dropdown';
import { ref, onMounted, watch } from 'vue';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';

const props = defineProps({
    selectedUser: Object
})

const users = ref([]);
const selectedUser = ref(props.selectedUser);

onMounted(() => {
    getUsers();
})

const emit = defineEmits(['updateUser']);

// watcher, that once prop.selectedUser changes, assigns selectedUser.value to be props.selectedUser
// this is necessary for when the user gets existing form data, so the dropdown can be preselected
watch(props, (newProps) => {
    selectedUser.value = newProps.selectedUser;
});

function getUsers() {
    axiosInstance.get('/users/')
        .then((response) => {
            devLog(response.data);
            users.value = response.data;
        })
        .catch(() => {

        })
        .then(() => {
        })
}

function updateUser() {
    emit('updateUser', selectedUser.value);
}

</script>
```