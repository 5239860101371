<template>
    <form class="action-form" @submit.prevent="checkIfFormIsValid">

        <div class="form-field">

            <div class="form-field">
                <h4 class="form-title">{{ $t('detailedAnalysisForm.selectedText') }}:</h4>
                <p v-if="!props.selectedText" class="error">{{ $t('detailedAnalysisForm.selectedTextError') }}</p>
                <v-textarea v-else auto-grow rows="1" type="text" class="text-field" v-model="actionForm.section" />
            </div>


            <div class="form-field">
                <h4 class="form-title">{{ $t('actionsForm.comment') }}:</h4>
                <textarea v-model="actionForm.comment"></textarea>
            </div>

            <div class="form-field">
                <h4 class="form-title">{{ $t('actionsForm.action-assign') }}</h4>
                <Dropdown v-model="actionForm.info" :options="actions" optionGroupLabel="label"
                    optionGroupChildren="items" :placeholder="$t('actionsForm.no-input')" class="w-full md:w-14rem">
                    <template #value="slotProps">
                        <div v-if="actionForm.info !== null">{{ $t('actionsForm.' + actionForm.info) }}</div>
                    </template>
                    <template #optiongroup="slotProps">
                        <div class="flex align-items-center">
                            <div>{{ $t('actionsForm.' + slotProps.option.label) }}</div>
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div class="flex align-items-center">
                            <div>{{ $t('actionsForm.' + slotProps.option) }}</div>
                        </div>
                    </template>
                </Dropdown>
                <p v-if="!formIsValid && actionForm.info === 'no-input'" class="error">{{
        $t('actionsForm.selectDropdown') }}</p>

            </div>
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('actionsForm.user-assign') }}</h4>
            <Dropdown v-model="actionForm.assigned_user" :options="users" optionLabel="email" optionValue="id"
                :placeholder="$t('detailedAnalysisForm.assignUser')">
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div v-if="slotProps.option.email === null">{{ $t('detailedAnalysisForm.noUser') }}</div>
                        <div v-else>{{ slotProps.option.email }}</div>
                    </div>
                </template>
            </Dropdown>

        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('actionsForm.deadline') }}:</h4>
            <Calendar v-model="actionForm.deadline" dateFormat="yy-mm-dd" :minDate="minDate" show-icon />
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('actionsForm.completed') }}:</h4>
            <Checkbox v-model="actionForm.completed" :binary="true" />
        </div>

        <div class="buttons-field">
            <Button :label="$t('detailedAnalysisForm.cancel')" icon="pi pi-times" @click="emit('closeForm')" text />
            <Button :label="$t('detailedAnalysisForm.saveAction')" type="submit" icon="pi pi-check" autofocus />
        </div>
    </form>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';

import actions from '@/assets/JSON/actions.json';
import axiosInstance from '@/services/interceptor';
import { formatDate } from '@/helpers/helpers';
import { devLog } from '@/helpers/helpers';



const props = defineProps({
    selectedText: String,
    riskID: Number,
    warningID: Number,
    documentID: Number
})

onMounted(() => {
    getUsers();
})

const route = useRoute();

const emit = defineEmits(['closeForm'])

const users = ref([]);
const minDate = ref(new Date());

const actionForm = ref({
    "info": null,
    "comment": null,
    "section": props.selectedText,
    "risk": props.riskID,
    "warning": props.warningID,
    "document": props.documentID,
    "dossier": route.params.dossierId,
    "assigned_user": null,
    "deadline": null,
    "completed": null,
    "betrouwbaarheid": "manual"
});

const formIsValid = ref(true);

function checkIfFormIsValid() {
    // here decides if the action needs to be assigned to a risk or a warning
    if (props.riskID) {
        actionForm.value.risk = props.riskID;
        // delete warning property from actionForm
        delete actionForm.value.warning;
        createAction("risk");

    } else if (props.warningID) {
        actionForm.value.warning = props.warningID;
        // delete risk property from actionForm
        delete actionForm.value.risk;
        createAction("warning");
    }
}


function createAction() {
    actionForm.value.document = props.documentID;
    // checks if a date is present
    if (actionForm.deadline !== null) {
        formatDate(actionForm.value, 'deadline')
    }
    devLog(actionForm.value)
    axiosInstance.post('actions/', actionForm.value)
        .then((response) => {
            // add new risk id to actionForm
            emit('closeForm');
        })
        .catch((error) => {
            //handle error
            devLog(error);
        })
        .then(() => {
            //always executed
        });
}

function getUsers() {
    axiosInstance.get('/users/')
        .then((response) => {
            users.value = response.data;
            users.value.unshift({
                "id": null,
                "email": null
            })
        })
        .catch(() => {

        })
        .then(() => {
        })
}


</script>

<style scoped>
/* FORM */
.action-form,
.action-form-empty,
.action-form-undefined {
    color: #1A224C;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    width: 100%;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-button {
    margin-top: 1rem;
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}

.text-field {
    color: var(--main-dark-purple);
    font-style: italic;
}
</style>