<template>
    <div class="page-wrapper">
        <BreadcrumbsTitle />
        <div class="flex-wrapper">
            <QualificationsExport :disableButtons="disableButtons" />
            <ConformityExport :disableButtons="disableButtons" />

        </div>
    </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import QualificationsExport from './dashboard-rapport/QualificationsExport.vue';
import ConformityExport from './dashboard-rapport/ConformityExport.vue';

import Card from 'primevue/card';

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axiosInstance from '@/services/interceptor';

const route = useRoute();

onMounted(() => {
    getQualificationMatrix();
})

const disableButtons = ref(false);

function getQualificationMatrix() {
    axiosInstance.get('qualificationMatrix/?dossier=' + route.params.dossierId)
        .then((response) => {
            // call handlePollingLogic with the response data
            if (response.data.length > 0) {
                handleResponse(response.data[0].status);
            } else {
                handleResponse("empty");
            }
        })
        //handle error
        .catch((error) => {
        })
        //always executed
        .then(() => {
        });
}

function handleResponse(status) {
    if (status === "empty" || status == "processing") {
        disableButtons.value = true;
    } else if (status === "done") {
        disableButtons.value = false;
    } else {
        disableButtons.value = true;
    }
}


</script>

<style scoped>
.flex-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.flex-item {
    flex: 1;
    width: 100%;
    margin: 1rem;
}

:deep(.p-card-title),
:deep(.p-card-content) {
    font-size: 14px;
    color: var(--main-dark-purple);
}


.square-card {
    justify-content: flex-start;
    display: flex;
    flex-direction: row;

    text-align: start;
    border-radius: 0;
    flex-grow: 1;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.content-flex {
    display: flex;
    width: 50%;
    margin: 2rem;
}
</style>