import axios from "axios";
import store from "@/store";

const BASE_URL = import.meta.env.VITE_APP_BASE_URL;

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Utility function to get CSRF token from cookies
function getCsrfToken() {
  const name = "csrftoken=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.startsWith(name)) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return null;
}

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = store.getters.getJwt;
    // console.log("Current JWT token:", token);

    // If a token is present add it to the header
    if (token) {
      config.headers.Authorization = `JWT ${token}`;
    }

    // Add CSRF token to headers
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await axiosInstance.post("auth/refresh_token/", {
          refresh: store.getters.getRefresh,
        });
        // console.log("Token refreshed successfully:", response.data);

        // Update the tokens in the store
        store.dispatch("refreshToken", {
          newToken: response.data.access,
          refreshToken: response.data.refresh,
        });

        // Update the Authorization header with the new token
        axiosInstance.defaults.headers.common['Authorization'] = `JWT ${response.data.access}`;
        originalRequest.headers.Authorization = `JWT ${response.data.access}`;

        // Retry the original request with the new token
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // console.error("Token refresh failed:", refreshError);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;