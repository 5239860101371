import { createApp } from "vue";

import App from "./App.vue";
// import './style.css'
import router from "./router";
import store from "./store";

import i18n from "./i18n";

// PrimeVue
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

// Vuetify
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import "primeflex/primeflex.css";
import "vuetify/styles";
import "primeicons/primeicons.css";

const vuetify = createVuetify({
  components,
  directives,
});

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(vuetify)
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(ToastService)
  .mount("#app");
