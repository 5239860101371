<template>
    <svg v-if="props.className" :class="props.className" xmlns="http://www.w3.org/2000/svg" id="fi_3405244"
        data-name="Layer 2" viewBox="0 0 24 24">
        <path
            d="M19,7a1,1,0,0,0-1,1V19.191A1.92,1.92,0,0,1,15.99,21H8.01A1.92,1.92,0,0,1,6,19.191V8A1,1,0,0,0,4,8V19.191A3.918,3.918,0,0,0,8.01,23h7.98A3.918,3.918,0,0,0,20,19.191V8A1,1,0,0,0,19,7Z">
        </path>
        <path d="M20,4H16V2a1,1,0,0,0-1-1H9A1,1,0,0,0,8,2V4H4A1,1,0,0,0,4,6H20a1,1,0,0,0,0-2ZM10,4V3h4V4Z">
        </path>
        <path d="M11,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
        <path d="M15,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z">
        </path>
    </svg>
</template>

<script setup>
const props = defineProps({
    className: String
})
</script>