export default {
  setAuthUser(state, { authUser, isAuthenticated }) {
    state.authUser = authUser;
    state.isAuthenticated = isAuthenticated;
  },
  updateToken(state, { newToken, refreshToken }) {
    state.jwt = newToken
    state.refresh = refreshToken
  },
  removeToken(state) {
    state.jwt = null;
  },
};
