<template>
	<TabView>
		<TabPanel :header="t('lawAndAssistance.advice')">
			<div class="full-page">

				<div class="nav-sidebar" v-if="props.legal && props.explanation">
					<p class="nav-title" v-for="title, index in props.explanation"
						:class="{ active: currentExplanationPage === index }" @click="setCurrentExplanationPage(index)">
						{{ title.Title }}
					</p>
				</div>

				<div class="menu-container">

					<!-- <div class="comment" v-if="props.riskObject && !props.riskObject.comment">
						<h3 class="sub-title">{{ $t('menuComments.noCommentTitle') }}</h3>
						<div class="comment-body">
							<p>{{ $t('menuComments.noComment') }}</p>
						</div>
					</div> -->

					<div class="comment" v-if="props.legal && props.explanation">

						<div class="member-sector" v-if="props.explanation[currentExplanationPage]">
							<h3 class="modal-header">{{ props.explanation[currentExplanationPage].Title
								}}
								- {{ props.explanation[currentExplanationPage].Member }}</h3>
							<p class="modal-text" v-html="props.explanation[currentExplanationPage].Text"></p>
						</div>

					</div>
				</div>

			</div>

		</TabPanel>

		<TabPanel header="Wat zegt de wet?">

			<div class="full-page">

				<div class="nav-sidebar" v-if="props.legal && props.explanation">
					<p class="nav-title" v-for="title, index in props.legal"
						:class="{ active: currentLegalPage === index }" @click="setCurrentLegalPage(index)">
						{{ title.Member }}
					</p>
				</div>

				<div class="menu-container">

					<!-- <div class="comment" v-if="props.riskObject && !props.riskObject.comment">
						<h3 class="sub-title">{{ $t('menuComments.noCommentTitle') }}</h3>
						<div class="comment-body">
							<p>{{ $t('menuComments.noComment') }}</p>
						</div>
					</div> -->

					<div class="comment" v-if="props.legal && props.explanation">

						<div class="member-sector" v-if="props.legal[currentLegalPage]">
							<h3 v-if="props.legal[currentLegalPage].Derogation" class="modal-header derogation">
								{{ props.legal[currentLegalPage].Derogation }}
							</h3>
							<h3 class="modal-header">{{ props.legal[currentLegalPage].Sector }}
								- {{ props.legal[currentLegalPage].Member }}</h3>
							<p class="modal-text" v-html="props.legal[currentLegalPage].Text"></p>
						</div>
						<div class="member-sector" v-else-if="props.legal[currentLegalPage].derogation">
							<h3 class="modal-header">{{ props.legal[currentLegalPage].member }}</h3>
							<h4>{{ props.legal[currentLegalPage].derogation }}</h4>
							<p class="modal-text">{{ props.legal[currentLegalPage].text }}</p>
						</div>

					</div>
				</div>

			</div>

		</TabPanel>

	</TabView>
</template>

<script setup>
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import { ref, onMounted } from 'vue';
import i18n from '@/i18n';

const { t } = i18n.global;

const props = defineProps({
	legal: Array,
	explanation: Array,
});

onMounted(() => {
	if (!props.legal || !props.explanation) {
		return;
	}
	// if the first page has already been added, then it will skip over it when it gets mounted again
	else if (props.legal[0].Member === "Advies van uw Legal Assistent") {
		return
	} else {
		getMaxPages();
		// addFirstPageToComments();
	}
});

const maxPagesLegal = ref(0);
const maxPagesExplanation = ref(0);
const currentLegalPage = ref(0);
const currentExplanationPage = ref(0);

const emit = defineEmits(['closeForm'])

function getMaxPages() {
	maxPagesLegal.value = props.legal.length;
	maxPagesExplanation.value = props.explanation.length;
}

function setCurrentLegalPage(pageNumber) {
	currentLegalPage.value = pageNumber;
}

function setCurrentExplanationPage(pageNumber) {
	currentExplanationPage.value = pageNumber;
}

</script>

<style scoped>
.full-page {
	display: flex;
	margin-left: -1.5rem;
	flex-direction: row;
}

.comment {
	text-align: start;
	padding: 1rem;
}

.comment-body {
	margin-top: 1rem;
}

p {
	color: black;
}

.nav-sidebar {
	background-color: var(--sec-dark-purple);
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	min-width: 25%;
	min-height: 50vh;
	overflow-y: scroll;
	text-align: flex-start;
}

.nav-title {
	display: flex;
	color: white;
	font-size: 14px;
	padding: 0.5rem;
	justify-content: flex-start;
	text-align: flex-start;
}

.nav-title:hover {
	cursor: pointer;
	background-color: var(--main-dark-purple);
}

.active {
	background-color: var(--main-dark-purple);
	color: white;
}

.modal-header {
	font-weight: 200;
	color: var(--sec-dark-purple);
	font-size: 14px;
}

.modal-text {
	white-space: pre-line;
}

.derogation {
	font-weight: 800;
	margin-bottom: 1rem;
}
</style>