<template>
  <div class="full-page">
    <breadcrumbs-title></breadcrumbs-title>
    <div class="wrapper" v-if="documentsAreVisible">
      <p class="wrapper-text">{{ $t('SearchView.document-list') }}:</p>
      <multi-select-documents :showTypeFour="true" @updateData="getDocumentsArray"
        @noDocumentsAvailable="noDocumentsWereFound"></multi-select-documents>
    </div>

    <div class="user-input">
      <keywords-dropdown class="dropdown" @sendChosenKeywordsID="getDropdownID"
        @chosenKeywordsChanged="getDropdownID"></keywords-dropdown>
      <search-input-text @searchActivated="getSearchInput"></search-input-text>
      <QandAInput @updateQuestion="updateQuestion" :questionIsLoading="questionIsLoading"></QandAInput>
    </div>

    <ProgressSpinner v-if="isLoading" style="width: 50px; height: 50px" strokeWidth="8" fill="var(--surface-ground)"
      animationDuration=".5s" aria-label="Custom ProgressSpinner" />
    <!-- <search-table :searchResult="searchResult" :searchInput="searchInput"></search-table> -->
    <search-input-table v-if="searchResult && isActive === 'searchInput'" :searchResult="searchResult"
      :searchInput="searchInput" :question="question" :selectedDocuments="selectedDocuments"
      :questionHasBeenCalled="questionHasBeenCalled" @riskEdited="selectTypeOfSearch"
      @updateLoading="updateLoading"></search-input-table>
    <!-- ADDED @riskEdited -->
    <keywords-table v-else :search-result="searchResult" :question="question" :selectedDocuments="selectedDocuments"
      :questionHasBeenCalled="questionHasBeenCalled" @risk-edited="updateSearch"
      @updateLoading="updateLoading"></keywords-table>
  </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import KeywordsDropdown from "@/views/search/KeywordsDropdown.vue";
import SearchInputText from '@/views/search/SearchInputText.vue';
import MultiSelectDocuments from '@/components/functional/MultiSelectDocuments.vue';
import SearchInputTable from '@/views/search/SearchInputTable.vue';
import KeywordsTable from '@/views/search/KeywordsTable.vue';
import ProgressSpinner from 'primevue/progressspinner';
import QandAInput from './search/QandAInput.vue';

import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';


const route = useRoute();
const selectedKeywordsID = ref();
const noDocumentsFound = ref(false);
const searchResult = ref();
const selectedDocuments = ref();
const searchInput = ref();
// is used when user changes documents, so function knows when to use keywords search and when to use input search
const isActive = ref('keywords');
const isLoading = ref(false);
const documentsAreVisible = ref(true);
const question = ref();
const questionIsLoading = ref(false);
// passed to tables, which will be passed to SearchQandACard, to let that component know when to call the updateQuestion function
const questionHasBeenCalled = ref(false);

function noDocumentsWereFound() {
  noDocumentsFound.value = true;
  documentsAreVisible.value = false;
}
// GET's are called in 3 different ways, whenever the keywords, or the selectedDocuments, or the searchInput changes
// the functions below change all variables accordingly, so only the correct variables have values assigned to them
// assigning correct values to variables so tables are shown correctly
function getDropdownID(keywordsID) {
  selectedKeywordsID.value = keywordsID;
  searchInput.value = null;
  isActive.value = 'keywords';
  // call function to GET when keywords changes
  selectTypeOfSearch();
};

function getDocumentsArray(selectedDocumentsArray) {
  // if I don't empty array at the start, value does not properly change and data does not get updated
  selectedDocuments.value = [];
  selectedDocuments.value = selectedDocumentsArray;
  // call function to GET when documents change
  selectTypeOfSearch();
}

function getSearchInput(search) {
  searchInput.value = search;
  selectedKeywordsID.value = null;
  isActive.value = 'searchInput';
  // call function to GET when searchInput changes
  selectTypeOfSearch();
}

// These are the endpoints that are used in the search functionality
// Keywords entire dossier: document-multi-search/?kwl=6&dossier=152
// Input entire dossier: document-search/?kw=dienen&dossier=152

// Keywords selected documents: document-multi-search/?kwl=00&document[]=63&document[]=64
// Input selected documents: document-search/?kw=dienen&document[]=63&document[]=64

// checks amount of documents selected and which value isActive, then goes to that endpoint needed for that specific search
function selectTypeOfSearch() {
  // search entire dossier
  if (selectedDocuments.value.length === 0 || !selectedDocuments.value) {
    // search uses keywords
    if (isActive.value === 'keywords') {
      // search entire dossier for keywords
      searchThroughDossier('document-multi-search/?kwl=', selectedKeywordsID.value)
    } else {
      // search entire dossier for search user input
      searchThroughDossier('document-search/?kw=', searchInput.value)
    }
  }
  // if documents were selected search through selected docs
  else {
    if (isActive.value === 'keywords') {
      // search through selected documents for keywords
      searchThroughDocuments('document-multi-search/?kwl=', selectedKeywordsID.value);
    } else {
      // search through selected documents for search user input
      searchThroughDocuments('document-search/?kw=', searchInput.value);
    }
  }
}

function searchThroughDossier(searchType, searchValue) {
  // early return if some values aren't there or undefined
  if (!searchType && !searchValue || searchType === undefined || searchValue === undefined) {
    return
  };
  isLoading.value = true;
  axiosInstance.get(searchType + searchValue + '&dossier=' + route.params.dossierId, {
    dossier: route.params.dossierId
  })
    .then((response) => {
      searchResult.value = response.data;
      isLoading.value = false;
    })
    //handle error
    .catch((error) => {
      devLog(error)
    })
    //always executed
    .then(() => {
    });
};

function searchThroughDocuments(searchType, searchValue) {
  // early return if some values aren't there or undefined

  if (!searchType && !searchValue || searchType === undefined || searchValue === undefined) {
    return
  };

  // docs array loop, adds &document[]=:id to url per document in selectedDocuments array
  let docsUrl = "&dossier=" + route.params.dossierId;
  if (selectedDocuments.value.length > 0) {
    for (const i in selectedDocuments.value) {
      docsUrl = docsUrl + '&document[]=' + selectedDocuments.value[i];
    }
  }
  isLoading.value = true;
  axiosInstance.get(searchType + searchValue + docsUrl, {
    dossier: route.params.dossierId
  })
    .then((response) => {
      searchResult.value = response.data;
      isLoading.value = false;
    })
    //handle error
    .catch((error) => {
      devLog(error)
    })
    //always executed
    .then(() => {
    });
};

// used to refresh data in search
function updateSearch(selectedKeyword) {
  if (selectedDocuments.value.length === 0 || !selectedDocuments.value) {

    // search entire dossier for search user input
    axiosInstance.get('document-search/?kw=' + selectedKeyword + '&dossier=' + route.params.dossierId, {
      dossier: route.params.dossierId
    })
      .then((response) => {
        refreshSelectedData(response.data, selectedKeyword);
      })
      //handle error
      .catch((error) => {
        devLog(error)
      })
      //always executed
      .then(() => {
      });
  }
  // if documents were selected search through selected docs
  else {
    // search through selected documents for search user input
    // docs array loop, adds &document[]=:id to url per document in selectedDocuments array
    let docsUrl = "&dossier=" + route.params.dossierId;
    if (selectedDocuments.value.length > 0) {
      for (const i in selectedDocuments.value) {
        docsUrl = docsUrl + '&document[]=' + selectedDocuments.value[i];
      }
    }
    axiosInstance.get('document-search/?kw=' + selectedKeyword + docsUrl, {
      dossier: route.params.dossierId
    })
      .then((response) => {
        refreshSelectedData(response.data, selectedKeyword);
      })
      //handle error
      .catch((error) => {
        devLog(error)
      })
      //always executed
      .then(() => {
      });
  }
}

// used to refresh data in keywords
function refreshSelectedData(search, selectedKeyword) {
  for (const index in searchResult.value.results) {
    searchResult.value.results[index][selectedKeyword] = search.results[selectedKeyword];
  }
}

function updateQuestion(newQuestion) {
  question.value = newQuestion;
  questionIsLoading.value = true;
  questionHasBeenCalled.value = true;
}

function updateLoading(isLoading) {
  questionIsLoading.value = isLoading;
  questionHasBeenCalled.value = false;
}

</script>

<style scoped>
.full-page {
  display: flex;
  flex-direction: column;
}

.user-input {
  display: flex;
  /* margin-right: 0.5rem; */
  vertical-align: middle;
  align-items: center;

}

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.wrapper-text {
  text-align: start;
  font-size: 14px;
  min-width: 7rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
</style>