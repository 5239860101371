<template>
    <the-popup @button-clicked="handlePopup()" v-if="showPopup">
        <template v-slot:title v-if="riskContext.section">{{ $t('riskDetailsTable.' + riskContext.name) }}</template>
        <template v-slot:title v-else-if="riskContext.consequence">{{ riskContext.consequence }}</template>

        <template v-slot:title>{{ $t('riskDetailsTable.' + riskContext.name) }}</template>
        <template v-slot:content v-if="riskContext.secure_url">
            <vue-pdf-embed :source="riskContext.secure_url" class="motivation-pdf"></vue-pdf-embed>
        </template>
        <template v-slot:content v-else-if="riskContext.section">{{ riskContext.section }}</template>
        <template v-slot:content v-else-if="riskContext.answer">{{ riskContext.answer }}</template>

        <template v-slot:content v-else>{{ $t('overviewActionsTable.no_risk') }}</template>
        <template v-slot:buttonText>{{ $t('overviewActionsTable.close') }}</template>
    </the-popup>

    <div v-if="actionList">
        <Dialog v-model:visible="visible" modal header="Actie bewerken" :style="{ width: '75vw' }">
            <PutActionForm :selected-action="selectedAction" :action-list="actionList" @closeForm="visible = false"
                @saveRowData="receiveDataFromChild">
            </PutActionForm>
        </Dialog>
        <DataTable :value="actionList" ref="dt" paginator :rows="10" v-model:filters="filters" editMode="row"
            :editingRows="editingRows">

            <template #header>
                <div class="flex justify-content-end" style="display: flex; justify-content: space-between; ">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" :placeholder="$t('overviewActionsTable.search')" />
                    </span>
                </div>
            </template>
            <template #empty> {{ $t('overviewActionsTable.empty') }} </template>
            <template #loading> {{ $t('overviewActionsTable.loading') }} </template>

            <Column field="risk_name" :header="$t('overviewActionsTable.risk_name')">
                <template #body="{ data }">
                    <p v-if="generatedRisks.includes(data.risk_name)"> {{ $t('riskDetailsTable.' + data.risk_name) }}
                    </p>
                    <p v-else>{{ $t('riskDetailsTable.' + data.risk_name) }}</p>
                </template>
            </Column>

            <Column field="assigned_user_email" :header="$t('overviewActionsTable.assignedUser')"></Column>

            <Column field="deadline" :header="$t('overviewActionsTable.deadline')">
                <template #body="{ data }">
                    {{ formatDate(data, "deadline") }}
                </template>
            </Column>


            <Column field="info" :header="$t('overviewActionsTable.info')">
                <template #body="{ data }">
                    <p v-if="data.info !== null">{{ $t('actionsForm.' + data.info) }}</p>
                </template>
            </Column>

            <Column field="comment" :header="$t('overviewActionsTable.comment')"></Column>

            <Column field="selection" :header="$t('overviewActionsTable.selection')"></Column>


            <Column field="completed" :header="$t('overviewActionsTable.completed')">
                <template #body="{ data }">
                    <p v-if="data.completed === true">Voltooid</p>
                    <p v-else>{{ $t('overviewActionsTable.incomplete') }}</p>
                </template>
            </Column>


            <Column field="actions" :header="$t('overviewActionsTable.actions')">
                <template #body="{ data }">
                    <span class="p-buttonset-vertical">
                        <Button icon="pi pi-eye" size="small" @click="showMoreInformation(data)" />
                        <Button icon="pi pi-pencil" class="edit" size="small" @click="showEditAction(data)" />
                        <Button icon="pi pi-trash" class="delete" size="small" @click="confirmDelete(data)" />
                    </span>
                </template>
            </Column>

        </DataTable>
    </div>
</template>

<script setup>
// primeVue imports
import ThePopup from '@/components/ui/ThePopup.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import PutActionForm from '@/components/forms/PutActionForm.vue';
import { useConfirm } from "primevue/useconfirm";
import VuePdfEmbed from 'vue-pdf-embed';

import { ref } from 'vue';
import axiosInstance from '@/services/interceptor';

import generatedRisks from '@/assets/JSON/generatedRisks.json';
import { formatDate, devLog } from '@/helpers/helpers';

//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";
//core
import "primevue/resources/primevue.min.css";
//icons
import "primeicons/primeicons.css";

const props = defineProps({
    actionList: Object,
});

const emit = defineEmits(['saveRowData', 'deleteRowData'])

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const confirm = useConfirm();


const editingRows = ref([])
const showPopup = ref(false);
const riskContext = ref('');
const visible = ref(false);

const selectedAction = ref(null);

function showMoreInformation(action) {
    if (action.risk) {
        getRisk(action);
    } else if (action.warning) {
        getWarning(action);
    }

};

function handlePopup() {
    showPopup.value = !showPopup.value;
}

function showEditAction(data) {
    selectedAction.value = data;
    visible.value = true;
}

function deleteRow(data) {
    emit('deleteRowData', data);
};

function getRisk(action) {
    // endpoint not working, fix then function will work
    axiosInstance.get('/risks/' + action.risk + '/')
        .then((response) => {
            riskContext.value = response.data;
            showPopup.value = true;
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
};

function getWarning(action) {
    // endpoint not working, fix then function will work
    axiosInstance.get('/warnings/' + action.warning + '/')
        .then((response) => {
            devLog(response.data);
            riskContext.value = response.data;
            showPopup.value = true;
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
};

function receiveDataFromChild(data) {
    emit('saveRowData', data);
}

const confirmDelete = (data) => {
    confirm.require({
        message: 'Wilt u deze actie verwijderen?',
        header: 'Confirmatie verwijderen actie',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        acceptLabel: 'Ja',
        rejectLabel: 'Nee',
        accept: () => {
            deleteRow(data);
        },
        reject: () => {
            return
        }
    });
};
</script>

<style scoped>
.square-card {
    border-radius: 0;
}

.button-field {
    padding: 1rem;
}

.tender-item:hover {
    background-color: #E7EAFF;
}

.tender-item-click:hover {
    cursor: pointer;
}

.sort {
    height: 1rem;
}

.title {
    display: flex;
    gap: 5px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.table-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.motivation-pdf {
    max-height: 250px;
    overflow: auto;
}

:deep(.p-button:not(.form-button)) {
    border-color: #1A224C;
    background-color: white;
    color: #1A224C;
    font-size: 14px;
    padding: 0.3rem;
}

:deep(.p-button:hover:not(.form-button)) {
    background-color: #bfc7ff;
    border-color: #bfc7ff;
}

:deep(.p-button.edit:hover:not(.form-button)) {
    background-color: green;
    border-color: green;
    color: white;
}

:deep(.p-button.delete:hover:not(.form-button)) {
    background-color: red;
    border-color: red;
    color: white;
}


:deep(.p-buttonset-vertical) {
    display: flex;
    flex-direction: column;
}

:deep(.p-buttonset-vertical) .p-button {
    margin: 0;
}

:deep(.p-buttonset-vertical) .p-button:not(:last-child) {
    border-bottom: 0 none;
}

:deep(.p-buttonset-vertical) .p-button:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
}

:deep(.p-buttonset-vertical) .p-button:first-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

:deep(.p-buttonset-vertical) .p-button:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

:deep(.p-buttonset-vertical) .p-button:focus {
    position: relative;
    z-index: 1;
}
</style>