import { createStore } from "vuex";

import authModule from "./modules/auth/index.js";
// import dossiersModule from "./modules/dossiers/index.js";

export default createStore({
  modules: {
    auth: authModule,
    // dossiers: dossiersModule,
  },
});
