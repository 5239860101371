<template>
  <div class="full-page" :class="{ 'loading-cursor': loading }">
    <Toast />
    <breadcrumbs-title></breadcrumbs-title>
    <v-card class="square-card">

      <overview-actions-table v-if="actionList.length > 0" :actionList="actionList" @saveRowData="saveRowData"
        @deleteRowData="deleteRowData"></overview-actions-table>
      <div v-else>
        <h1>{{ $t('OverviewActionsView.empty-title') }}</h1>
        <p>{{ $t('OverviewActionsView.empty-body') }}</p>
      </div>

    </v-card>
  </div>
</template>

<script setup>
import OverviewActionsTable from '@/views/overview-actions/OverviewActionsTable.vue';
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';
import i18n from '@/i18n';

onMounted(() => {
  getTenderActions(route.params.dossierId);
})

const toast = useToast();


const route = useRoute();

const actionList = ref([]);
const loading = ref(false);
const { t } = i18n.global;

// Gets all actions of this specific tender
function getTenderActions(dossierID) {
  axiosInstance.get('actions/?dossier=' + dossierID)
    .then((response) => {
      actionList.value = response.data;
    })
    //handle error
    .catch((error) => {
      devLog(error)
    })
    //always executed
    .then(() => {
    });
};

function saveRowData(data) {
  // finds index of edited action, and changes value of old action to newly edited action
  let action = actionList.value.find((action) => action.id === data.id);
  let index = actionList.value.indexOf(action);
  actionList.value[index] = data;
}

function deleteRowData(data) {
  loading.value = true;
  axiosInstance.delete('actions/' + data.id + '/')
    .then((response) => {
      // finds index of edited action, and changes value of old action to newly edited action
      let action = actionList.value.find((action) => action.id === data.id);
      let index = actionList.value.indexOf(action);
      actionList.value.splice(index, 1);
      toast.add({ severity: 'info', summary: t('OverviewActionsView.delete-summary'), detail: t('OverviewActionsView.delete-summary'), life: 3000 });
      loading.value = false;
    })
    //handle error
    .catch((error) => {
      devLog(error)
    })
    //always executed
    .then(() => {
    });
}

</script>

<style scoped>
.square-card {
  border-radius: 0;
}

.loading-cursor {
  cursor: wait;
}
</style>