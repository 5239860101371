<template>
    <span v-if="props.risks !== undefined || props.risk && props.risk.length !== 0" class="risk-tags">
    </span>

    <Dialog v-if="typeOfDialog === 'editRisk'" v-model:visible="dialogIsVisible" modal
        :header="$t('DetailedAnalysisView.dialog.edit-risk')" :style="{ width: '75vw' }">
        <AddRiskForm :existingRisk="riskForm.existingRisk" :paragraphID="riskForm.paragraphID"
            @risk-edited="emitChanges" :selectedText="riskForm.selectedText" @closeForm="dialogIsVisible = false" />
    </Dialog>
    <Dialog v-if="typeOfDialog === 'assistance'" v-model:visible="dialogIsVisible" modal
        :header="$t('DetailedAnalysisView.dialog.assistance')" :style="{ width: '75vw' }">
        <LawAndAssistance :legal="legal" :explanation="explanation" @closeForm="dialogIsVisible = false">
        </LawAndAssistance>

    </Dialog>

    <Dialog v-if="typeOfDialog === 'addAction'" v-model:visible="dialogIsVisible" modal
        :header="$t('DetailedAnalysisView.dialog.add-action')" :style="{ width: '75vw' }">
        <AddActionForm :selectedText="riskForm.selectedText" :riskID="riskForm.existingRisk.id"
            @closeForm="dialogIsVisible = false">
        </AddActionForm>
    </Dialog>

    <ContextMenu ref="menu" :model="contextMenuItems" style="width:fit-content;" />


    <div class="tags-field">
        <Tag v-for="(risk, index) in props.risks" :key="risk.id" class="risk-tag" value="tag"
            @click.stop="showRiskMenu($event, risk, props.id)">
            {{ $t('riskDetailsTable.' + risk.name) }}
        </Tag>
    </div>


</template>

<script setup>
import Tag from 'primevue/tag';
import AddRiskForm from '@/components/forms/AddRiskForm.vue';
import AddActionForm from '@/components/forms/AddActionForm.vue';
import LawAndAssistance from '@/components/functional/right-click-risks-functionality/LawAndAssistance.vue';
import ContextMenu from 'primevue/contextmenu';
import Dialog from 'primevue/dialog';
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

import { ref, defineEmits } from 'vue';
import i18n from '@/i18n';
import axiosInstance from '@/services/interceptor';
import { useRoute } from 'vue-router';

const props = defineProps({
    risks: Array,
    id: Number
});

const emit = defineEmits(['risk-edited']);

const route = useRoute();
const { t } = i18n.global;
const toast = useToast();
const confirm = useConfirm();


// Dialog and forms
const dialogIsVisible = ref(false);
const typeOfDialog = ref(null);
const riskForm = ref({});
const legal = ref(null);
const explanation = ref(null);

const menu = ref();
const contextMenuItems = ref([]);

const showRiskMenu = (event, risk, paragraphID) => {
    contextMenuItems.value = [];
    // label needs to show, assistance, edit risk, delete risk, and add action
    contextMenuItems.value.push(
        { label: t("DetailedAnalysisView.edit-risk"), icon: 'pi pi-fw pi-pencil', command: () => { showEditRisk(risk, paragraphID) } },
        { label: t("DetailedAnalysisView.delete-risk"), icon: 'pi pi-fw pi-trash', command: () => { deleteConfirm(risk, paragraphID) } },
        { label: t("DetailedAnalysisView.assistance"), icon: 'pi pi-fw pi-info-circle', command: () => { showAssistance(risk) } },
        { label: t("DetailedAnalysisView.add-action"), icon: 'pi pi-fw pi-plus', command: () => { showAddAction(risk) } },
    );
    menu.value.show(event);
};

function showEditRisk(risk, paragraphID) {
    riskForm.value.selectedText = risk.section;
    riskForm.value.paragraphID = paragraphID;
    riskForm.value.existingRisk = risk;
    dialogIsVisible.value = true;
    typeOfDialog.value = 'editRisk';
}

function deleteConfirm(risk, paragraphID) {
    confirm.require({
        // target: event.currentTarget,
        message: t("DetailedAnalysisView.confirm.delete-message"),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: t("DetailedAnalysisView.confirm.accept-label"),
        rejectLabel: t("DetailedAnalysisView.confirm.reject-label"),
        acceptClass: 'confirm-delete',
        accept: () => {
            deleteRisk(risk, paragraphID);

        },
        reject: () => {
            toast.add({ severity: 'error', summary: t("DetailedAnalysisView.toast.reject-summary"), detail: t("DetailedAnalysisView.toast.reject-detail"), life: 3000 });
        }
    });
}

function showAssistance(risk) {
    // get currently selected language, and if it is null, then it is set to 'nl'
    if (localStorage.getItem('language') === null) {
        localStorage.setItem('language', 'nl');
    }
    const selectedLanguage = localStorage.getItem('language').toUpperCase();
    axiosInstance.get('/advice/?dossier=' + route.params.dossierId + '&risk_name=' + risk.name + '&language=' + selectedLanguage)
        .then((response) => {
            legal.value = response.data.data.legal;
            explanation.value = response.data.data.explanation;
            dialogIsVisible.value = true;
            typeOfDialog.value = "assistance";
        })
        .catch((error) => {
            devLog(error)
        })
};

function showAddAction(risk) {
    riskForm.value.selectedText = risk.section;
    riskForm.value.existingRisk = risk;
    dialogIsVisible.value = true;
    typeOfDialog.value = 'addAction';
}

function deleteRisk(risk, paragraphID) {
    axiosInstance.delete('/risks/' + risk.id + '/')
        .then((response) => {
            emitChanges(paragraphID);
            toast.add({ severity: 'info', summary: t("DetailedAnalysisView.toast.success-summary"), detail: t("DetailedAnalysisView.toast.success-detail"), life: 5000 });
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
}

function emitChanges(paragraphID) {
    emit('riskEdited', paragraphID);
}

</script>

<style scoped>
.risk-tag:not(:first-child) {
    margin-left: 5px;
}

:deep(.p-tag) {
    background-color: var(--main-light-purple);
    color: var(--main-dark-purple);
    font-weight: 400;
}
</style>