<template>
    <Dropdown class="keywords-dropdown" :options="keywordsList" option-label="name" v-model="selectedKeywords"
        :placeholder="$t('KeywordsDropdown.select-keywords')" @update:modelValue="setSelectedKeywords">
    </Dropdown>
</template>

<script setup>
import Dropdown from 'primevue/dropdown';

import { onMounted, ref } from 'vue';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';

const setItemsForDropdown = [
    {
        id: '00',
        name: "Publican Keywords"
    }
]

onMounted(() => {
    getKeywords();
})

const emit = defineEmits(['sendChosenKeywordsID', 'chosenKeywordsChanged']);

const keywordsList = ref();
const selectedKeywords = ref({
    id: 0,
    name: "No value"
});

function getKeywords() {
    axiosInstance.get('keywords/')
        .then((response) => {
            keywordsList.value = response.data;
            addSetItemsToDropdown();
        })
        //handle error
        .catch((error) => {
            devLog(error)
        })
        //always executed
        .then(() => {
        });
}

function addSetItemsToDropdown() {
    keywordsList.value.unshift(setItemsForDropdown[0]);
}

// whenever selectedKeywords gets updated, emit new id
function setSelectedKeywords() {
    emit('chosenKeywordsChanged', selectedKeywords.value.id, "keywords");
}
</script>

<style scoped>
.keywords-dropdown {
    min-width: 20%;
    text-align: start;
    align-items: center !important;
    vertical-align: middle;
    height: 3rem;
    margin-right: 1rem;
}
</style>