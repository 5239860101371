<template>
    <div class="full-page">
        <BreadcrumbsTitle />
        <SingleSelectDocuments @updateData="getSecureURL" />

        <Card>
            <template #content>
                <vue-pdf-app v-if="currentPDF" style="height: 100vh;" :pdf="currentPDF"></vue-pdf-app>
            </template>
        </Card>

        <!-- <vue-pdf-embed v-if="currentPDF" :source="currentPDF" :key="key" page="5"></vue-pdf-embed> -->
        <!-- <embed :src="currentPDF">  -->

    </div>
</template>

<script setup>
import VuePdfApp from "vue3-pdf-app";
// import this to use default icons for buttons
// importing this will cause the tests to fail, so it is commented out
// import "vue3-pdf-app/dist/icons/main.css";
import { v4 as uuidv4 } from 'uuid';

import SingleSelectDocuments from './detailed-analysis/SingleSelectDocuments.vue';
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';

import { ref } from 'vue';
import axiosInstance from '@/services/interceptor';
import { useRoute } from 'vue-router';
import { devLog } from '@/helpers/helpers';


import Card from 'primevue/card';

const route = useRoute();

// computed property that returns an unique key for the pdf viewer
const key = uuidv4();
const currentPDF = ref(null);

function getSecureURL(documentID) {
    axiosInstance.get('documents/?dossier=' + route.params.dossierId)
        .then((response) => {
            let document = response.data.find((document) => document.id === documentID);
            currentPDF.value = document.secure_url;
        })
        .catch((error) => {
            devLog(error)
        })
}

</script>