<template>
    <div class="full-page">
        <breadcrumbs-title></breadcrumbs-title>
        <v-card class="square-card">
            <form class="form" @submit.prevent="checkForErrors(newKeywords)">

                <div class="form-field">
                    <h4 class="form-title">{{ $t('addKeywords.name') }}</h4>
                    <textarea type="text" v-model="newKeywords.name" :placeholder="$t('addKeywords.name')" @keydown="nameAlreadyExists = false" />
                    <p v-if="nameAlreadyExists && attemptedToSend" class="error" >{{ $t('addKeywords.nameExists') }}</p>
                    <p v-if="attemptedToSend && newKeywords.name === ''" class="error">{{ $t('addKeywords.emptyError') }}</p>

                </div>

                <div class="form-field">
                    <h4 class="form-title">{{ $t('addKeywords.keywords') }}</h4>
                    <Chips v-model="newKeywords.keywords" class="chips" separator="," />
                    <p v-if="newKeywords.keywords.length < 1 && attemptedToSend" class="error">{{ $t('addKeywords.emptyError') }}</p>

                </div>

                <TheButton class="form-button">
                    <template #text>{{ $t('addKeywords.submit') }}</template>
                </TheButton>
            </form>
        </v-card>
    </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import TheButton from '@/components/ui/TheButton.vue';
import Chips from 'primevue/chips';

import { ref } from 'vue';
import { useRouter } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';

const router = useRouter();

const newKeywords = ref({
    keywords: [],
    name: '',
})
const nameAlreadyExists = ref(false);
const attemptedToSend = ref(false);


// calls all error handling functions
function checkForErrors(keywords) {
    attemptedToSend.value = true;
    if (keywords.keywords.length < 1 || keywords.name == '') {
        return
    }
    // this function will also end up calling add keywords
    // due to how js works with data, once the get has been successfully received and all variables have been set to true or false
    // only then will the addKeywords be called if all errors are false 
    axiosInstance.get('/keywords/')
        .then((response) => {
            // loops through profiles and checks if name that user has put in equals name that has already been made
            for (const item in response.data) {
                if (keywords.name == response.data[item].name) {
                    nameAlreadyExists.value = true;
                    return
                } else {
                    nameAlreadyExists.value = false;
                }
            }
            // if one of the error values == true, returns, else it calls addKeywords
            if (nameAlreadyExists.value || keywords.keywords.length < 1 || keywords.name == '') {
                return
            } else {
                addKeywords(keywords);
            }
        })
        .catch(() => {
        })
        .then(() => {
        })
}

function addKeywords(keywords) {
    axiosInstance.post('keywords/', keywords)
        .then((response) => {
            router.push('/keywords')
        })
        //handle error
        .catch((error) => {
            devLog(error)
        })
        //always executed
        .then(() => {
        });
}

</script>

<style scoped>
.show-items {
    color: grey;
    font-size: small;
}


.square-card {
    border-radius: 0;
    min-width: fit-content;
}

/* FORM */
.form,
.form-empty,
.form-undefined {
    color: #1A224C;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    max-width: 35rem;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-button {
    margin-top: 1rem;
}

.form-title {
    font-weight: 450;
    color: var(--sec-dark-purple);
}

.error {
    color: red;
}

textarea {
    width: 20rem;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
    height: 3rem;
}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);

}

:deep(.p-chips-input-token) {
    width: 40rem;
}
</style>