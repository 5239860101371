<template>
    <svg v-if="props.className" id="fi_9411817" enable-background="new 0 0 4000 4000" viewBox="0 0 4000 4000"
        xmlns="http://www.w3.org/2000/svg" :class="props.className">
        <g>
            <path
                d="m3100 1890h-990v-990c0-60.747-49.253-110-110-110s-110 49.253-110 110v990h-990c-60.747 0-110 49.253-110 110s49.253 110 110 110h990v990c0 60.747 49.253 110 110 110s110-49.253 110-110v-990h990c60.747 0 110-49.253 110-110s-49.253-110-110-110z">
            </path>
        </g>
    </svg>
</template>

<script setup>
const props = defineProps({
    className: String
})
</script>