<template>
    <!-- Chips are hidden when there is less than 1 document in dossier -->
    <div class="chips-container">
        <div class="chip" v-for="document in documents" :class="getStatusClass(document.status, document.id)">
            <span class="chip-content">
                <p class="chip-content" @click="setChipActive(document.id, document.status)"
                    :disabled="document.status === 'processing' || document.status === 'error'">{{
                        document.document_name }}
                </p>
                <i v-if="document.status === 'processing'" class="pi pi-hourglass" style="font-size: 1rem"></i>
                <i v-else-if="document.status === 'error'" class="pi pi-times-circle" style="font-size: 1rem"></i>

            </span>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import axiosInstance from '@/services/interceptor';

const route = useRoute();

const activeDocument = ref()
const documents = ref([]);
const processingDocumentIDs = ref([]);
let amountOfGetDocumentLoops = 0;
const emit = defineEmits(['updateData', 'noDocumentsAvailable'])

onMounted(() => {
    getDocuments();
})

function setChipActive(documentID, status) {
    // disable click when doc is processing
    if (status === "processing" || status === "error") {
        return
    }
    // add if to check if item already in array
    // if item in array, remove it, otherwise, add it
    if (activeDocument.value === documentID) {
        // filters out item that is already selected
        activeDocument.value = null;
    } else {
        activeDocument.value = documentID;
    }
    emit('updateData', activeDocument.value);
}

function setFirstChipActive() {
    for (let index in documents.value) {
        // loops through all docs, and sets the first doc that isn't processing to be the active doc
        if (documents.value[index].status === 'done' || documents.value[index].status === null) {
            activeDocument.value = documents.value[index].id;
            return
        }
    }
}

function getDocuments() {
    axiosInstance.get('documents/?dossier=' + route.params.dossierId)
        .then(async (response) => {
            console.log('lelele')
            await filterDocuments(response.data);
            // checks amount of docs that are still being processed
            await handleProcessingDocs(response.data);

        })
        .catch((error) => {
        })
}


async function handleProcessingDocs(responseData) {
    console.log('loop')
    processingDocumentIDs.value = [];
    // checks amount of docs that are still being processed
    processingDocumentIDs.value = responseData.filter(obj => obj.status === "processing").map(obj => obj.id);
    // if there are docs still being processed, after 5 seconds get those docs
    if (processingDocumentIDs.value.length >= 1 && amountOfGetDocumentLoops < 15) {
        setTimeout(() => {
            amountOfGetDocumentLoops++;
            getProcessingDocuments();
        }, 5000);
    }

}


// every time there are still docs being processed, get those docs again
function getProcessingDocuments() {
    axiosInstance.get('documents/?dossier=' + route.params.dossierId)
        .then((response) => {
            // in response.data, filter out the docs that are still processing
            // then, check if one of those docs has an id that is in the processingDocumentIDs array
            // if it is, then replace that doc in the documents array with the new doc
            for (const index in response.data) {
                if (processingDocumentIDs.value.includes(response.data[index].id)) {
                    documents.value = documents.value.map(obj => obj.id === response.data[index].id ? response.data[index] : obj);
                    // reset loop, this means it is working
                    amountOfGetDocumentLoops = 0;
                }
            }
            // start the process again
            handleProcessingDocs(response.data);
        })
        .catch((error) => {
        })
}

async function filterDocuments(docs) {
    // if there is a route document, and the id of that document is in the docs array, and that doc has type 4, then add only that doc to the docs array
    if (route.query && route.query.document && docs.filter(obj => obj.id === parseInt(route.query.document))[0].type === 4) {
        // only add the doc with the id of the route query to the documents array
        documents.value.push(docs.filter(obj => obj.id === parseInt(route.query.document))[0]);
    } else {
        // only add type 1,2, and 3 to documents array
        for (const index in docs) {
            if (docs[index].type === 4) {
                continue
            } else {
                documents.value.push(docs[index]);
            }
        }
    }
    // after the docs query is filtered, set the initial values
    setInitialValues();
};

// sets the initial values of the component
function setInitialValues() {
    // if no documents are in the current dossier, return and dont get any risks
    if (documents.value.length === 0) {
        emit('noDocumentsAvailable', true)
        return
    }

    else if (documents.value.length === 1) {
        // if there is only one document in the dossier, and that doc is still processing, then there are no active docs
        if (documents.value[0].status === 'processing') {
            emit('noDocumentsAvailable', true)
            return
        }
    }

    // if there is a route query, and the document id of that query is in the documents array, then set that document to be the active document
    if (route.query && route.query.document) {
        setChipActive(parseInt(route.query.document));
    } else {
        // if there is no route query, then set the first document in the documents array to be the active document
        setFirstChipActive();
        emit('updateData', activeDocument.value);
    }
}

const getStatusClass = (status, id) => {
    if (activeDocument.value === id) {
        return 'active'; // Returns 'active' class if the item is active
    } else if (status === 'processing') {
        return 'processing'; // Returns 'processing' class if the item is not active and still processing
    } else if (status === 'error') {
        return 'error'; // Returns 'error' class if the item is not active and has an error 
    } else {
        return ''; // Returns empty string if none of the conditions are met
    }
};

</script>

<style scoped>
.chips-container {
    text-align: start;
    margin-bottom: 1rem;
}

.chip {
    text-align: start;
    display: inline-block;
    padding: 0 25px;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    border-radius: 25px;
    border: 1px solid white;
    background-color: white;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
}

.chip:hover {
    /* border: 1px solid var(--sec-dark-purple); */
    border: 1px solid #E7EAFF;
    background-color: #E7EAFF;
    cursor: pointer;
}

.active {
    border: 1px solid var(--sec-dark-purple);
    background-color: #E7EAFF;
}

.chip-content {
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    color: var(--sec-dark-purple);
}

.chip-svg {
    color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 14px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
}

.chip-svg:hover {
    color: #666666;
}

.error {
    border: 1px solid red;
    background-color: lightpink;
    color: darkred;
}

.error:hover {
    cursor: default;
    border: 1px solid red;
    background-color: lightpink;
}

.error .chip-content {
    color: darkred;
}

.processing {
    border: 1px solid green;
    background-color: greenyellow;
    color: green;
}

.processing:hover {
    cursor: default;
    border: 1px solid green;
    background-color: greenyellow;
}

.processing .chip-content {
    color: green;
}
</style>