<template>
    <Card class="card">
        <template #title>{{ $t('SearchQandACard.title') }}</template>
        <template #content v-if="isLoading">
            <p>{{ $t('SearchQandACard.loading-message') }}</p>
        </template>
        <template #content v-else-if="advice">
            <p v-html="advice"></p>
        </template>
        <template #content v-else>
            <p class="m-0">{{ $t('SearchQandACard.default-message') }}</p>
        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';

import { watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';

const props = defineProps({
    selectedDocuments: Array,
    question: String,
    questionHasBeenCalled: Boolean
});

const route = useRoute();

const emit = defineEmits(['updateLoading']);

const advice = ref();
const isLoading = ref(false);

// watch for changes question, and when both props have a value and it isn't loading, call getAnswer
watch(() => props.questionHasBeenCalled, (questionHasBeenCalled) => {
    // if the question has been called, and there are selected documents, and it isn't loading, call getAnswer
    if (props.questionHasBeenCalled && props.selectedDocuments.length >= 1 && !isLoading.value) {
        getAnswer(props.selectedDocuments, props.question);
    } else {
        // advice.value = '';
        emit('updateLoading', false);
    }
});

// // when question changes, clear advice
// watch(() => props.question, () => {
//     advice.value = '';
// });

function getAnswer(docs, question) {
    // in the question string, replace every space with a - to make it url friendly
    let urlQuestion = question.replace(/ /g, '-');
    // let allDocsIds needs to become a string, so it can be used in the url
    let allDocIds = docs.join('&document='); // join all the selected documents with a &document= in between
    // devLog(allDocIds);
    // devLog(`/qanda/?dossier=${route.params.dossierId}&document=${allDocIds}&question=${urlQuestion}`)
    // clear the current advice
    advice.value = '';
    axiosInstance.get(`/qanda/?dossier=${route.params.dossierId}&document=${docs}&question=${urlQuestion}`)
        .then(response => {
            advice.value = response.data.results;
            emit('updateLoading', false);
        })
        .catch(error => {
            emit('updateLoading', false);
            devLog(error);
        });
}

</script>

<style scoped>
.card {
    margin-bottom: 1rem;
}

:deep(.p-card-title) {
    font-size: 24px;
    font-weight: 100;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--main-dark-purple);
    text-align: start;
    margin-top: -0.25rem;
    margin-left: 1rem;
}


:deep(.p-card-content) {
    text-align: start;
    padding: 1rem;
}
</style>