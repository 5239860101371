<template>
    <button class="ui-the-button">
        <slot name="icon" class="ui-the-button-icon"></slot>
        <template v-if="loading">
            <i class="pi pi-spin pi-spinner"></i> <!-- Loading icon -->
        </template>
        <template v-else>
            <slot name="text" class="text"></slot>
        </template>
    </button>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    loading: {
        type: Boolean,
        default: false
    }
});

const loading = ref(props.loading);

watch(() => props.loading, (newValue) => {
    loading.value = newValue;
});

</script>


<style>
.ui-the-button {
    background-color: #3F4A86;
    border: none;
    color: white;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
}

.ui-the-button:hover {
    background-color: #1A224C;
}


.ui-the-button {
    font-weight: 400;
    border-radius: 5px;
}

.ui-the-button-icon {
    height: 25px;
    fill: white;
    margin-right: 0.5rem;
}
</style>