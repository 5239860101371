<template>
    <aside :class="`${isExpanded ? 'is-expanded' : ''}`">
        <div class="menu-toggle-wrap">
            <button class="menu-toggle" @click="toggleMenu">
                <DoubleArrowsIcon :className="'material-icons'"></DoubleArrowsIcon>
            </button>
        </div>
        <div class="menu">

            <router-link id="dossiers" class="button" to="/dossiers">
                <DossiersListIcon :className="'material-icons'"></DossiersListIcon>
                <span class="text">{{ $t('theSidebar.dossiers') }}</span>
            </router-link>

            <router-link id="users" class="button" to="/users">
                <UsersIcon :className="'material-icons'"></UsersIcon>
                <span class="text">{{ $t('theSidebar.users') }}</span>
            </router-link>

            <router-link id="selections" class="button" to="/selections">
                <SelectionsIcon :className="'material-icons'"></SelectionsIcon>
                <span class="text">{{ $t('theSidebar.selections') }}</span>
            </router-link>

            <router-link id="keywords" class="button" to="/keywords">
                <KeywordsIcon :className="'material-icons'"></KeywordsIcon>
                <span class="text">{{ $t('theSidebar.keywords') }}</span>
            </router-link>


            <router-link id="dashboard" class="button" :to="'/dashboard/' + dossierID" v-if="currentPath.includes('dashboard/')">
                <DashboardIcon :className="'material-icons'"></DashboardIcon>
                <span class="text">{{ $t('theSidebar.dashboard') }}</span>
            </router-link>

            <!-- <router-link class="button" to="/project-management">
                <svg xmlns="http://www.w3.org/2000/svg" class="material-icons"
                    viewBox="0 0 576 512">
                    <path
                        d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 240a24 24 0 1 0 0-48 24 24 0 1 0 0 48zM368 321.6V328c0 8.8 7.2 16 16 16s16-7.2 16-16v-6.4c0-5.3 4.3-9.6 9.6-9.6h40.5c7.7 0 13.9 6.2 13.9 13.9c0 5.2-2.9 9.9-7.4 12.3l-32 16.8c-5.3 2.8-8.6 8.2-8.6 14.2V384c0 8.8 7.2 16 16 16s16-7.2 16-16v-5.1l23.5-12.3c15.1-7.9 24.5-23.6 24.5-40.6c0-25.4-20.6-45.9-45.9-45.9H409.6c-23 0-41.6 18.6-41.6 41.6z" />
                </svg>
                <span class="text">{{ $t('theSidebar.project') }}</span>
            </router-link> -->

        </div>
    </aside>
</template>

<script setup>
// icons
import DashboardIcon from '@/assets/icons/DashboardIcon.vue';
import SelectionsIcon from '@/assets/icons/SelectionsIcon.vue';
import UsersIcon from '@/assets/icons/UsersIcon.vue';
import DossiersListIcon from '@/assets/icons/DossiersListIcon.vue';
import DoubleArrowsIcon from '@/assets/icons/DoubleArrowsIcon.vue';
import KeywordsIcon from '@/assets/icons/KeywordsIcon.vue';

import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

watch(route, (oldValue, newValue) => {
    getCurrentPath();
})

const currentPath = ref(route.path);
const isExpanded = ref(localStorage.getItem("isExpanded") === "true");
const dossierID = ref();

function toggleMenu() {
    isExpanded.value = !isExpanded.value;
    localStorage.setItem("isExpanded", isExpanded.value)
}

function getCurrentPath() {
    currentPath.value = route.path;
    dossierID.value = route.params.dossierId;
}

</script>

<style scoped>
#dashboard{
    margin-top: 5rem;
}
aside {
    display: flex;
    flex-direction: column;
    min-width: calc(2rem + 32px);
    flex-basis: calc(2rem + 32px);
    overflow: hidden;
    /* header = 6.5vh, so 100 - 6.5 = 93.5vh */
    min-height: 93.5vh;

    background-color: white;
    color: white;
    border-right: 2px solid #E7EAFF;

    transition: 0.2s ease-out;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.5rem;
    /* box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

aside.is-expanded {
    flex-basis: var(--sidebar-width);
    flex-grow: 0;
    flex-shrink: 0;
}

.menu-toggle-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3rem;
    padding: 1rem;


    position: relative;
    top: 0;
    transition: 0.2s ease-out;
}

.menu-toggle {
    transition: 0.2s ease-out;
}

.is-expanded .menu-toggle-wrap {
    top: -0.5rem;
    justify-content: flex-end;
    transition: 0.2s ease-out;

}

.is-expanded .menu-toggle {
    transform: rotate(-180deg);
    transition: 0.2s ease-out;

}

.menu-toggle .material-icons:hover {
    fill: var(--main-dark-purple);
    transform: translateX(00.5rem);
}

.menu-toggle .material-icons {
    width: 25px;
    fill: var(--sec-light-purple);
    transition: 0.2s ease-out;
}

.button .text {
    opacity: 0;
    transition: 0.2s ease-out;
    font-size: 14px;
    white-space: nowrap;
}


.is-expanded .button .text {
    opacity: 1;
}

/* used to center menu in sidebar */
.menu {
    margin-top: 23vh;
}

.menu .button {
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 1rem 1rem;
    transition: 0.2s ease-out;
}

.menu .button .material-icons {
    fill: var(--sec-light-purple);
    transition: 0.2s ease-out;
    width: 25px;
    min-width: 25px;
    height: 25px;
    align-items: center;
    margin-left: 0.15rem;
}

.text {
    color: var(--main-dark-purple);
    transition: 0.2s ease-out;
    text-align: left;
}

.button:hover {
    background-color: #E7EAFF;
}

.router-link-active {
    box-shadow: inset 5px 0 0px 0px var(--sec-light-purple);
    background-color: #E7EAFF;
}

.is-expanded .button .material-icons {
    margin-right: 1rem;
}

.router-link-active .material-icons {
    fill: var(--main-dark-purple);
}
</style>