<template>
    <div v-if="allTitles" class="title-field">
        <h2 class="breadcrumb-title">
            <span v-if="allTitles.length === 1">{{ $t('router.' + allTitles[0]) }}</span>
            <!-- If there is a parameter in the route, add it to the url -->
            <router-link v-else-if="route.params.dossierId" :to="'/' + allTitles[0] + '/' + route.params.dossierId" class="title-link">{{
                $t('router.' + allTitles[0]) }}</router-link>
            <router-link v-else :to="'/' + allTitles[0]" class="title-link">{{ $t('router.' + allTitles[0]) }}</router-link>
            <span v-for="n in allTitles.length - 1">
                <span v-if="allTitles.length - 1 === n"> / {{ $t('router.' + allTitles[n]) }}</span>
                <router-link v-else :to="allTitles[n]" class="sub-title-link"> / {{ $t('router.' + allTitles[n]) }}
                </router-link>
            </span>
        </h2>
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';

onMounted(() => {
    getAllPathsForBreadcrumb();
})

const route = useRoute();
const allTitles = ref(null);

function getAllPathsForBreadcrumb() {
    // splits string into array
    allTitles.value = route.path.split('/');
    // remove first item from array, which will always be an empty string because path starts with '/' that gets split
    allTitles.value.shift();
    // removes all params from the array
    for (const property in route.params) {
        allTitles.value = allTitles.value.filter(a => a !== route.params[property])
    }
}

</script>

<style scoped>
.title-field {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem;
    margin-bottom: 1.5rem;
    padding: 0;
}

.breadcrumb-title {
    color: var(--main-dark-purple);
    text-align: start;
    font-weight: 375;
}

.sub-title-link,
.title-link {
    text-decoration: underline;
    margin: 0;
    padding: 0;
    color: var(--sec-dark-purple);
}

.sub-title-link {
    margin: right 0.5rem;
}
</style>