import i18n from "@/i18n";
import riskDetailsChecklistWithSubtitles from "@/assets/JSON/riskDetailsChecklistWithSubtitles";

const { t } = i18n.global;

export function formatDate(formData, dateProperty) {
  // formData = form object that gets passed, dateProperty = the name of the property that includes a date
  if (formData[dateProperty] === null) {
    return null;
  } else if (new Date(formData[dateProperty]) === new Date()) {
    // if somehow default date of 01-01-1970 gets passed, return null
    return null;
  } else {
    let yourDate = new Date(formData[dateProperty]);
    const offset = yourDate.getTimezoneOffset();
    const newDateObject = new Date(yourDate.getTime() - offset * 60 * 1000);
    formData[dateProperty] = newDateObject.toISOString().split("T")[0];
    return formData[dateProperty];
  }
}

// allusers = Array of all users that you should get from component where you call this function, and pass it in
export function getUserEmailFromID(userID, allUsers) {
  // if no user assigned, early return
  if (!userID) {
    return;
  } else {
    // finds the user with the corresponding id
    const result = allUsers.find((user) => user.id === userID);
    if (!result) {
      //TODO: add translation
      // if the id doesn't have a match
      return t("helpers.noUser");
    } else {
      // if it has a match, return the right email
      return result.email;
    }
  }
}

export function formatChecklist(jsonData) {
  const mappedPrecontracual = new Map();
  const mappedContractual = new Map();
  for (const property in riskDetailsChecklistWithSubtitles) {
    for (const title in riskDetailsChecklistWithSubtitles[property]) {
      const intersection = riskDetailsChecklistWithSubtitles[property][
        title
      ].filter((element) => jsonData[property].includes(element));
      if (property === "precontractual") {
        mappedPrecontracual.set(title, intersection);
      } else {
        mappedContractual.set(title, intersection);
      }
    }
  }
  // this clones object without reference
  // if you do newJsonData = jsonData, then the 2 objects are connected, and bugs can be caused in the code
  let newJsonData = { ...jsonData };
  newJsonData.precontractual = mappedPrecontracual;
  newJsonData.contractual = mappedContractual;
  return newJsonData;
}

// custom console log function, only logs in development mode so we don't have to remove all console logs before deploying
export function devLog(...args) {
  if (import.meta.env.MODE === "development") {
    console.log(...args);
  }
}
