<template>
    <svg v-if="props.className" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="fi_2722998"
        :class="$props.className">
        <g id="_19" data-name="19">
            <path
                d="m12 19a1 1 0 0 1 -.71-1.71l5.3-5.29-5.3-5.29a1 1 0 0 1 1.41-1.41l6 6a1 1 0 0 1 0 1.41l-6 6a1 1 0 0 1 -.7.29z">
            </path>
            <path
                d="m6 19a1 1 0 0 1 -.71-1.71l5.3-5.29-5.3-5.29a1 1 0 0 1 1.42-1.42l6 6a1 1 0 0 1 0 1.41l-6 6a1 1 0 0 1 -.71.3z">
            </path>
        </g>
    </svg>
</template>

<script setup>
const props = defineProps({
    className: String
})
</script>