<template>
    <svg v-if="props.className" id="fi_7367911" enable-background="new 0 0 512 512" viewBox="0 0 512 512" :class="props.className"
        xmlns="http://www.w3.org/2000/svg">
        <g id="List">
            <g>
                <path
                    d="m452 476h-392c-33.084 0-60-26.916-60-60v-320c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v320c0 33.084-26.917 60-60 60zm-392-400c-11.028 0-20 8.972-20 20v320c0 11.028 8.972 20 20 20h392c11.028 0 20-8.972 20-20v-320c0-11.028-8.972-20-20-20z">
                </path>
            </g>
            <g>
                <path
                    d="m412 196h-232c-11.046 0-20-8.954-20-20s8.954-20 20-20h232c11.046 0 20 8.954 20 20s-8.955 20-20 20z">
                </path>
            </g>
            <g>
                <circle cx="100" cy="176" r="20"></circle>
            </g>
            <g>
                <path
                    d="m412 276h-232c-11.046 0-20-8.954-20-20s8.954-20 20-20h232c11.046 0 20 8.954 20 20s-8.955 20-20 20z">
                </path>
            </g>
            <g>
                <circle cx="100" cy="256" r="20"></circle>
            </g>
            <g>
                <path
                    d="m412 356h-232c-11.046 0-20-8.954-20-20s8.954-20 20-20h232c11.046 0 20 8.954 20 20s-8.955 20-20 20z">
                </path>
            </g>
            <g>
                <circle cx="100" cy="336" r="20"></circle>
            </g>
        </g>
    </svg>
</template>

<script setup>
const props = defineProps({
    className: String
})
</script>