<template>
    <div class="page-wrapper" v-if="isLoading">
        <v-card class="square-card">
            <p>{{ $t('MySelectionsView.loading') }}</p>
        </v-card>
    </div>
    <div class="page-wrapper" v-else-if="isLoading == false && mySelections.length > 0">
        <div class="page-header">
            <breadcrumbs-title></breadcrumbs-title>
            <the-button @click="goToAddNew" class="button">
                <template #icon>
                    <PlusIcon :className="'ui-the-button-icon'"></PlusIcon>
                </template>
                <template #text>
                    {{ $t('MySelectionsView.add-new-selection') }}
                </template>
            </the-button>
        </div>

        <v-card class="square-card">

            <DataTable :value="mySelections" tableStyle="min-width: 50rem" v-model:editingRows="editingRows"
                editMode="row" @row-edit-save="checkForErrors" tableClass="editable-cells-table"
                table-style="font-size: 14px">
                <Column field="precontractual" :header="$t('MySelectionsView.precontractual')">
                    <template #editor="{ data, field }">
                        <div v-if="data.precontractual.length > 0" class="show-edit-items">

                            <span v-for="item in data.precontractual">
                                <!-- Adds a ',' after the first item -->
                                <span v-if="data.precontractual.indexOf(item) === 0"></span>
                                <span v-else>, </span>

                                {{ $t('riskDetailsTable.' + item) }}</span>
                        </div>
                        <MultiSelect v-model="data[field]" :options="precontractualList"
                            :placeholder="$t('MySelectionsView.select-precontractual')" :maxSelectedLabels="0"
                            class="w-full md:w-20rem">
                            <template #option="slotProps">
                                <p>{{ $t('riskDetailsTable.' + slotProps.option) }}</p>
                            </template>
                        </MultiSelect>
                    </template>
                    <template #body="slotProps">
                        <div v-if="slotProps.data.precontractual.length > 0" class="show-items">

                            <span v-for="item in slotProps.data.precontractual">
                                <!-- Adds a ',' after the first item -->
                                <span v-if="slotProps.data.precontractual.indexOf(item) === 0"></span>
                                <span v-else>,</span>

                                {{ $t('riskDetailsTable.' + item) }}</span>
                        </div>
                    </template>
                </Column>

                <Column field="contractual" :header="$t('MySelectionsView.contractual')">
                    <template #editor="{ data, field }">
                        <div v-if="data.contractual.length > 0" class="show-edit-items">

                            <span v-for="item in data.contractual">
                                <!-- Adds a ',' after the first item -->
                                <span v-if="data.contractual.indexOf(item) === 0"></span>
                                <span v-else>, </span>

                                {{ $t('riskDetailsTable.' + item) }}</span>
                        </div>
                        <MultiSelect v-model="data[field]" :options="contractualList"
                            :placeholder="$t('MySelectionsView.select-contractual')" :maxSelectedLabels="0"
                            class="w-full md:w-20rem">
                            <template #option="slotProps">
                                <p>{{ $t('riskDetailsTable.' + slotProps.option) }}</p>
                            </template>
                        </MultiSelect>
                    </template>
                    <template #body="slotProps">
                        <div v-if="slotProps.data.contractual.length > 0" class="show-items">

                            <span v-for="item in slotProps.data.contractual">
                                <!-- Adds a ',' after the first item -->
                                <span v-if="slotProps.data.contractual.indexOf(item) === 0"></span>
                                <span v-else>, </span>

                                {{ $t('riskDetailsTable.' + item) }}</span>
                        </div>
                    </template>
                </Column>

                <Column field="profile" :header="$t('MySelectionsView.profile')">
                    <template #editor="{ data, field }">
                        <Dropdown v-model="data[field]" :options="profiles" optionLabel="name" optionValue="id">
                        </Dropdown>
                    </template>
                    <template #body="slotProps">
                        <p>{{ $t('MySelectionsView.' + slotProps.data.profile) }}</p>
                    </template>
                </Column>

                <Column field="name" :header="$t('MySelectionsView.name')">
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]">{{ data.name }}</InputText>
                        <p v-if="nameAlreadyExists" class="error-text">{{ $t('MySelectionsView.name-exists') }}</p>
                    </template>
                    <template #body="slotProps">
                        <p>{{ slotProps.data.name }}</p>
                    </template>
                </Column>

                <Column :rowEditor="true" style="width: 10%; min-width: 4rem" bodyStyle="text-align:center"></Column>
                <Column style="width: 10%; min-width: 4rem" bodyStyle="text-align:center">
                    <template #body="slotProps">
                        <Toast />
                        <!-- <ConfirmPopup></ConfirmPopup> -->
                        <the-button @click="deleteConfirm($event, slotProps.data)">
                            <template #icon>
                                <DeleteIcon :className="'ui-the-button-icon'" />
                            </template>
                            <template #text>
                                {{ $t('MySelectionsView.delete') }}
                            </template>
                        </the-button>
                    </template>
                </Column>
            </DataTable>

        </v-card>
    </div>
    <div class="page-wrapper" v-else>
        <AddSelectionView></AddSelectionView>
    </div>
</template>

<script setup>
// primevue imports
import DeleteIcon from "@/assets/icons/DeleteIcon.vue";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import Toast from 'primevue/toast';
import ConfirmPopup from 'primevue/confirmpopup';
import InputText from 'primevue/inputtext';
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import TheButton from '@/components/ui/TheButton.vue';
import PlusIcon from '@/assets/icons/PlusIcon.vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from 'primevue/usetoast';
import AddSelectionView from './AddSelectionView.vue';

import { onMounted, ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';

import axiosInstance from '@/services/interceptor';
import riskDetails from "../assets/JSON/riskDetailsChecklist.json"
import profilesJSON from "../assets/JSON/profiles.json"


const isLoading = ref(true)
const mySelections = ref('')
const editingRows = ref([]);
const editedProfile = ref('');
const mySelectionsDataTable = ref();
const currentUserProfiles = ref([]);
// used in error handling to check if the current name of selection already exists
const nameAlreadyExists = ref(false);

const confirm = useConfirm();
const toast = useToast();

const contractualList = riskDetails.contractual;
const precontractualList = riskDetails.precontractual;
const profiles = profilesJSON.profiles

const { t } = useI18n({ useScope: 'global' })

onMounted(() => {
    getProfiles();
})

function getProfiles() {
    axiosInstance.get('/profiles/')
        .then((response) => {
            mySelections.value = response.data;
            addUserProfiles(response.data);
        })
        .catch(() => {

        })
        .then(() => {
            isLoading.value = false
        })
}

function goToAddNew() {
    // add go to url
    router.push('selections/add-selection');
}

function onRowEditSave(newData, index) {

    // need to do a put request of edited data to update db
    editedProfile.value = newData;

    // error handling added here
    axiosInstance.put('/profiles/' + editedProfile.value.id + '/', editedProfile.value)
        .then((response) => {
            updateStoredSelectionOnEdit(newData);
            // need to save edited data to local mySelections variable
            mySelections.value[index] = newData;
            // resetting currently selected user profiles
            currentUserProfiles.value = [];
            // getting the profiles again from the db, updating the currentUserProfiles
            getProfiles();
            editingRows.value = null;
        })
        .catch(() => {

        })
        .then(() => {
            isLoading.value = false
        })
};

function checkForErrors(event) {

    let { newData, index, data } = event;

    // if all fields are same and user clicks back on edit, leaves editing
    if (data.precontractual === newData.precontractual &&
        data.contractual === newData.contractual &&
        data.name === newData.name &&
        data.profile === newData.profile) {
        return;
    }

    // checks to see if user selected selection name already exists
    for (const item in mySelections.value) {
        if (newData.name == mySelections.value[item].name && newData.id !== mySelections.value[item].id) {
            nameAlreadyExists.value = true;
            // makes it so user stays in editing mode of dataTable, something built in PrimeVue DataTable component
            editingRows.value = [...editingRows.value, event.data];
            return
        }
    }
    // calls function if no errors occur
    onRowEditSave(newData, index);
}

function deleteConfirm(event, row) {
    confirm.require({
        target: event.currentTarget,
        message: t('MySelectionsView.delete-ask'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: t('MySelectionsView.accept'),
        rejectLabel: t('MySelectionsView.reject'),
        acceptClass: 'confirm-delete',
        accept: () => {
            toast.add({ severity: 'info', summary: t('MySelectionsView.success'), detail: t('MySelectionsView.delete-confirm'), life: 3000 });
            confirmDeleteSelected(row);

        },
        reject: () => {
            toast.add({ severity: 'error', summary: t('MySelectionsView.cancel'), detail: t('MySelectionsView.delete-cancel'), life: 3000 });
        }
    });
};

function confirmDeleteSelected(row) {
    axiosInstance.delete('profiles/' + row.id + '/')
        .then((response) => {
            updateStoredSelectionOnDelete(row);
            // delete selected locally
            mySelections.value = mySelections.value.filter(obj => obj.id !== row.id);
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
}

function updateStoredSelectionOnEdit(updatedSelection) {
    const storedProfile = JSON.parse(localStorage.getItem('selectedSelectionProfile'));
    if (!storedProfile || storedProfile.id === updatedSelection.id) {
        localStorage.setItem('selectedSelectionProfile', JSON.stringify(updatedSelection));
    }
}

function updateStoredSelectionOnDelete(deletedSelection) {
    const storedProfile = JSON.parse(localStorage.getItem('selectedSelectionProfile'));
    if (storedProfile && storedProfile.id === deletedSelection.id) {
        localStorage.removeItem('selectedSelectionProfile');
    }
}


// export CSV functions
function customExportCSV() {
    setExportTableCSV();
};

function setExportTableCSV() {
    mySelectionsDataTable.value = mySelections.value;
    // ordered objects will be pushed here
    const orderedSelections = [];

    // all actions that will be exported into the csv table will be edited to follow this template
    let selectionsTemplate = {
        "contractual": null,
        "precontractual": null,
        "profile": null
    }

    for (const index in mySelections.value) {

        // delete props that aren't needed in excel sheet
        delete mySelections.value[index].id

        let contractualString = ''
        let preContractualString = ''

        for (const item in mySelections.value[index].contractual) {
            contractualString += t('riskDetailsTable.' + mySelections.value[index].contractual[item]);

            // adds '/' when array has more than 1 items, and isn't the last item in the array
            if (item < mySelections.value[index].contractual.length - 1) {
                contractualString += ' / '
            }
        }

        for (const item in mySelections.value[index].precontractual) {
            preContractualString += t('riskDetailsTable.' + mySelections.value[index].precontractual[item])

            if (item < mySelections.value[index].precontractual.length - 1) {
                preContractualString += ' / '
            }
        }

        selectionsTemplate.contractual = contractualString;
        selectionsTemplate.precontractual = preContractualString;
        selectionsTemplate.profile = t('MySelectionsView.' + mySelectionsDataTable.value[index].profile)

        orderedSelections.push(selectionsTemplate);
        // resets the values, if this piece of code is removed, the function will push the same object into the array
        selectionsTemplate = {
            "contractual": null,
            "precontractual": null,
            "profile": null
        }
        contractualString = ''
        preContractualString = ''
    }

    // gets headers and translates them
    const headers = Object.keys(selectionsTemplate);
    let translatedHeaders = []
    for (const index in headers) {
        translatedHeaders.push(t('riskDetailsView.' + headers[index]))
    }
    // creating the right format to create csv file
    const main = orderedSelections.map((item) => {
        return Object.values(item).toString();
    })

    const csv = [translatedHeaders, ...main].join('\n');

    startCSVDownload(csv)
};

function startCSVDownload(csvText) {

    const blob = new Blob([csvText], { type: 'application/csv' })

    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    // name of downloaded file
    a.download = 'my_selections.csv';
    a.href = url;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);

};

</script>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1.5rem;
}

.square-card {
    border-radius: 0;
}

.show-edit-items {
    color: grey;
    font-size: x-small;
}

.error-text {
    color: red;
}

:deep(.p-button) {
    background-color: #1A224C;
    border-color: white;
    color: #ffffff;
}

:deep(.p-button:hover) {
    background-color: #bfc7ff;
    border-color: #bfc7ff;
}

::v-deep(.editable-cells-table td.p-cell-editing) {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}
</style>