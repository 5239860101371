<template>
  <v-btn size="large" class="button">
    <slot>Button</slot>
  </v-btn>
</template>

<style scoped>
.button {
  background-color: #1A224C;
  border: none;
  color: white;
}

.button.inactive,
.button:disabled {
  background-color: #969DC5;
  color: white;

}

.button:hover {
  background-color: #3F4A86;
}

.button:disabled:hover {
  background-color: #969DC5;
}
</style>