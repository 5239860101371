<template>
  <div class="card flex justify-content-center">
    <Button icon="pi pi-user" text rounded aria-label="User" @click="toggle" aria-haspopup="true"
      aria-controls="overlay_menu" />
    <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
  </div>
</template>


<script setup>
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import router from '@/router';
import store from '@/store';
import { ref } from 'vue';

import i18n from '@/i18n';
const { t } = i18n.global;

const menu = ref();

const toggle = (event) => {
  menu.value.toggle(event);
};

const items = ref([
  {
    label: t('AccountButton.my-account'),
    // icon: 'pi pi-refresh',
    command: () => {
      goToMyAccount();
    }
  },
  {
    label:  t('AccountButton.logout'),
    // icon: 'pi pi-times',
    command: () => {
      logout();
    }
  }
]
);

function logout() {
  store.dispatch('logout');
  router.replace('/auth');
}

function goToMyAccount() {
  router.replace('/my-account');
}

</script>

<style scoped>
:deep(.p-button-icon) {
  color: white;
}
</style>