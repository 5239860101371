<template>
    <div v-if="props.dataObject">
        <DataTable v-if="props.dataObject" :value="props.dataObject" paginator stripedRows :rows="10"
            :class="'p-datatable-sm'" v-model:filters="filters" table-style="font-size: 14px" :loading="props.isLoading"
            tableClass="editable-cells-table" editMode="cell" @cell-edit-complete="onCellEditComplete($event)">

            <template #header>
                <div class="header">
                    <div class="searchbar">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" :placeholder="$t('DossierListTable.search')" />
                        </span>
                    </div>

                    <div class="left-field">
                        <!-- <p class="selection">{{ $t('DossierListTable.filter') }}: </p>
                        <div class="dropdown-field">
                            <Dropdown v-model="selectedFilter" :options="tenderFilterList" option-label="name">
                            </Dropdown>
                        </div> -->

                        <!-- <p class="selection">Samenvatting: </p>
                        <div class="dropdown-field" v-if="props.selectionProfiles">
                            <Dropdown v-model="selectedSelectionProfile" :options="props.selectionProfiles" option-label="name"
                                :disabled="props.selectionProfiles.length < 1"></Dropdown>
                        </div> -->

                        <!-- <div class="button-field">
                            <the-button @click="customExportCSV($event)">
                                <template #icon>
                                    <ExportIcon :className="'ui-the-button-icon'"></ExportIcon>
                                </template>
                                <template #text>{{ $t('DossierListTable.export') }}</template>
                            </the-button>
                        </div> -->
                    </div>
                </div>
            </template>
            <template #loading>
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </template>
            <template #empty> {{ $t('DossierListTable.empty') }} </template>

            <Column field="name" :header="$t('DossierListTable.name')" sortable :editable="true">
                <template #editor="{ data, field }">
                    <input v-model="data[field]" />
                </template>
            </Column>

            <Column field="author" :header="$t('DossierListTable.author')" sortable>
                <template #body="{ data, }">
                    {{ data.author }}
                </template>
            </Column>

            <Column field="assigned_user" :header="$t('DossierListTable.assignedUser')" sortable :editable="true">
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="users" option-label="email">
                    </Dropdown>
                </template>
                <template #body="{ data, }">
                    {{ getUserEmailFromID(data.assigned_user, users) }}
                </template>
            </Column>

            <Column field="creation_date" :header="$t('DossierListTable.creation_date')" sortable>
                <template #body="{ data }">
                    {{ formatDate(data, "creation_date") }}
                </template>
            </Column>
            <Column field="contractor" :header="$t('DossierListTable.contractor')" sortable :editable="true">
                <template #editor="{ data, field }">
                    <input v-model="data[field]" />
                </template>
            </Column>
            <Column field="submission_date" :header="$t('DossierListTable.submission_date')" sortable :editable="true">
                <template #editor="{ data, field }">
                    <input v-model="data[field]" />
                </template>
                <!-- <template #body="{ data }">
                    {{ formatDate(data, "submission_date") }}
                </template> -->
            </Column>
            <Column field="status" :header="$t('DossierListTable.status')" sortable :editable="true">
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="tenderFilters.filters" optionLabel="name"
                        optionValue="id">
                    </Dropdown>
                </template>
                <template #body="slotProps">
                    <p>{{ $t('DossierListTable.' + slotProps.data.status) }}</p>
                </template>
            </Column>

            <Column field="actions, item" :header="$t('DossierListTable.actions')" style="min-width: 7rem">
                <template #body="{ data }">
                    <span class="p-buttonset">
                        <Toast />
                        <ConfirmPopup></ConfirmPopup>
                        <div class="actions">
                            <div class="buttons-field">
                                <Button icon="pi pi-home" size="small" @click="goToURL('dashboard/', data)"
                                    :disabled="data.secure_url === null"
                                    :title="$t('DossierListTable.go-to-dashboard')" />

                                <Button icon="pi pi-trash" class="delete-button" size="small"
                                    @click="deleteConfirm($event, data)"
                                    :loading="deleteIsLoading && selectedTenderID == data.id"
                                    loading-icon="pi pi-spinner pi-spin"
                                    :title="$t('DossierListTable.delete-dossier')" />
                            </div>
                        </div>
                    </span>
                </template>
            </Column>

        </DataTable>

    </div>
    <div v-else>
        <p>{{ $t('DossierListTable.not_found') }}</p>
    </div>
</template>

<script setup>
// primeVue imports
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import ConfirmPopup from 'primevue/confirmpopup'
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import TheButton from '@/components/ui/TheButton.vue';
import ExportIcon from '@/assets/icons/ExportIcon.vue';

// vue imports
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { computed } from '@vue/reactivity';
import { ref, watch, onMounted } from 'vue';

import axiosInstance from '@/services/interceptor';
import tenderFilters from "@/assets/JSON/tenderFilters.json";
import { getUserEmailFromID, formatDate, devLog } from '@/helpers/helpers';

//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";
//core
import "primevue/resources/primevue.min.css";
//icons
import "primeicons/primeicons.css";

const props = defineProps({
    dataObject: Object,
    isLoading: Boolean,
    tableHeaders: Array,
    dataInTable: String,
    selectionProfiles: Array,
})

const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const { t } = useI18n({ useScope: 'global' })

const emit = defineEmits(['getFilteredTenders', 'deleteDossier', 'updateTender'])

const tenderList = ref(props.dataObject);
const confirm = useConfirm();
const toast = useToast();
const editedSelection = ref();
const tendersDataTable = ref()
const deleteIsLoading = ref(false);
const selectedFilter = ref(tenderFilters.filters[0]);
// grabs base of imported JSON file, and adds object to it for no filter
const tenderFilterList = ref([])
const users = ref([])

// computed property used to show a single delete button loading on delete
const reactiveSelectedTenderID = ref(null)
const selectedTenderID = computed({
    get() {
        return reactiveSelectedTenderID.value
    },
    set(tenderID) {
        reactiveSelectedTenderID.value = tenderID
    }
})

watch(selectedFilter, (oldValue, newValue) => {
    localStorage.setItem('selectedFilter', JSON.stringify(selectedFilter.value));
    emit('getFilteredTenders', selectedFilter.value)
})

onMounted(() => {
    if (!localStorage.getItem('selectedFilter')) {
        localStorage.setItem('selectedFilter', JSON.stringify(tenderFilters.filters[0]));
    }
    pushFilterItemsToVariable();
    getUsers();
})

function pushFilterItemsToVariable() {
    // only works at start when array is empty
    if (tenderFilterList.value.length !== 0) {
        return
    } else {
        // push all items in the array used for filters dropdown
        for (const index in tenderFilters.filters) {
            tenderFilterList.value.push(tenderFilters.filters[index])
        }
        // adds no filter to items in dropdown used in header
        tenderFilterList.value.push({
            "id": 1,
            "name": t('DossierListTable.no-filter')
        })
    }
}


function goToURL(URL, dossier) {
    router.push(URL + dossier.id + '')
}

// API request functions

function getUsers() {
    axiosInstance.get('/users/')
        .then((response) => {
            users.value = response.data;
        })
        .catch(() => {

        })
        .then(() => {
        })
}

function onCellEditComplete(event) {
    let { newData, index } = event;
    if (event.value === event.newValue) {
        return
    }

    // assigned_user gives an object with .id and .email, but the backend only needs the id
    // so the following code selects that id, and assigns that to the assigned_user property of the object
    // if the put is being requested by the assigned_user field
    if (event.field === 'assigned_user') {
        // add the .id property the newData object, since that is all that is needed in the backend to add an AccountUser
        // also, the dropdown sends an object with properties id and email, ONLY if the event.field === assigned_user
        newData.assigned_user = event.newValue.id;
    }
    // if user === null on PUT request, backend crashes
    // so delete assigned user when there is no assigned user
    if (newData.assigned_user === null) {
        delete newData.assigned_user
    }

    axiosInstance.put('/dossier-edit/', newData)
        .then((response) => {
            emit('updateTender', newData);
        })
        .catch((error) => {
            devLog(error)
        })
        .then(() => {
            // isLoading.value = false
        })
};

function deleteConfirm(event, tender) {
    reactiveSelectedTenderID.value = tender.id
    confirm.require({
        message: t('DossierListTable.delete-confirm'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: t('DossierListTable.yes'),
        rejectLabel: t('DossierListTable.no'),
        acceptClass: 'confirm-delete',
        accept: () => {
            deleteIsLoading.value = true;
            deleteDossier(tender);
        },
        reject: () => {
            toast.add({ severity: 'error', summary: t('DossierListTable.cancel-summary'), detail: t('DossierListTable.cancel-detail'), life: 3000 });
        }
    });
};

function deleteDossier(tender) {
    axiosInstance.delete('dossiers/' + tender.id + '/')
        .then((response) => {
            // removes currently deleted tender from tenderList reactive object
            emit('deleteDossier', tender)

            // shows delete succeeded notification
            deleteIsLoading.value = false;
            toast.add({ severity: 'info', summary: t('DossierListTable.success-summary'), detail: t('DossierListTable.success-detail'), life: 3000 });
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
}



// export CSV functions
function customExportCSV() {
    setExportTableCSV();
};

function setExportTableCSV() {
    tendersDataTable.value = tenderList.value;
    // ordered objects will be pushed here
    const orderedTendersData = [];

    // all actions that will be exported into the csv table will be edited to follow this template
    let tenderTemplate = {
        "author": null,
        "name": null,
        "creation_date": null,
        "contractor": null,
        "procedure": null,
        "submission_date": null
    }

    for (const item in tenderList.value) {

        // delete props that aren't needed in excel sheet
        delete tenderList.value[item].id

        // pasting data into the template
        tenderTemplate.author = tendersDataTable.value[item].author
        tenderTemplate.name = tendersDataTable.value[item].name
        // returns date into readable string + replaces a comma so excel doesn't split up the date and time in different columns
        tenderTemplate.creation_date = formatDate(tendersDataTable.value[item].creation_date).replace(',', '')
        tenderTemplate.contractor = tendersDataTable.value[item].contractor
        tenderTemplate.procedure = tendersDataTable.value[item].procedure
        tenderTemplate.submission_date = tendersDataTable.value[item].submission_date


        orderedTendersData.push(tenderTemplate);
        // resets the values, if this piece of code is removed, the function will push the same object into the array
        tenderTemplate = {
            "author": null,
            "name": null,
            "creation_date": null,
            "contractor": null,
            "procedure": null,
            "submission_date": null
        }
    }

    // gets headers and translates them
    const headers = Object.keys(tenderTemplate);
    let translatedHeaders = []
    for (const index in headers) {
        translatedHeaders.push(t('DossierListTable.' + headers[index]))
    }
    // creating the right format to create csv file
    const main = orderedTendersData.map((item) => {
        return Object.values(item).toString();
    })

    const csv = [translatedHeaders, ...main].join('\n');

    startCSVDownload(csv)
};

function startCSVDownload(csvText) {

    const blob = new Blob([csvText], { type: 'application/csv' })

    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    // name of downloaded file
    a.download = 'all_tenders.csv';
    a.href = url;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);

};

</script>



<style scoped>
input {
    color: grey;
}

.header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.selection {
    align-self: center;
    margin-right: 0.5rem;
    align-items: center;
}

.left-field {
    text-align: left;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dropdown-field {
    margin-right: 1.5rem;
}

:deep(.p-button:not(.export)) {
    border-color: #1A224C;
    background-color: white;
    color: #1A224C;
    font-size: 14px;
    padding: 0.3rem;
}

:deep(.p-button:hover:not(.export)) {
    background-color: #bfc7ff;
    border-color: #bfc7ff;
}

:deep(.p-button.delete:hover:not(.export)) {
    background-color: red;
    border-color: red;
    color: white;
}

:deep(.p-button.edit:hover:not(.export)) {
    background-color: green;
    border-color: green;
    color: white;
}

:deep(.p-button.export) {
    background-color: #1A224C;
    border-color: #1A224C;
    color: white;
}

:deep(.p-button.export:hover) {
    background-color: #bfc7ff;
    border-color: #bfc7ff;
    color: #1A224C;
}

:deep(.p-datatable-loading-overlay) {
    color: #1A224C;
    font-size: 22px;
    font-weight: 900;
    /* background-color: #bfc7ff; */
    opacity: 85%;
}
</style>
