<template>
    <svg v-if="props.className" xmlns="http://www.w3.org/2000/svg" id="fi_2626863" data-name="Layer 1" viewBox="0 0 64 64" :class="props.className">
        <path
            d="M63.28,24.46,53,15.9V9a2,2,0,0,0-4,0v3.56L35.84,1.6a6,6,0,0,0-7.68,0L.72,24.46a2,2,0,0,0,2.56,3.08L30.72,4.67a2,2,0,0,1,2.56,0L56,23.6V58a2,2,0,0,1-2,2H44V38a6,6,0,0,0-6-6H26a6,6,0,0,0-6,6V60H10a2,2,0,0,1-2-2V34a2,2,0,0,0-4,0V58a6,6,0,0,0,6,6H54a6,6,0,0,0,6-6V26.94l.72.6A2,2,0,0,0,62,28a2,2,0,0,0,1.28-3.54ZM24,60V38a2,2,0,0,1,2-2H38a2,2,0,0,1,2,2V60Z">
        </path>
        <circle cx="30" cy="48" r="2"></circle>
    </svg>
</template>

<script setup>
const props = defineProps({
    className: String
})
</script>