<template>
	<form class="form" @submit.prevent="handleSendFormData(handoverForm)">

		<div class="form-field full-width">
			<h2>{{ $t('internalInfoForm.description') }}:</h2>
			<textarea v-model="handoverForm.description" type="text" height="1500"></textarea>
		</div>

		<div class="form-field full-width">
			<UsersDropdown @update-user="selectUser" :selectedUser="handoverForm.user" />
		</div>


		<div class="form-field" v-if="sentFormSuccessfully">
			<p class="success-message">{{ $t('internalInfoForm.success-message') }}.</p>
		</div>


		<TheButton class="form-button">
			<template #text>{{ $t('addSelectionView.submit') }}</template>
		</TheButton>

	</form>
</template>

<script setup>
import TheButton from '@/components/ui/TheButton.vue';
import UsersDropdown from '../../components/functional/UsersDropdown.vue';

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';

// When implementing get functionality, uncomment route and useRoute
const route = useRoute();

// Possible functionality, change color of date if deadline is close (less that a week)

// When this value = false, fields can be edited
const sentFormSuccessfully = ref(false);

// if there is a successful get request for internal info, this will be true
const internalInfoExists = ref(false)


const handoverForm = ref('');
// this is used to check on put request, if user did not change anything and tries to submit form, it returns
const handoverFormOrignalData = ref('');

onMounted(() => {
	getInternalInfo();
});

function selectUser(newUser) {
	handoverForm.user = newUser;
};

function getInternalInfo() {
	// Should get the internal information
	// For now = empty function
	axiosInstance.get('info/?dossier=' + route.params.dossierId)
		.then((response) => {
			devLog(response.data)
			// if it returns an empty object, turn handoverForm into an empty object with valid properties
			if (response.data.length == 0) {
				handoverForm.value = {
					"dossier": parseInt(route.params.dossierId),
					"description": null,
					"user": null,
				}
			} else {
				//TODO: if backend hasn't added user to object, add it here
				handoverForm.value = response.data[0];
				handoverFormOrignalData.value = { ...response.data[0] };
				internalInfoExists.value = true;
			}
		})
		.catch((error) => {
		})
		.then(() => {
			//
		})
}

function handleSendFormData(formData) {
	// if user made no changes and tries to post/put, return
	if (handoverForm.value === handoverFormOrignalData.value) {
		return
	}
	// if user field is an object, get the id from it and assign that to handoverForm.value.user
	if (typeof handoverForm.value.user === 'object') {
		handoverForm.value.user = handoverForm.value.user.id;
	}

	// checks if user already has internal info
	if (internalInfoExists.value == true) {
		// putFormData(formData);
	} else {
		// postFormData(formData);
	}
}

function postFormData(formData) {
	axiosInstance.post('info/?tender=' + route.params.id, formData)
		.then((response) => {
			sentFormSuccessfully.value = true;
			// immediately call this function, to get id number of this internal info so user can immediately use put requests
			getInternalInfo();
		})
		.catch((error) => {
		})
		.then(() => {
			//
		})
}

function putFormData(formData) {
	axiosInstance.put('info/' + formData.id + '/', formData)
		.then((response) => {
			sentFormSuccessfully.value = true;
		})
		.catch((error) => {
		})
		.then(() => {
		})
}

</script>

<style scoped>
.square-card {
	border-radius: 0;
	min-width: fit-content;
}

/* FORM */
.form,
.form-empty,
.form-undefined {
	color: #1A224C;
	padding: 0.5rem;
	padding-top: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: start;
	max-width: 50%;
}

.form-field {
	margin-top: 0.5rem;
	display: flex;
	flex-direction: column;
}

.full-width {
	width: 100%;
}

.form-button {
	margin-top: 1rem;
}

.form-title {
	font-weight: 450;
	color: var(--sec-dark-purple);
}

.error {
	color: red;
}

.success-message {
	color: green;
}

textarea {
	width: 100%;
	padding: 0.75rem;
	outline: none;
	background-color: white;
	border: 1px solid #6D76B4;
	color: var(--main-dark-purple);
	overflow-y: scroll;
}

.buttons-field {
	align-items: flex-end;
}

.selected-text {
	font-style: oblique;
	color: var(--main-dark-purple);
}

h2 {
	font-weight: 350;
	color: var(--sec-dark-purple);
	margin-bottom: 1rem;
	font-size: large;
}
</style>../../components/functional/UsersDropdown.vue