<template>

    <div class="full-page" :class="{ 'loading': loading }">
        <breadcrumbs-title></breadcrumbs-title>
        <single-select-documents @updateData="pickGetOnMount"
            @noDocumentsAvailable="noDocumentsFound"></single-select-documents>
        <RightClickRisksFunctionality v-if="currentlySelectedDocument" :data="rightClickData" :event="rightClickEvent"
            :currentlySelectedDocument="currentlySelectedDocument" :selectedParagraphs="selectedParagraphsIDs"
            :selectedParagraphObjects="selectedParagraphs" @risk-edited="getSingleParagraph"
            @risk-added="getSingleParagraph" @delete-risk="deleteRiskFromParagraph"
            @delete-section-risk="deleteSectionRisk" @paragraphsHaveBeenChanged="updateParagraphs">
        </RightClickRisksFunctionality>
        <div class="paginator">
            <Paginator class="paginator" :class="{ 'disabled': loading }" @page="getNewPage($event)" :disabled="'true'"
                v-model:first="currentPageObject.first" :rows="500" :total-records="countOfParagraphs"
                :key="currentPageObject.page" :ref_key="currentPageObject.page">
            </Paginator>
        </div>

        <v-card v-if="noDocumentsInDossier">
            <p>{{ $t('DetailedAnalysisView.no-docs') }}</p>
        </v-card>

        <v-card class="square-card-page" id="card-background"
            v-else-if="!noDocumentsInDossier && !noParagraphsInDocument && !loading">
            <div class="left-row" v-if="allLabelsArray">

                <div class="sidebar">

                    <Listbox v-model="selectedLabels" :options="sortedLabelsArray" multiple listStyle="height:360px"
                        :disabled="scrolling" v-if="dataHasBeenSorted">
                        <template #option="slotProps">
                            <div class="flex align-items-center">
                                <div>{{ $t('riskDetailsTable.' + slotProps.option) }}
                                    <span> ({{ amountOfLabels[slotProps.option] }})
                                    </span>
                                </div>
                            </div>
                        </template>
                    </Listbox>
                    <div class="single-column">
                        <p v-if="selectedLabels.length > 0 && selectedLabelsIndexes.length > 0">{{
        $t('DetailedAnalysisView.item')
    }} {{ currentSelectedIndex + 1
                            }}
                            {{ $t('DetailedAnalysisView.of') }} {{
        selectedLabelsIndexes.length }}</p>
                        <p v-if="selectedLabels.length > 0 && selectedLabelsIndexes.length === 0">{{
        $t('DetailedAnalysisView.empty-label') }}</p>
                    </div>
                    <div class="button-field">
                        <Button icon="pi pi-arrow-left" :disabled="disableButtons()"
                            @click="scrollToItemInSelection('decrease')" />
                        <Button icon="pi pi-arrow-right" :disabled="disableButtons()"
                            @click="scrollToItemInSelection('increase')" />
                    </div>

                </div>
            </div>
            <div class="right-row">

                <DataTable :value="shownTableValues" tableStyle="min-width: 50rem" class=datatable
                    @row-click="addRiskToSelection" v-if="paragraphs !== null">
                    <Column field="text" :header="$t('DetailedAnalysisView.text')" class="column"
                        :style="'padding: 0; margin: 0'">
                        <template #body="slotProps">
                            <div class="single-column text" :id="slotProps.index"
                                :style="'border-color:' + slotProps.data.risk_section_color"
                                @contextmenu.prevent="rightClick($event, slotProps.data)"
                                :class="showSelectedRisk(slotProps.data)">

                                <div class="header">
                                    <p v-if="slotProps.data.risk_section_code === 1" class="top-label unselectable"
                                        :style="'background-color:' + slotProps.data.risk_section_color">
                                        <span v-if="slotProps.data.risk_section && slotProps.data.risk_section.name">{{
        $t('riskDetailsTable.' + slotProps.data.risk_section.name) }}</span>
                                    </p>

                                </div>

                                <div class="text-field">
                                    <p :class="highlightItem(slotProps.data.risk)">
                                        <span v-if="slotProps.data.risk && slotProps.data.risk.flag">*</span>
                                        {{ slotProps.data.text }}
                                    </p>

                                    <div class="footer">

                                        <p v-if="slotProps.data.page !== 0" class="pagenumber unselectable">
                                            {{ $t('DetailedAnalysisView.page') }}: {{ slotProps.data.page }}</p>

                                        <RiskTags :risks="slotProps.data.risk" :id="slotProps.data.id"></RiskTags>
                                    </div>
                                </div>


                            </div>
                        </template>
                    </Column>
                </DataTable>

            </div>

        </v-card>
        <v-card v-else-if="loading">
            <div class="card flex justify-content-center">
                <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8"
                    aria-label="Custom ProgressSpinner" />
            </div>
            <p>{{ $t('DetailedAnalysisView.loading') }}</p>
        </v-card>
        <v-card v-else-if="noDocumentIsSelected">
            <p>{{ $t('DetailedAnalysisView.choose-doc') }}</p>
        </v-card>
        <v-card v-else>
            <p>{{ $t('DetailedAnalysisView.empty-doc') }}</p>
        </v-card>

    </div>

</template>

<script setup>
import Tag from 'primevue/tag';
import ProgressSpinner from 'primevue/progressspinner';
import Listbox from 'primevue/listbox';
import Button from 'primevue/button';
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import SingleSelectDocuments from '@/views/detailed-analysis/SingleSelectDocuments.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import RightClickRisksFunctionality from '@/components/functional/RightClickRisksFunctionality.vue';
import Paginator from 'primevue/paginator';
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import RiskTags from '@/components/functional/RiskTags.vue';

import { onMounted, ref, watch, nextTick, computed } from 'vue';
import { useRoute } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import riskDetailsChecklist from '@/assets/JSON/riskDetailsChecklist.json';
import i18n from '@/i18n';
import { devLog } from '@/helpers/helpers';

import 'primeicons/primeicons.css';

const route = useRoute();
const toast = useToast();
const confirm = useConfirm();
const loading = ref(true);
// API AND DATA
const paragraphs = ref(null);
const shownTableValues = ref(null)
const dataHasBeenSorted = ref(false);

// LABELS FUNCTIONALITY
const allLabelsArray = ref([]);
// the labels that the user has selected, these are just strings of the names of the labels in the array
const selectedLabels = ref([]);
// this array has all the index numbers of the items in the paragraphs array that have a label that matches with the selectedLabels
// the indexes are set in the classes of all items, which makes scrolling to them possible
const selectedLabelsIndexes = ref([]);
const currentSelectedIndex = ref(0);
const buttonsHaveBeenCalledOnce = ref(false);
const amountOfLabels = ref({});
const scrolling = ref(false);

const currentlySelectedDocument = ref(null)
const noDocumentsInDossier = ref(false);
const noParagraphsInDocument = ref(false);
const noDocumentIsSelected = ref(false);

const counterID = ref(0);

// PAGINATION FUNCTIONALITY
// total amount of paragraphs spread across all pages
// this will be used to check how many pages are available
const countOfParagraphs = ref(null);

// currently selected page, on load this is the default value
const currentPageObject = ref({
    page: 0,
    first: 0,
    rows: 500,
    pageCount: 1
});
const nextPage = ref(null);
const previousPage = ref(null);

// SECTION RISK FUNCTIONALITY
// all selected risks are stored here
const selectedParagraphs = ref([]);
// only the id's of the selected risks are here
// placed in this array, since using the first array for a computed property may cause performance issues
const selectedParagraphsIDs = computed(() => {
    return selectedParagraphs.value.map(risk => risk.id);
});

// RIGHT-CLICK FUNCTIONALITY
const rightClickEvent = ref(null);
const rightClickData = ref(null);

// On mount, if query is present, screen goes straight to that paragraph in query
const queriesOnMount = ref(false);

const props = defineProps({
    class: String
});


const { t } = i18n.global;

let sortedLabelsArray = computed(() => {
    return [...allLabelsArray.value].sort((a, b) => {
        const translatedA = t('riskDetailsTable.' + a);
        const translatedB = t('riskDetailsTable.' + b);
        return translatedA.localeCompare(translatedB);
    });
});

onMounted(() => {
    if (route.query && route.query.seq && route.query.document) {
        queriesOnMount.value = true;
    };
})

watch(selectedLabels, (newValue, oldValue) => {
    addItemsToSelectedLabelsIndexes(selectedLabels.value);
});

// watcher for shownTableValues
watch(shownTableValues, (newValue, oldValue) => {
    currentSelectedIndex.value = 0;
    // if there were queries on mount, and the shownTableValues have been set
    if (queriesOnMount.value === true) {
        // hide single-select-documents component
        // nextTick watches the value, and calls a function once that value has been rendered to the site
        nextTick(() => {
            scrollToQueriedRisk(parseInt(route.query.seq) - currentPageObject.value.first);
        });
        // set queriesOnMount to false, so this code only runs once
        queriesOnMount.value = false;
    }
});


function noDocumentsFound(value) {
    noDocumentsInDossier.value = value;
}

function pickGetOnMount(selectedDocument) {
    if (route.query && route.query.document && route.query.seq && queriesOnMount.value === true) {
        searchParagraphsWithRisk(selectedDocument);
    } else {
        getParagraphs(selectedDocument);
    }
}

function getParagraphs(selectedDocument) {
    // loading.value = true;
    // start by setting all values back to null
    counterID.value = 0;
    paragraphs.value = null;
    shownTableValues.value = null;
    dataHasBeenSorted.value = false;
    selectedLabelsIndexes.value = [];
    currentSelectedIndex.value = 0;
    buttonsHaveBeenCalledOnce.value = false;
    amountOfLabels.value = {};
    noDocumentIsSelected.value = false;
    nextPage.value = null;
    previousPage.value = null;
    countOfParagraphs.value = null;

    if (selectedDocument === null) {
        noDocumentIsSelected.value = true;
        return
    }

    axiosInstance.get('/paragraphs/?document=' + selectedDocument)
        .then((response) => {
            paragraphs.value = response.data.results;
            currentlySelectedDocument.value = selectedDocument;
            countOfParagraphs.value = response.data.count;
            nextPage.value = response.data.next;
            previousPage.value = response.data.previous;
            shownTableValues.value = response.data.results;
            loading.value = false;

            if (response.data.results.length === 0) {
                noParagraphsInDocument.value = true;
            } else {
                noParagraphsInDocument.value = false;
                sortLabels();

            }

        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
};

function getSingleParagraph(paragraphID) {
    axiosInstance.get('/paragraphs/' + paragraphID + '/?document=' + currentlySelectedDocument.value)
        .then((response) => {
            // index of affected paragraph in paragraphs array
            let objIndex = paragraphs.value.findIndex((obj => obj.id == paragraphID));
            // store old and new paragraph in variables, we will use them to update labels
            let oldParagraph = paragraphs.value[objIndex];
            let newParagraph = response.data;
            // resetLabels();
            updateLabels(oldParagraph, newParagraph, objIndex);
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
}

function updateLabels(oldParagraph, newParagraph, objIndex) {
    // in the oldParagraph, if there are risks, iterate over each risk
    // and then, decrease the amount of labels in the amountOfLabels object by 1 for every risk.name that is in the oldParagraph
    // but it cannot go lower than 0
    if (oldParagraph.risk) {
        for (const risk of oldParagraph.risk) {
            if (amountOfLabels.value[risk.name] > 0) {
                amountOfLabels.value[risk.name]--;
            }
        }
    }
    // do the same for newParagraph, but increase the amount of labels in the amountOfLabels object by 1
    if (newParagraph.risk) {
        for (const risk of newParagraph.risk) {
            amountOfLabels.value[risk.name]++;
        }
    }
    paragraphs.value[objIndex] = newParagraph;
}

//////////////////////////////////////////////////////////// PAGINATION FUNCTIONS ///////////////////////////////////////////////////////////////////
function getNewPage(event) {
    if (currentPageObject.value.page === event.page) {
        return
    }

    else {
        currentPageObject.value = event;
        loading.value = true;
        selectedParagraphs.value = [];
        getParagraphsOfNewPage();
    }
}

function getParagraphsOfNewPage() {
    // start by setting all values back to null
    counterID.value = 0;
    paragraphs.value = null;
    shownTableValues.value = null;
    dataHasBeenSorted.value = false;
    selectedLabelsIndexes.value = [];
    currentSelectedIndex.value = 0;
    buttonsHaveBeenCalledOnce.value = false;
    amountOfLabels.value = {};
    noDocumentIsSelected.value = false;

    let currentURL = '/paragraphs/?document=' + currentlySelectedDocument.value + '&limit=500' + '&offset=' + currentPageObject.value.first;

    axiosInstance.get(currentURL)
        .then((response) => {
            paragraphs.value = response.data.results;
            countOfParagraphs.value = response.data.count;
            nextPage.value = response.data.next;
            previousPage.value = response.data.previous;
            shownTableValues.value = response.data.results;
            loading.value = false;

            if (response.data.results.length === 0) {
                noParagraphsInDocument.value = true;
            } else {
                noParagraphsInDocument.value = false;
                sortLabels();
            }
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// adds highlight class to items with a label that is in the selectedLabels array
// and adds selected class to items user has selected
function highlightItem(risk) {
    // This replaces empty label with label "None" for classes
    // Might be irrelevant to users, so its commented out for now
    // might become more relevant once labels can be assigned to items under labeled item
    // if(risk == "") {
    //     risk = "None";
    // }
    if (risk !== null && selectedLabels.value.includes(risk.name)) {
        return 'highlight'
    }
    else {
        return
    }
}

//////////////////////////////////////////////////////////// LABEL FUNCTIONS ////////////////////////////////////////////////////////////////////////

function sortLabels() {
    allLabelsArray.value = [];
    for (const property in riskDetailsChecklist) {
        for (const index in riskDetailsChecklist[property]) {
            // const translatedLabel = t('riskDetailsTable.' + riskDetailsChecklist[property][index]);
            // allLabelsArray.value.push(translatedLabel);
            allLabelsArray.value.push(riskDetailsChecklist[property][index])
        }
    }
    allLabelsArray.value.sort();
    getLabelAmount();
}

function getLabelAmount() {
    selectedLabels.value = [];
    // Setup the amountOfLabels object, by assigning it a property for every label in allLabelsArray, with a value of 0
    for (const label of allLabelsArray.value) {
        amountOfLabels.value[label] = 0;
    }
    // Loop through paragraphs
    for (const paragraph of paragraphs.value) {
        // When an item has risks, iterate over each risk
        if (paragraph.risk) {
            const countedRisks = new Set(); // Initialize a set to track counted risks
            for (const risk of paragraph.risk) {
                if (allLabelsArray.value.includes(risk.name) && !countedRisks.has(risk.name)) {
                    amountOfLabels.value[risk.name]++;
                    countedRisks.add(risk.name); // Mark this risk as counted
                }
            }
        }

        // If the paragraph has a risk_section, and that risk section's name property is included in the selectedLabels
        // and the item in question has a risk_section_code of 1, add it to the amountOfLabels object
        if (paragraph.risk_section && allLabelsArray.value.includes(paragraph.risk_section.name) && paragraph.risk_section_code === 1) {
            amountOfLabels.value[paragraph.risk_section.name]++;
        }
    }
    dataHasBeenSorted.value = true;
}

function disableButtons() {
    if (selectedLabels.value.length === 0) {
        return true;
    } else if (selectedLabels.value.length > 0 && selectedLabelsIndexes.value.length === 0) {
        return true;
    } else {
        return false;
    }
}

function addItemsToSelectedLabelsIndexes() {
    selectedLabelsIndexes.value = [];
    currentSelectedIndex.value = 0;
    for (let i = 0; i < shownTableValues.value.length; i++) {
        // Check if it has risks and iterate over each risk
        if (shownTableValues.value[i].risk !== null) {
            for (const risk of shownTableValues.value[i].risk) {
                if (selectedLabels.value.includes(risk.name)) {
                    selectedLabelsIndexes.value.push(i);
                    break; // Break to avoid adding the same index multiple times if multiple risks match
                }
            }
        }
        // Check if it has a risk section and if risk section has a name property that is included in the selected labels
        if (shownTableValues.value[i].risk_section !== null &&
            selectedLabels.value.includes(shownTableValues.value[i].risk_section.name) &&
            shownTableValues.value[i].risk_section_code === 1) {
            // Add index if not already added
            if (!selectedLabelsIndexes.value.includes(i)) {
                selectedLabelsIndexes.value.push(i);
            }
        }
    }
}

function scrollToItemInSelection(typeOfScroll) {
    // FIX SCROLLING, gets cancelled by almost anything (clicks)
    scrolling.value = true;
    setSelectedIndex(typeOfScroll);

    // first set index correctly, seperate function
    const el = document.getElementById(selectedLabelsIndexes.value[currentSelectedIndex.value]);

    el.scrollIntoView({ behavior: "smooth" });

    scrolling.value = false;
}

// find way to optimize function, maybe pass '++' and '--' in order to make it completely dynamic
function setSelectedIndex(typeOfScroll) {
    if (typeOfScroll === 'increase') {
        // if buttons have never been called
        if (!buttonsHaveBeenCalledOnce.value) {
            // set the value to true, since it has been called now
            buttonsHaveBeenCalledOnce.value = true;
            if (currentSelectedIndex.value === 0) {
                // if button has been called for the first time, on an increase, it will set the index to -1
                // This makes sure the scrolling functionality of the labels works as intended
                // without this, the functionality will skip right to #2 on the labels list
                currentSelectedIndex.value = -1;
            }
        }
        // resets the index 
        if (currentSelectedIndex.value === selectedLabelsIndexes.value.length - 1) {
            currentSelectedIndex.value = -1;
        }
        currentSelectedIndex.value = currentSelectedIndex.value + 1;

    } else {
        if (currentSelectedIndex.value === 0) {
            currentSelectedIndex.value = selectedLabelsIndexes.value.length;
        }
        currentSelectedIndex.value = currentSelectedIndex.value - 1;
    }
}

function searchParagraphsWithRisk(selectedDocument) {
    currentlySelectedDocument.value = selectedDocument;
    let sequence = parseInt(route.query.seq);
    if (sequence >= 0 && sequence <= 500) {
        currentPageObject.value.page = 0;
    } else if (sequence > 500) {
        currentPageObject.value.first = Math.floor((sequence) / 500) * 500;
        currentPageObject.value.page = Math.ceil(sequence / 500) - 1; // Update currentPageObject.page as well

    } else {
        return "sequence less than 0, should be impossible";
    }

    let currentURL = '/paragraphs/?document=' + currentlySelectedDocument.value + '&offset=' + currentPageObject.value.first;
    axiosInstance.get(currentURL)
        .then((response) => {
            currentPageObject.value.pageCount = Math.ceil(response.data.count / 500);
            paragraphs.value = response.data.results;
            currentlySelectedDocument.value = selectedDocument;
            countOfParagraphs.value = response.data.count;
            nextPage.value = response.data.next;
            previousPage.value = response.data.previous;
            shownTableValues.value = response.data.results;
            loading.value = false;


            if (response.data.results.length === 0) {
                noParagraphsInDocument.value = true;
            } else {
                noParagraphsInDocument.value = false;
                sortLabels();
            }

        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
};

function scrollToQueriedRisk(seq) {
    scrolling.value = true;
    const el = document.getElementById(seq);
    el.scrollIntoView({ behavior: "smooth" });
    scrolling.value = false;
    queriesOnMount.value = false;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////// SECTION RISK SELECTION ///////////////////////////////////////////////////////
// adds appropriate classname to items, to show which items are selected and which are not
function showSelectedRisk(risk) {
    if (selectedParagraphsIDs.value.includes(risk.id)) {
        return 'section' + risk.risk_section_code + ' ' + risk.risk_section_color + ' ' + 'selected'
    } else {
        return 'section' + risk.risk_section_code + ' ' + risk.risk_section_color;
    }
}

// on click, adds that risks id to array, all items in that array get highlighted
function addRiskToSelection(row) {
    if (!row.originalEvent.shiftKey && selectedParagraphs.value.length >= 1) {
        selectedParagraphs.value = [];
        return
    }

    // give the row.data an index property, and assign that index value to it
    let rowData = row.data;
    rowData.index = row.index;

    // if there are no items, just add what was clicked on
    if (selectedParagraphs.value.length < 1) {
        selectedParagraphs.value.push(rowData);

    }
    // if there is a single item in the array and that item is the same item as the one the user just clicked on
    else if (selectedParagraphs.value.length === 1 && selectedParagraphs.value[0].id === rowData.id) {
        // remove that item
        selectedParagraphs.value = [];
    }

    else {

        // sort all items in array by index
        selectedParagraphs.value.sort((a, b) => a.index - b.index);

        // assign values to let variables with clearer names
        let lowestSelectedIndex = selectedParagraphs.value[0].index;
        let highestSelectedIndex = selectedParagraphs.value[selectedParagraphs.value.length - 1].index;
        let rowDataIndex = rowData.index;

        // if newly pressed index is lower than lowest selected index
        if (rowDataIndex < lowestSelectedIndex) {
            let difference = lowestSelectedIndex - rowDataIndex;
            // that newly pressed index should become the newly lowest selected index
            // for that to happen, all paragraphs with an index between those two indexes, need to be pushed to the selectedParagraphs
            for (let i = 0; i <= difference; i++) {

                let addedRisk = shownTableValues.value[rowDataIndex + i];
                // assign index to all those items
                addedRisk.index = rowDataIndex + i;
                // we then add it to the selectedParagraphs array
                selectedParagraphs.value.push(addedRisk);
            }

        } else if (rowDataIndex > highestSelectedIndex) {
            // after user selects a paragraph, and then selects a paragraph lower in the list
            // if rowDataIndex is higher than array's  highest selected index, add all items of that point to array
            // ADD ITEMS
            // difference = amount of times we have to loop to push all items between the two selected paragraphs into selectedParagraphs array
            let difference = rowDataIndex - highestSelectedIndex;
            for (let i = 1; i <= difference; i++) {
                // shownTableValues has all paragraphs
                // we select the one with the index that is equal to the selectedParagraphs lowest index
                // then add the i, so it doesn't push the same row.data everytime, but always goes one index higher per loop
                // loop also starts at 1 and not 0, so the loop skips over the index
                let addedRisk = shownTableValues.value[highestSelectedIndex + i];
                // assign index to all those items
                addedRisk.index = highestSelectedIndex + i;
                // we then add it to the selectedParagraphs array
                selectedParagraphs.value.push(addedRisk);
            }
        } else {
            // if newly selected paragraph is inside of selection
            // so if the newly selected paragraph index, is in between the highestSelectedIndex and lowestSelectedIndex
            // remove all items with index that comes after the addedRisk.id
            selectedParagraphs.value = selectedParagraphs.value.filter(obj => obj.index <= rowData.index);
        }
    }
}

/////////////////////////////////////////////////////////// RIGHT-CLICK ////////////////////////////////////////////////////////////
// RIGHT CLICK: sets data in variable
function rightClick(event, data) {
    // if what the user right-clicked on wasn't selected, empty selection
    if (!selectedParagraphsIDs.value.includes(data.id)) {
        selectedParagraphs.value = [];
    }
    rightClickData.value = data;
    rightClickEvent.value = event;
}

function deleteRiskFromParagraph(paragraph) {
    axiosInstance.delete('/risks/' + paragraph.risk.id + '/')
        .then((response) => {
            getSingleParagraph(paragraph.id);
            toast.add({ severity: 'info', summary: t("DetailedAnalysisView.toast.success-summary"), detail: t("DetailedAnalysisView.toast.success-detail"), life: 5000 });
            resetLabels();
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
}

function deleteSectionRisk(data) {
    axiosInstance.delete('/risks/' + data.risk_section.id + '/')
        .then((response) => {
            updateParagraphs(response.data.changed_paragraphs);
            toast.add({ severity: 'info', summary: t("DetailedAnalysisView.toast.success-summary"), detail: t("DetailedAnalysisView.toast.success-detail"), life: 5000 });
            resetLabels();
        })
        .catch((error) => {
            //handle error
        })
        .then(() => {
            //always executed
        });
}

function updateParagraphs(changedParagraphs) {
    changedParagraphs.forEach(newObj => {
        const index = paragraphs.value.findIndex(obj => obj.id === newObj.id);
        if (index !== -1) {
            paragraphs.value[index] = newObj;
        }
    });
    resetLabels();
    // Optionally, you can return the updated array
    return paragraphs.value;
}

function resetLabels() {
    allLabelsArray.value = [];
    selectedLabels.value = [];
    selectedLabelsIndexes.value = [];
    currentSelectedIndex.value = 0;
    // deletes all properties of object
    for (let variableKey in amountOfLabels.value) {
        if (amountOfLabels.value.hasOwnProperty(variableKey)) {
            delete amountOfLabels.value[variableKey];
        }
    }
    sortLabels();
}

</script>

<style scoped>
.square-card-page {
    border-radius: 0;
    overflow-y: auto;
    max-height: 80vh;
    display: flex;
    flex-direction: row;
}

.left-row {
    position: sticky;
    top: 0;
    z-index: 9999;
    padding: 1rem;
    text-align: left;
    max-width: 24%;
}

.search-bar {
    margin-bottom: 0.5rem;
}

.single-column {
    color: var(--main-dark-purple);
    display: flex;
    flex-direction: column;
}

.button-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    text-align: left;
}

.text {
    font-size: 18px;
    text-align: left;
    white-space: pre-line;
}

.highlight {
    /* background-color: var(--main-light-purple); */
    background-color: yellow;
    width: fit-content;
}

.footer {
    color: var(--sec-dark-purple);
    font-size: 10px;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.label {
    text-align: start;
}

.label,
.pagenumber {
    color: var(--sec-dark-purple);
    font-size: 10px;
}

.section1 {
    border-left: 2px solid black;
    border-top: 2px solid black;
}

.section2 {
    border-left: 2px solid black;
}

:deep(.p-datatable) {
    margin-left: 4rem;
}

:deep(.p-column-header-content) {
    padding: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

:deep(.p-tag) {
    background-color: var(--main-light-purple);
    font-weight: 300;
    color: var(--main-dark-purple);
}

.top-label {
    text-transform: uppercase;
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.text-field {
    padding: 1rem;
}

.green .top-label {
    color: white;
}

.yellow .top-label {
    color: black;
}

.loading {
    cursor: wait;
}

.loading :deep(.p-paginator) {
    cursor: wait;
    pointer-events: none;
}

.loading :deep(.p-paginator-pages) {
    cursor: wait;
    pointer-events: none;
}

.loading .disabled {
    cursor: wait;
    pointer-events: none;
}

.selected {
    background-color: #F1F3FF;
}
</style>