<template>
    <div class="risks-container">
        <RightClickRisksFunctionality :data="rightClickData" :event="rightClickEvent"
            :currentlySelectedDocument="selectedDocument" @riskEdited="updateRisk"></RightClickRisksFunctionality>


        <div class="checkbox-component">
            <!-- Empty div used to style this component same way as the other -->
        </div>
        <div class="table-component">
            <SearchQandACard :selectedDocuments="props.selectedDocuments" :question="props.question"
                :questionHasBeenCalled="props.questionHasBeenCalled" @updateLoading="updateLoading">
            </SearchQandACard>

            <div class="empty-table" v-if="!searchResult && !searchInput">
                <p>{{ $t('SearchInputTable.type-something') }}</p>
            </div>
            <div v-else v-for="item, name in searchResult">
                <v-card class="square-card">
                    <h2 class="title">{{ name + ": " + searchResult[name].length }}</h2>
                    <div v-for="index in item" class="risk-body" @contextmenu.prevent="rightClick($event, index)">
                        <p class="text" v-html="index.hits"></p>
                        <div class="footer">
                            <p v-if="index.document_name">{{ index.document_name }}</p>
                            <p v-if="index.page">{{ $t('SearchInputTable.page') }}: {{ index.page }}</p>
                            <p v-else></p>
                        </div>
                        <RiskTags :risks="index.risk" :id="index.id" :key="'search-input-table'" @risk-edited="emitRiskEdited"></RiskTags>
                    </div>
                </v-card>
            </div>

        </div>
    </div>
</template>

<script setup>
import RightClickRisksFunctionality from '@/components/functional/RightClickRisksFunctionality.vue';
import SearchQandACard from './SearchQandACard.vue';
import RiskTags from '@/components/functional/RiskTags.vue';

import { ref, watch, onMounted } from 'vue';
import VuePdfEmbed from 'vue-pdf-embed'

const props = defineProps({
    searchResult: Object,
    searchInput: String,
    question: String,
    selectedDocuments: Array,
    questionHasBeenCalled: Boolean
});

watch(() => props.searchResult, () => {
    searchResult.value = props.searchResult.results;
})

onMounted(() => {
    searchResult.value = props.searchResult.results;
})



const emit = defineEmits(['riskEdited', 'updateLoading'])

const searchResult = ref();
// rightclick functionality
const rightClickEvent = ref(null);
const rightClickData = ref(null);
const selectedDocument = ref(null);


// RIGHT CLICK: sets data in variable
function rightClick(event, data) {
    rightClickData.value = data;
    rightClickEvent.value = event;
    selectedDocument.value = data.document__id;
}
function updateRisk(data) {
    emit('riskEdited');
}

function updateLoading(value) {
    emit('updateLoading', value);
}

function emitRiskEdited(paragraphId) {
    emit('riskEdited', paragraphId);
}

</script>

<style scoped>
.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    font-size: 14px;
    color: var(--sec-dark-purple);
}

.navigation-title,
.title {
    padding: 1rem;
    background-color: white;
    color: var(--main-dark-purple);
    text-align: start;
}

.title {
    border-bottom: 1px solid var(--main-light-purple);
    padding: 1.5rem;
}

.risks-container {
    display: flex;
}

.table-component {
    margin-left: 1rem;
    width: 50%;
    flex-grow: 2;
    overflow-y: auto;
}

.checkbox-component {
    width: 20%;
}

.menu-component {
    width: 35%;
}

.square-card {
    border-radius: 0;
    margin-bottom: 0.5rem;
}

.checkbox-tab-content {
    text-align: start;
    overflow-y: auto;
}

.checkbox-item {
    display: flex;
    padding: 1rem;
    vertical-align: center;
}

/* .checkbox-item:not(:disabled):hover {
    cursor: pointer;
    background-color: #E7EAFF;
  } */

.explanation {
    font-size: xx-small;
}

input.checkboxes {
    width: 15px;
    height: 15px;
    position: relative;
    align-items: center;
    top: 2.5px;
}

label {
    margin-left: 0.8rem;
}

input.checkboxes:disabled {
    background: #6D76B4;
}

input.checkboxes:disabled+label {
    color: var(--main-light-purple);
    font-weight: 200;
}

.title-count {
    display: inline;
    font-weight: 500;
}

.risks-container,
.menu-container {
    flex-grow: 1;
}

.motivation-pdf {
    overflow: auto;
}

.risk-body {
    padding: 1rem;
    /* border-bottom: 1px solid #95A0EC; */
    text-align: start;
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
    margin-bottom: 0.5rem;
    border-bottom: 1px solid var(--main-light-purple);
    font-size: 18px;
    margin-left: 1rem;

}

.risk-body:hover {
    cursor: pointer;
}

.checkbox-amount {
    font-weight: 350;
    color: #6D76B4;
}

:deep(.p-button) {
    border-color: #1A224C;
    background-color: white;
    color: #1A224C;
    font-size: 14px;
    padding: 0.3rem;
    height: 2rem;
}

:deep(.p-button.delete:hover) {
    background-color: red;
    border-color: red;
    color: white;
}

:deep(.p-button.edit:hover) {
    background-color: green;
    border-color: green;
    color: white;
}

.view-mode,
.risk.edit-form {
    display: flex;
}

.content {
    flex-grow: 4;
}

.button-field {
    display: flex;
    justify-content: flex-end;
}

.risk-edit-form {
    display: flex;
    width: 100%;
    height: 100%;
}

.text-field {
    color: var(--main-dark-purple);
    font-style: italic;
}

.edit-mode,
.view-mode {
    /* background-color: white; */
    border-radius: 15px;
    padding: 0.5rem;
    padding-left: 1rem;
}

.empty-card {
    padding-bottom: 2rem;
}

.sub-title {
    margin-bottom: 1rem;
}

.text {
    white-space: pre-line;
    text-align: left;
}
</style>