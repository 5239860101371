<template>
    <Card class="square-card">
        <template #header>
            <img :alt="$t('DashboardSearch.img-alt')" src="../../assets/images/search-illustratie.png" />
        </template>
        <template #title>
            <p v-html="$t('DashboardSearch.title')"></p>
        </template>
        <template #content>
            <p v-html="$t('DashboardSearch.content')"></p>
        </template>
        <template #footer>
            <Button :label="$t('DashboardSearch.button-label')" rounded outlined right
                @click="goToURL('/dashboard/' + route.params.dossierId + '/search')">
            </Button>
        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';
import Button from 'primevue/button';

import router from '@/router';
import { useRoute } from 'vue-router';

const route = useRoute();

function goToURL(URL) {
    router.push(URL);
}


</script>

<style scoped>
:deep(.p-card):hover {
    cursor: pointer;
    background-color: #E7EAFF;
}

:deep(.p-card-title),
:deep(.p-card-content) {
    font-size: 14px;
    color: var(--main-dark-purple);
}

.square-card {
    max-width: 25em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    border-radius: 0;
    flex-basis: 48%;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.dashboard-item-title {
    font-weight: 550;
    color: var(--sec-dark-purple);
}

.two-buttons {
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.dashboard-button,
.dropdown {
    min-width: 48%;
    height: 3rem;
    max-width: 48%;
}

.dropdown {
    padding: 0;
    margin: 0;
}

.dashboard-button {
    justify-content: center;
}

.dashboard-single-button {
    width: 100%;
    justify-content: center;
    height: 3rem;
}

.dashboard-button-field,
.two-buttons {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
</style>