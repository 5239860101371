<template>
  <div class="home-view">
    <div class="page-header">
      <breadcrumbs-title></breadcrumbs-title>
      <the-button @click="goToAddDossier" class="button">
        <template #icon>
          <PlusIcon :className="'ui-the-button-icon'"></PlusIcon>
        </template>
        <template #text>
          {{ $t('uploadPublicTender.addTender') }}
        </template>
      </the-button>

    </div>

    <v-card class="square-card-page">
      <dossier-list-table :dataObject="tenderList" :tableHeaders="listHeaders" :dataInTable="dataInTable"
        :isLoading="isLoading" :selectionProfiles="selectionProfiles" @getFilteredTenders="getFilteredTenderList"
        @updateTender="updateTender" @deleteDossier="deleteDossier"></dossier-list-table>
    </v-card>
    <!-- <UploadPublicTender v-else /> -->
  </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import TheButton from '@/components/ui/TheButton.vue';
import DossierListTable from '@/views/dossiers/DossierListTable.vue';
import PlusIcon from '@/assets/icons/PlusIcon.vue';

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

import axiosInstance from '@/services/interceptor';
import { DatetimeFormat } from 'vue-i18n';

//once this component is loaded, getFilteredTenderList is called
onMounted(() => {
  getFilteredTenderList();
  getProfiles();
});

// Headers used in the template, the order of items is very important
const listHeaders = [
  "author",
  "name",
  "assigned_user",
  "creation_date",
  "contractor",
  "submission_date",
]

// Gets passed as a prop, to let child-component know what type of data gets passed
const dataInTable = 'tenders';
// TODO: add loading screen
const isLoading = ref(true);
// the entire tenderList will be placed here, this is the original and won't be edited
const tenderList = ref([]);
// all the created selection profiles will be placed here
const selectionProfiles = ref()

function goToAddDossier() {
  router.push('/dossiers/add-dossier');
}

//gets called on mount, gets users tender list
function getFilteredTenderList(filter) {
  // when function is called, checks value of filter ID first, and assigns value in case filter is undefined
  if (filter === undefined) {
    if (!localStorage.getItem('selectedSelectionProfile')) {
      filter = {
        id: 1,
        name: 'decision'
      };
    } else {
      filter = JSON.parse(localStorage.getItem('selectedFilter'))
    }
  }

  if (filter.id === "none") {
    // when user does not want to use a filter, gets all tenders
    getDossierList();
  } else {
    // else, gets tenders of chosen filter
    // axiosInstance.get('tenders/' + '?filter=' + filter.id)
    axiosInstance.get('/dossiers/')
      .then((response) => {
        tenderList.value = null
        tenderList.value = response.data;
        isLoading.value = false;
      })
      //handle error
      .catch((error) => {
        // if there goes something wrong, user will just get all tenders instead of filtered ones
        getDossierList()
      })
      //always executed
      .then(() => {
      });
  }


};

// if filtered GET errors for some reason, gets all tenders regardless of filter
function getDossierList() {
  axiosInstance.get('/dossiers/')
    .then((response) => {
      tenderList.value = response.data;
      isLoading.value = false;

    })
    //handle error
    .catch((error) => {
    })
    //always executed
    .then(() => {
    });
}

function getProfiles() {
  axiosInstance.get('/profiles/')
    .then((response) => {
      selectionProfiles.value = response.data;
    })
    .catch(() => {

    })
    .then(() => {
      // isLoading.value = false
    })
}

function updateTender(newTenderData) {
  for (let i = 0; i < tenderList.value.length; i++) {
    if (newTenderData.id === tenderList.value[i].id) {
      tenderList.value[i] = newTenderData;
    }
  }
}

function deleteDossier(tender) {
  tenderList.value = tenderList.value.filter(function (object) {
    return object.id !== tender.id;
  });
}

</script>

<style scoped>
.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1.5rem;
}

.square-card-page {
  border-radius: 0;
}

.button-field {
  padding: 1rem;
}

.tender-item:hover {
  background-color: #E7EAFF;
}

.tender-item-click:hover,
.text-center:hover {
  cursor: pointer;
}

.sort {
  height: 1rem;
}

.table-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>