<template>
    <Card class="square-card">
        <template #title>
            {{ $t('PDFSummaryExport.title') }}
        </template>
        <template #content>
            <form class="form" @submit.prevent="checkForErrors()">

                <div class="form-field">
                    <label class="form-title" for="filename">{{ $t('PDFSummaryExport.name') }}:</label>
                    <InputText id="filename" v-model="exportForm.name" aria-describedby="filename-help" />
                    <p v-if="exportForm.name.length < 1 && attemptedFormSent" class="error">{{
                $t('PDFSummaryExport.name-explanation') }}</p>
                </div>

                <div class="form-field" id="documents" v-if="documentsAreVisible">
                    <label class="form-title" for="documents">{{ $t('PDFSummaryExport.selection') }}:</label>
                    <SelectionsDropdown @chosen-selection-changed="updateSelection" :hideDefault="true">
                    </SelectionsDropdown>
                </div>

                <TheButton class="form-button">
                    <template #text>{{ $t('PDFSummaryExport.title') }}</template>
                </TheButton>



            </form>

        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';

import TheButton from '@/components/ui/TheButton.vue';
import InputText from 'primevue/inputtext';
import SelectionsDropdown from '@/components/functional/SelectionsDropdown.vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { ref } from 'vue';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';


const route = useRoute();

const exportForm = ref({
    name: '',
    selection_id: null,
    dossier: route.params.dossierId
})
const attemptedFormSent = ref(false);
const documentsAreVisible = ref(true);


function updateSelection(chosenSelection) {
    if (chosenSelection !== undefined) {
        if (chosenSelection.id === 0) {
            // if standard publican id was chosen, set it to null, this value doesn't need to get passed to endpoint
            exportForm.value.selection_id = '00';
        } else {
            exportForm.value.selection_id = chosenSelection.id
        }
    }
}

function checkForErrors() {
    attemptedFormSent.value = true
    if (exportForm.value.name.length < 1) {
        return
    }
    downloadPDFSummary();
};

function downloadPDFSummary() {
    const language = localStorage.getItem('language') || 'nl';
    axiosInstance({
        url: 'report-pdf-summaries/?dossier=' + route.params.dossierId + '&selection_id=' + exportForm.value.selection_id + '&language=' + language,
        method: 'GET',
        responseType: 'blob', // important
        selection_id: exportForm.value.selection_id
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', exportForm.value.name + '.pdf'); // or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            devLog(error)
        })
}

</script>

<style scoped>
/* FORM */
.form,
.form-empty,
.form-undefined {
    color: #1A224C;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}

.form-button {
    margin-top: 1rem;
}

.form-title {
    font-weight: 450;
    color: var(--sec-dark-purple);
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}

#documents {
    width: 100%;
    margin-top: 1rem;
    color: var(--sec-dark-purple);
}

.button-field {
    display: flex;
    gap: 15px;
}
</style>
