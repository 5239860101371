<template>
  <v-card class="square-card-page">
    <div class="background">
      <div class="content">
        <h2>{{ $t("notFoundPage.title") }}</h2>
        <p>{{ $t("notFoundPage.visit") }}.</p>
        <the-button class="button" @click="goToHomePage">
          <template #text>{{ $t("notFoundPage.homepage") }}</template>
        </the-button>
      </div>

    </div>
  </v-card>
</template>

<script setup>
import TheButton from '@/components/ui/TheButton.vue';

import { useRouter } from 'vue-router';

const router = useRouter();

function goToHomePage() {
  router.push('/');
}

</script>

<style scoped>
h2 {
  color: #1A224C;
  padding: 3rem;
  font-size: 5rem;
}

h2,
p,
.button {
  position: relative;
  text-align: center;
}
.button {
  margin-top: 1rem;
}

.square-card-page {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}
.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 2rem;
}

.square-card-page::before {
  content: "";
  background-image: url('../assets/images/short-logo-purple.svg');
  background-size: contain;
  background-position: center;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.25;
}
</style>