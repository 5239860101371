<template>
  <div class="search-bar-wrapper">
    <form @submit.prevent="emitSearchActivated">
      <InputText v-model="search" :placeholder="$t('SearchInputText.type-keyword')" />
    </form>
    <Button class="button" :label="$t('SearchInputText.search')" type="submit" @click="emitSearchActivated">
    </Button>
  </div>
</template>

<script setup>
import InputText from 'primevue/inputtext';
import TheButton from '@/components/ui/TheButton.vue';
import Button from 'primevue/button';

import { ref } from 'vue';

const emit = defineEmits(['searchActivated'])
const search = ref();

function emitSearchActivated() {
  emit('searchActivated', search.value, "searchInput");
}

</script>

<style scoped>
.search-bar-wrapper {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

:deep(.p-button) {
  background-color: #3F4A86;
  border: none;
  color: white;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

:deep(.p-button):hover {
  background-color: #1A224C;
}
</style>