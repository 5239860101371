<template>
  <form v-if="showForm" class="risk-form" @submit.prevent="checkIfFormIsValid">
    <div class="form-field">
      <div class="form-item">
        <h4 class="form-title">{{ $t('riskForm.summary') }}</h4>
        <v-textarea auto-grow rows="1" type="text" class="text-field" v-model="riskForm.section" />
      </div>
    </div>
    <p v-if="!formIsValid && riskForm.section.length === 0" class="error">{{ $t('riskForm.empty') }}</p>


    <div class="form-field">
      <div class="form-item">
        <h4 class="form-title">{{ $t('riskForm.phase') }}</h4>
        <Dropdown v-model="riskForm.chapter" :options="phases" :placeholder="$t('riskForm.selectPhase')"
          class="w-full md:w-14rem">
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
              <div>{{ $t('addRiskForm.' + slotProps.value) }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="flex align-items-center">
              <div>{{ $t('addRiskForm.' + slotProps.option) }}</div>
            </div>
          </template>
        </Dropdown>
        <p v-if="!formIsValid && !riskForm.chapter" class="error">{{ $t('riskForm.selectDropdown') }}</p>

      </div>


      <div class="form-item">
        <h4 class="form-title">{{ $t('riskForm.riskName') }}</h4>

        <Dropdown v-if="!riskForm.chapter" :disabled="!riskForm.chapter" :placeholder="t('riskForm.selectPhase')"
          class="w-full md:w-14rem">
        </Dropdown>

        <Dropdown v-else-if="riskForm.chapter === 'precontractual'" v-model="riskForm.name"
          :options="riskDetailsChecklist.precontractual" :disabled="!riskForm.chapter"
          :placeholder="t('riskForm.selectPhase')" class="w-full md:w-14rem">

          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
              <div>{{ $t('riskDetailsTable.' + slotProps.value) }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>

          <template #option="slotProps">
            <div class="flex align-items-center">
              <div>{{ $t('riskDetailsTable.' + slotProps.option) }}</div>
            </div>
          </template>

        </Dropdown>


        <Dropdown v-else-if="riskForm.chapter === 'contractual'" v-model="riskForm.name"
          :options="riskDetailsChecklist.contractual" :disabled="!riskForm.chapter"
          :placeholder="t('riskForm.selectPhase')" class="w-full md:w-14rem">

          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
              <div>{{ $t('riskDetailsTable.' + slotProps.value) }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>

          <template #option="slotProps">
            <div class="flex align-items-center">
              <div>{{ $t('riskDetailsTable.' + slotProps.option) }}</div>
            </div>
          </template>

        </Dropdown>

        <p v-if="!formIsValid && !riskForm.name" class="error">{{ $t('riskForm.selectDropdown') }}</p>
      </div>

    </div>
    <div class="buttons-field">
      <Button :label="$t('detailedAnalysisForm.cancel')" icon="pi pi-times" @click="emit('closeForm')" text />
      <Button :label="$t('riskForm.saveRisk')" icon="pi pi-check" class="accept-button" @click="checkIfFormIsValid"
        autofocus />
    </div>
  </form>
  <div v-else class="confirmation-page">
    <div class="confirmation-field">
      <h3 class="confirmation-title">{{ $t('riskForm.confirmationTitle') }}</h3>
      <p class="confirmation-item">{{ $t('riskForm.confirmationExplanation') }}
      </p>
    </div>
    <div class="risks-field">
      <p v-if="existingRisks.length >= 1" v-for="risk in existingRisks" class="risks">
        {{ $t('riskDetailsTable.' + risk.risk_section.name) }}
      </p>
    </div>
    <h3 class="confirmation-title">{{ $t('riskForm.confirmationAccept') }}</h3>

    <div class="buttons-field entire-page">
      <Button :label="t('riskForm.cancel')" icon="pi pi-times" @click="emit('closeForm')" text />

      <Button :label="t('riskForm.riskCreate')" class="accept-button" icon="pi pi-check" @click="createSectionRisk"
        autofocus />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';

import axiosInstance from '@/services/interceptor';
import riskDetailsChecklist from "@/assets/JSON/riskDetailsChecklist.json"

import i18n from '@/i18n';

const t = i18n.global.t;

const props = defineProps({
  selectedParagraphs: Array,
  selectedParagraphObjects: Array,
  documentID: Number
})

onMounted(() => {
  addAllTextToSection();
})

const emit = defineEmits(['closeForm', 'formFinished', 'riskAdded', 'paragraphsHaveBeenChanged']);
const route = useRoute();

const showForm = ref(true);
const phases = [
  'precontractual',
  'contractual'
]

const riskForm = ref({
  "section": '',
  "name": '',
  "chapter": null,
  "document": props.documentID,
  "dossier": parseInt(route.params.dossierId),
  "paragraph_ids": props.selectedParagraphs
});
const existingRisks = ref([]);
const existingRisksIDs = ref([]);

const formIsValid = ref(true);

function addAllTextToSection() {
  let allText = '';
  for (let index in props.selectedParagraphObjects) {
    if (parseInt(index) === 0) {
      allText = props.selectedParagraphObjects[index].text;
    } else {
      allText = allText + '\n' + props.selectedParagraphObjects[index].text;
    }
  }
  riskForm.value.section = allText;
}

function checkIfFormIsValid() {
  if (riskForm.value.name.length < 1 || riskForm.value.name === null || riskForm.value.section.length < 1) {
    formIsValid.value = false;
    return
  } else {
    // if there is no existing risk passed, then create new risk
    checkIfSectionRiskExists();
  }
}

function checkIfSectionRiskExists() {
  for (let i = 0; i < props.selectedParagraphObjects.length; i++) {
    if (props.selectedParagraphObjects[i].risk_section_code !== null && props.selectedParagraphObjects[i].risk_section !== null
      && existingRisksIDs.value.includes(props.selectedParagraphObjects[i].risk_section.id) === false) {
      // pushes a paragraph with section_risk_code = 1
      existingRisks.value.push(props.selectedParagraphObjects[i]);
      existingRisksIDs.value.push(props.selectedParagraphObjects[i].risk_section.id);
    }
  }
  if (existingRisks.value.length >= 1) {
    showForm.value = false;
    return
  }
  createSectionRisk();
}

function createSectionRisk() {
  axiosInstance.post('create-section-risks/', riskForm.value)
    .then((response) => {
      if (response.data.changed_paragraphs) {
        emit('paragraphsHaveBeenChanged', response.data.changed_paragraphs);
      }
      showForm.value = false;
      emit('riskAdded');
      emit('closeForm');
    })
    .catch((error) => {
      //handle error
    })
    .then(() => {
      //always executed
    });
};


</script>

<style scoped>
:deep(.p-button).accept-button {
  color: white;
}

/* FORM */
.risk-form,
.risk-form-empty,
.risk-form-undefined {
  color: #1A224C;
  padding: 0.5rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  width: 100%;
}

.form-field {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-item {
  width: 100%;
}

.form-button {
  margin-top: 1rem;
}

.error {
  color: red;
}

textarea {
  width: 100%;
  padding: 0.75rem;
  outline: none;
  background-color: white;
  border: 1px solid #6D76B4;
  color: var(--main-dark-purple);
  overflow-y: scroll;
}

.buttons-field {
  align-items: flex-end;
  margin-top: 2.5rem;
}

.selected-text {
  font-style: oblique;
  color: var(--main-dark-purple);
}

.text-field {
  color: var(--main-dark-purple);
  font-style: italic;
}

.risks-field {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.risks {
  color: red;
}

.confirmation-title {
  color: var(--main-dark-purple);
}
</style>