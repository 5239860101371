<template>
    <RightClickRisksFunctionality :data="rightClickData" :event="rightClickEvent">
    </RightClickRisksFunctionality>

    <Dialog v-model:visible="dialogIsVisible" modal :header="$t('WarningsCard.popup-header')"
        :style="{ width: '75vw' }">

        <EditWarningForm :warning="warningSelectedForEdit" @closeForm="dialogIsVisible = false"
            @warning-edited="warningEdited"></EditWarningForm>
    </Dialog>

    <Card class="card" v-if="showWarningCard" :class="disableWarnings" :disabled="isLoading">


        <template #title>
            <div class="card-title-field">
                <h4 class="title">
                    <i v-if="isLoading" class="pi pi-hourglass">
                    </i>
                    {{ $t('WarningsCard.warnings') }}
                </h4>
                <Button icon="pi pi-sync" class="edit" size="small" v-if="!isLoading"
                    @click="startGeneratingNewWarning($event, props.warnings)"
                    :title="$t('SummaryRisksTable.update-summary')" :disabled="disableGenerateNewWarningButton" />


            </div>
            <i v-if="props.warnings.length >= 1" :class="iconClass" class="toggle-icon" style="font-size: 1.5rem"
                @click="toggleVisibility"></i>

        </template>

        <template #content v-if="warningsAreVisible">


            <div v-for="warning in props.warnings" :style="`--risk-color: ${warning.risk_color}`"
                @contextmenu.prevent="rightClick($event, warning)">
                <Panel :header="warning.consequence">
                    <template #icons>
                        <button class="p-panel-header-icon p-link mr-2" @click="showEditWarningPopup(warning)">
                            <span class="pi pi-pencil"></span>
                        </button>
                        <button class="p-panel-header-icon p-link mr-2" @click="showDeleteWarningConfirm(warning)">
                            <span class="pi pi-trash"></span>
                        </button>
                    </template>
                    <p>{{ warning.answer }}</p>
                </Panel>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';
import Panel from 'primevue/panel';
import Menu from 'primevue/menu';
import Dialog from 'primevue/dialog';
import EditWarningForm from '@/views/summary-risks/warnings-card/EditWarningForm.vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import Button from 'primevue/button';

import { ref, computed, onMounted, onUnmounted } from 'vue';
import RightClickRisksFunctionality from '@/components/functional/RightClickRisksFunctionality.vue';
import { devLog } from '@/helpers/helpers';
import i18n from '@/i18n';
import axiosInstance from '@/services/interceptor';
import { useRoute } from "vue-router";

const confirm = useConfirm();
const toast = useToast();
const route = useRoute();

const { t } = i18n.global;
const props = defineProps({
    warnings: Array,
    selectedItems: Array,
    risks: Array
});

const warningsAreVisible = ref(false);
const dialogIsVisible = ref(false);
const warningSelectedForEdit = ref(null);
const iconClass = ref('pi pi-plus');
const processing = ref(false);
const isLoading = ref(false);
let intervalId = null;

const emit = defineEmits(['warningEdited', 'warningDeleted', 'warningsAreDone']);

function toggleVisibility() {
    warningsAreVisible.value = !warningsAreVisible.value;
    iconClass.value = warningsAreVisible.value ? 'pi pi-minus' : 'pi pi-plus';

}

// rightclick functionality
const rightClickEvent = ref(null);
const rightClickData = ref(null);

const disableGenerateNewWarningButton = computed(() => {
    // return true to disable
    // return false to enable
    // if there are risks, but no warnings, the button should be enabled
    // if it is not processing, the button should be enabled
    // if there is a warning with the property changed === true, the button should be enabled
    // in all other cases, the button should be disabled
    if (props.risks.length > 0 && props.warnings.length === 0) {
        return false;
    } else if (processing.value) {
        return false;
    } else if (props.warnings.some(warning => warning.changed === true)) {
        return false;
    } else {
        return true;
    }
});

const showWarningCard = computed(() => {
    // if loading is true, always show the card
    // return false when there are no warnings, and isLoading is false, always return true otherwise
    return isLoading.value || props.warnings.length > 0;
});

const disableWarnings = computed(() => {
    // if props.riskSummariesList.length === 0, return a string value of 'disabled-summaries'
    return isLoading.value ? 'disabled-warnings' : 'warnings';
});

const checkWarningsStatus = () => {
    const dossierId = route.params.dossierId; // Assuming `route` is available in your setup
    axiosInstance.get(`/dossiers/${dossierId}/`)
        .then((response) => {
            if (response.data.warnings_status === 'done') {
                isLoading.value = false; // Update loading status
                clearInterval(intervalId); // Stop the interval
                emit('warningsAreDone')
            } else {
                isLoading.value = true; // Update loading status
            }
        })
        .catch((error) => {
            console.error('Error checking warnings status:', error);
        });
};

onMounted(() => {
    checkWarningsStatus(); // Call immediately when mounted
    intervalId = setInterval(checkWarningsStatus, 5000); // Then every 5 seconds
});

onUnmounted(() => {
    if (intervalId) {
        clearInterval(intervalId); // Clean up on component unmount
    }
});


// RIGHT CLICK: sets data in variable
function rightClick(event, data) {
    devLog('right click', data)
    rightClickData.value = data;
    rightClickEvent.value = event;
}

function showEditWarningPopup(warning) {
    warningSelectedForEdit.value = warning;
    dialogIsVisible.value = true;
    devLog('edit warning', warning)
}

function showDeleteWarningConfirm(warning) {
    confirm.require({
        message: t('WarningsCard.confirm.message'),
        header: t('WarningsCard.confirm.header'),
        icon: 'pi pi-exclamation-triangle',
        rejectProps: {
            label: t('WarningsCard.confirm.reject-label'),
            severity: 'secondary',
            outlined: true
        },
        acceptProps: {
            label: t('WarningsCard.confirm.accept-label')
        },
        rejectLabel: t('WarningsCard.confirm.reject-label'),
        acceptLabel: t('WarningsCard.confirm.accept-label'),
        accept: () => {
            deleteWarning(warning);
        },
        reject: () => {
            toast.add({ severity: 'error', summary: t('WarningsCard.confirm.reject-summary'), detail: t('WarningsCard.confirm.reject-detail'), life: 3000 });
        }
    });
}

function warningEdited(warning) {
    emit('warningEdited', warning);
};

function deleteWarning(warning) {
    // axios request to delete the warning, and on success emit the warningDeleted event
    axiosInstance.delete(`warnings/${warning.id}/`)
        .then((response) => {
            toast.add({ severity: 'info', summary: t('WarningsCard.confirm.accept-summary'), detail: t('WarningsCard.confirm.accept-detail'), life: 3000 });
            emit('warningDeleted', warning);
        })
        .catch((error) => {
            // handle error
            devLog(error)
        })
        .then(() => {
            // always executed
        });
};

function startGeneratingNewWarning(event, warnings) {

    devLog(props.selectedItems);
    let newWarningObject = {
        dossier: route.params.dossierId,
        "risk-name": props.selectedItems[0],
    };
    // minimize card
    warningsAreVisible.value = false;
    iconClass.value = "pi pi-plus";
    processing.value = true;
    // emit("warningDeleted", warnings);
    // Expects dossier and risk_name as parameters
    axiosInstance
        // dossiersWithChanges = the id of the dossier where a file was recently uploaded
        .post(
            "/warnings/",
            newWarningObject
        )
        .then((response) => {
            updateChangedProperty(newWarningObject["risk-name"]);
        })
        .catch((error) => {
            devLog(error);
        });
}

function updateChangedProperty(riskName) {
    // find first warning in warnings array with the same name as the riskName parameter
    let warning = props.warnings.find(warning => warning.risk_name === riskName);
    // if the warning doesn't exist, return
    if (!warning) return;
    // do a put request, and change the changed property to false
    warning.changed = false;
    axiosInstance.put(`warnings/${warning.id}/`, warning)
        .then((response) => {
            emit('warningEdited', warning);
            processing.value = false;
        })
        .catch((error) => {
            // handle error
            devLog(error)
        })
        .then(() => {
            // always executed
        });
};

</script>
```

<style scoped>
.card {
    margin-bottom: 0.5rem;
}

:deep(.p-card-title) {
    font-size: 18px;
    font-weight: 100;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--main-dark-purple);
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 1rem;
}


:deep(.p-card-content) {
    text-align: start;
    padding: 1rem;
}

:deep(.p-card-title) i:hover {
    cursor: pointer;
    color: #6D76B4;
}

:deep(.p-card-title) i:hover {
    cursor: pointer;
    color: #6D76B4;
}

::v-deep(.p-panel-header) {
    color: var(--risk-color);
}

:deep(.p-panel) {
    margin-bottom: 1rem;
}

.card-title-field {
    display: flex;
    align-items: center;
    gap: 32px;
}

.disabled-warnings {
    background-color: var(--main-light-purple);
}

.disabled-warnings:hover {
    cursor: default;
}

.disabled-warnings h4 {
    color: var(--sec-light-purple);
}
</style>