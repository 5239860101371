<template>
    <form class="form" @submit.prevent="updateQuestion">
        <InputText :disabled="props.questionIsLoading" class="question" v-model="question"
            :placeholder="$t('QandAInput.ask-ai')" />
        <Button class="button" :label="$t('QandAInput.ask-question')" type="submit" :loading="props.questionIsLoading">
        </Button>
    </form>
</template>

<script setup>
import InputText from 'primevue/inputtext';
import TheButton from '@/components/ui/TheButton.vue';
import Button from 'primevue/button';

import { ref } from 'vue';

const props = defineProps({
    questionIsLoading: Boolean
});

const emit = defineEmits(['updateQuestion']);

const question = ref();

function updateQuestion() {
    emit('updateQuestion', question.value);
    // question.value = '';
}

</script>

<style scoped>
.question {
    min-width: 100%;
    margin-left: 3rem;
    margin-right: 0.5rem;
}

.button {
    color: white;
    height: 3rem;
    min-width: fit-content;
}

.form {
    display: flex;
    align-items: center;
    max-width: 100%;
}

:deep(.p-button) {
    background-color: #3F4A86;
    border: none;
    color: white;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
}

:deep(.p-button):hover {
    background-color: #1A224C;
}
</style>