<template>
  <header>
    <div class="logo-container">
      <router-link to="/" class="nav-title"><img id="logo-img"
          src="../../assets/images/publican-full-logo-purple.svg" /></router-link>
    </div>

    <div class="settings">
      <p class="user">{{ user.email }}</p>
      <account-button class="account-button"></account-button>
      <locale-switcher class="locale-switcher"></locale-switcher>
    </div>

  </header>
</template>

<script setup>
import LocaleSwitcher from '../ui/LocaleSwitcher.vue';
import AccountButton from '../ui/AccountButton.vue';
import { computed } from '@vue/reactivity';
import { ref } from 'vue';

// authUser is stored in sessionStorage as a string, so it needs to turn back into an object to access email
const reactiveUser = ref(JSON.parse(sessionStorage.getItem('authUser')))
const user = computed(() => reactiveUser.value)


</script>

<style scoped>
header {
  height: 4rem;
  background-color: #1A224C;
  display: flex;
  justify-content: space-between;
}

img {
  width: 150px;
}

.logo-container,
.settings {
  display: flex;
  align-items: center;
}

.account-button {
  margin-right: 0.5rem;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--main-header-color);
}

.dropdown {
  color: white;
  border-left: solid white;
  border-width: 1px;
  text-align: center;
}

.locale-switcher {
  margin-left: 1rem;
}
.user{
  color: white;
  margin-right: 1rem;
}
</style>
