import { createI18n } from "vue-i18n";

// Import the locale files
import en from "./locales/en.json";
import nl from "./locales/nl.json";
import fr from "./locales/fr.json";

// Define the messages
const messages = {
  en: en,
  nl: nl,
  fr: fr,
};


let selectedLanguage = typeof localStorage !== 'undefined' ? localStorage.getItem("language") : 'nl';


// Create the i18n instance
const i18n = createI18n({

  legacy: false,
  globalInjection: true,
  locale: selectedLanguage || "nl",
  fallbackLocale: selectedLanguage || "nl",
  escapeInterpolation: false,
  messages,
});

export default i18n;
