<template>
    <div class="login-zone">
        <Card class="card">
            <template #title>{{ $t("AuthLoginMfaForm.headerTitle") }}</template>
            <template #content>
                <form @submit.prevent="handleMFA">
                    <div class="form-field">
                        <div class="p-float-label">
                            <InputText id="mfaCode" v-model="mfaCode" @blur="validateMFA" class="input-field" />
                            <label for="mfaCode">{{ $t("AuthLoginMfaForm.enterCode") }}</label>
                        </div>
                        <small v-if="mfaError" class="p-error">{{ mfaError }}</small>
                    </div>
                    <Button :label="$t('AuthLoginMfaForm.submit')" type="submit" :disabled="!isFormValid" :loading="loading" />
                </form>
            </template>
        </Card>
    </div>
</template>

<script setup>
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import i18n from '@/i18n';


const store = useStore();
const router = useRouter();
const { t } = i18n.global;

const mfaCode = ref('');
const mfaError = ref('');
const isFormValid = ref(false);
const loading = ref(false);

const validateMFA = () => {
    if (mfaCode.value.length !== 6) {
        mfaError.value = t('AuthLoginMfaForm.enterValidCode');
        isFormValid.value = false;
    } else {
        mfaError.value = '';
        isFormValid.value = true;
    }
};

function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.startsWith(name)) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return null;
}


async function handleMFA() {
    const csrfToken = getCsrfToken();

    // Include CSRF token in headers if available
    const headers = csrfToken ? { "X-CSRFToken": csrfToken } : {};

    loading.value = true;
    const actionPayload = {
        otp: mfaCode.value,
    };
    try {
        const response = await store.dispatch('verifyMFA', actionPayload, { headers });
        // Handle successful MFA verification
        if (response && response.success) {
            router.push({ path: '/' }); // Redirect to the root path which will redirect to /dossiers
        } else {
            mfaError.value = t("AuthLoginMfaForm.invalidCode");
        }
    } catch (error) {
        console.log(error)
        // Handle failed MFA verification
        mfaError.value =  t("AuthLoginMfaForm.invalidCode");
    }
    loading.value = false;
};

</script>

<style scoped>
.p-error {
    color: red;
    font-size: 0.875rem;
}

.card {
    flex-grow: 1;
    max-width: 750px;
}

.login-zone {
    display: flex;
    justify-content: center;
}

.form-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
}

.input-field {
    width: 100%;
    box-sizing: border-box;
}
small {

    margin-top: 0.5rem;
    color: red;
}
</style>