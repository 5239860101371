<template>
    <v-card v-if="tokenIsValid === true && pageIsLoading === false" class="square-card-page">
        <h2 class="card-title">{{ $t('resetPasswordView.resetPassword') }}</h2>

        <form class="form" autocomplete="off">
            <div class="form-field">
                <label for="email">{{ $t('resetPasswordView.password') }}</label>
                <InputText autocomplete="off" type="password" v-model="password" :placeholder="$t('resetPasswordView.password')" />
                <div class="password-rules">
                    <!-- ADD IF NECESSARY
                    <li>Moet minstens één kleine letter bevatten</li>
                    <li>Moet minstens één hoofdletter bevatten</li>
                    <li>Moet minstens één getal bevatten</li>
                    -->
                    <li v-if="passwordLongEnough === false">{{ $t('resetPasswordView.passwordLength') }}</li>
                </div>
            </div>
            <div class="form-field">
                <label for="email">{{ $t('resetPasswordView.passwordRepeat') }}</label>
                <InputText autocomplete="off" type="password" v-model="repeatPassword" :placeholder="$t('resetPasswordView.passwordRepeat')" />
                <p v-if="passwordsMatch === false" class="error">
                    {{ $t('resetPasswordView.passwordsDontMatch') }}</p>
            </div>

            <Button icon="pi pi-send" class="add-user" :label="$t('resetPasswordView.send')" @click="validatePasswordFields()"
                :loading="postIsLoading" />
            <p v-if="successfulPost === true || successfulPost === false"
                :class="{ success: successfulPost, error: !successfulPost }">{{ postResponse }}</p>
        </form>

    </v-card>
    <NotFoundView v-else-if="tokenIsValid === false && pageIsLoading === false"></NotFoundView>
    <v-card v-else class="square-card-page">
        <h2 class="card-title">{{ $t('resetPasswordView.loading') }}</h2>
    </v-card>
</template>

<script setup>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import NotFoundView from './NotFoundView.vue';

import { useRoute } from 'vue-router';
import { onMounted, ref, watch } from 'vue';

import axios from 'axios';
import i18n from '@/i18n';

const route = useRoute();
const uninterceptedAxiosInstance = axios.create();
const { t } = i18n.global;

const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
const tokenIsValid = ref(false)
const passwordsMatch = ref(null);
const passwordLongEnough = ref(null);
const successfulPost = ref(null);
const postResponse = ref('')
const postIsLoading = ref(false)
const pageIsLoading = ref(true)
const password = ref('')
const repeatPassword = ref('')


onMounted(() => {
    authenticateToken(route.params.token)
})


watch(repeatPassword, (newValue, oldValue) => {
    checkIfPasswordsMatch();
});
watch(password, (newValue, oldValue) => {
    checkIfPasswordLongEnough();
});


function authenticateToken(token) {

    let tokenObject = {
        "token": token
    }

    uninterceptedAxiosInstance.post(BASE_URL + 'auth/password_reset/validate_token/', tokenObject)
        .then((response) => {
            tokenIsValid.value = true;
            pageIsLoading.value = false;
        })
        .catch((error) => {
            pageIsLoading.value = false;
        })
        .then(() => {
        })
}

function changePassword(password, token) {
    postIsLoading.value = true;

    let data = {
        "password": password,
        "token": token
    }

    uninterceptedAxiosInstance.post(BASE_URL + 'auth/password_reset/confirm/', data)
    .then((response) => {
            postIsLoading.value = false;
            successfulPost.value = true;
            postResponse.value = t('resetPasswordView.success')
        })
        .catch((error) => {
            postIsLoading.value = false;
            successfulPost.value = false;
            // postResponse.value = "Er ging iets mis, uw wachtwoord werd niet aangepast."
            postResponse.value = error.response.data.password[0]
        })
        .then(() => {
        })
}

function checkIfPasswordsMatch() {
    if (password.value === repeatPassword.value) {
        passwordsMatch.value = true;
        return
    } else {
        passwordsMatch.value = false;
        return
    }
}

function checkIfPasswordLongEnough() {
    if (password.value.length <= 7) {
        passwordLongEnough.value = false;
        return
    } else {
        passwordLongEnough.value = true;
    }
}

function validatePasswordFields() {
    checkIfPasswordsMatch();
    checkIfPasswordLongEnough();
    if (passwordsMatch.value === false || passwordLongEnough.value === false) {
        return
    }
    changePassword(password.value, route.params.token)
}

</script>

<style scoped>
.form-field {
    margin: 1rem;
}

.error {
    color: red;
}

.success {
    color: green;
}

.form-field {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    text-align: start;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}

:deep(.p-button.add-user) {
    background-color: #1A224C;
    border-color: #1A224C;
    color: white;
}

:deep(.p-button.add-user:hover) {
    background-color: #bfc7ff;
    border-color: #bfc7ff;
    color: #1A224C;
}

li {
    list-style-type: none;
}

.password-rules {
    margin-left: 0.25rem;
    margin-top: 0.25rem;
    color: red;
}
</style>