<template>
    <Card class="square-card">
        <template #header>
            <img :alt="$t('DashboardConformity.img-alt')" src="../../assets/images/acties-illustratie.png" />
        </template>
        <template #title>
            {{ $t('DashboardConformity.title') }}
        </template>
        <template #content>
            <p>
                {{ $t('DashboardConformity.content') }}
            </p>
        </template>
        <template #footer>
            <Button :label="$t('DashboardConformity.button-label')" rounded outlined right @click="handleClick" :disabled="isDisabled">
                <template v-if="isLoading">
                    <i class="pi pi-spin pi-spinner"></i>
                </template>
                <template v-else>
                    <p style="font-weight: bold;">{{ $t('DashboardConformity.button-label') }}</p>
                </template>
            </Button>
            <p class="error" v-if="error">{{ errorMessages }}</p>
        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';
import Button from 'primevue/button';

import { ref, computed } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';

import axiosInstance from '@/services/interceptor';

const route = useRoute();
const error = ref(false); // Reactive variable to show error message
const errorMessages = ref(''); // Reactive variable to show error message

const props = defineProps({
    qualificationMatrix: String
});

// computed property, if props.qualificationMatrix is 'empty', disable the button
const isDisabled = computed(() => {
    return props.qualificationMatrix === 'empty' || props.qualificationMatrix === 'processing';
});

const isLoading = ref(false); // Reactive variable to track loading state


async function handleClick() {
    // normally this handler won't be used, but it's here in case the parent component changes in the future
    if (props.qualificationMatrix === 'empty') {
        // there are no documents or the documents are not yet processed/ dont have a selection
        showWarning("Er zijn geen documenten of de documenten zijn nog niet verwerkt.");
    } else if (props.qualificationMatrix === 'processing') {
        // the documents are being processed
        showWarning("De selectie-eisen worden verwerkt, even geduld alstublieft.");
    } else if (props.qualificationMatrix === 'done') {
        // the documents are processed and have a selection, so we download them
        downloadExcel(route.params.dossierId);
    } else {
        // something went wrong
        showWarning("Er ging iets mis, probeer het later opnieuw.");
    };
}

function showWarning(warningMessage) {
    error.value = true;
    errorMessages.value = warningMessage;
}


async function downloadExcel() {
    isLoading.value = true; // Start loading
    const language = localStorage.getItem('language') || 'nl';
    axiosInstance.get('/qualification-export-matrix/?dossier=' + route.params.dossierId + '&language=' + language, {
        responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'conformity.xlsx');
        document.body.appendChild(link);
        link.click();
        isLoading.value = false; // End loading
    }).catch((error) => {
        isLoading.value = false;
        return
    });
}


</script>

<style scoped>
.page-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

:deep(.p-card):hover {
    cursor: pointer;
    background-color: #E7EAFF;
}

:deep(.p-card-title),
:deep(.p-card-content) {
    font-size: 14px;
    color: var(--main-dark-purple);
}

.square-card {
    max-width: 25em;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    border-radius: 0;
    flex-basis: 48%;
    flex-grow: 1;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.dashboard-item-title {
    font-weight: 550;
    color: var(--sec-dark-purple);
}

.two-buttons {
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.dashboard-button,
.dropdown {
    min-width: 48%;
    height: 3rem;
    max-width: 48%;
}

.dropdown {
    padding: 0;
    margin: 0;
}

.dashboard-button {
    justify-content: center;
}

.dashboard-single-button {
    width: 100%;
    justify-content: center;
    height: 3rem;
}

.dashboard-button-field,
.two-buttons {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.error {
    margin-top: 1rem;
    color: red;
}
</style>