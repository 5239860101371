<template>
    <div class="full-page">
        <breadcrumbs-title></breadcrumbs-title>
        <div class="container">
            <Card class="left-flex">
                <template #content>
                    <Listbox v-model="selectedFilter" :options="filters">
                        <template #option="slotProps">
                            <div>{{ $t('QualificationView.' + slotProps.option.value) }}</div>
                        </template>
                    </Listbox>
                </template>

            </Card>
            <Card v-if="qualifications" class="right-flex" v-for="qualification in filteredQualifications"
                :key="qualification.id">
                <template #content>
                    <div class="list">
                        <p v-html="qualification.text"></p>
                    </div>
                </template>
            </Card>
            <Card v-if="!qualifications" class="right-flex" v-for="qualification in filteredQualifications"
                :key="qualification.id">
                <template #content>
                    <div class="list">
                        <h2>{{ $t('QualificationView.no-qualifications') }}</h2>
                        <p>{{ $t('QualificationView.no-docs') }}</p>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup>
import Card from 'primevue/card';
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import Listbox from 'primevue/listbox';

import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import qualificationsFilters from '@/assets/JSON/qualificationsFilters';
import { devLog } from '@/helpers/helpers';
import i18n from '@/i18n';

const route = useRoute();
const { t } = i18n.global;

const qualifications = ref(null);

const selectedFilter = ref();

// import the filters from the JSON file, these qualifications are also used on other pages
// so they are stored in a JSON file, so all changes are made in one place
const filters = ref(qualificationsFilters);

// computed property, returns the filtered qualifications
const filteredQualifications = computed(() => {
    if (!qualifications.value) {
        return null;
    } else if (qualifications.value && selectedFilter.value) {
        const filtered = qualifications.value.filter(qualification => qualification.name === selectedFilter.value.value);
        // if there is no qualification present, return a message
        if (filtered.length === 0) {
            const empty = [{
                text: `<h2>${$t('QualificationView.text-no-qualifications')}</h2>\n${$t('QualificationView.progressing')}: <strong>${selectedFilter.value.name}</. ${$t('QualificationView.try-again-later')}`,
            }];
            return empty;
        } else {
            return filtered;
        }
    }
});

onMounted(() => {
    getQualifications();
});

function getQualifications() {
    axiosInstance.get('qualifications/?dossier=' + route.params.dossierId)
        .then(response => {
            qualifications.value = response.data;
            if (qualifications.value.length !== filters.value.length) {
                // after 5 seconds, getQualifications again
                devLog('loopin')
                setTimeout(() => {
                    getQualifications();
                }, 5000);
            }
        })
        .catch(error => {
            devLog(error);
        });
}



</script>

<style scope>
.full-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.container {
    display: flex;
}

.left-flex {
    width: 20%;
    min-width: 20%;
}

.right-flex {
    margin-left: 1rem;
    width: 100%;
    overflow-y: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: start;
}

.right-flex p {
    line-height: 2;
}
</style>